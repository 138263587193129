import {css, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {FormSubmissionBase} from './form-submission-base';
import '@material/mwc-select';
import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';

export interface SelectOption {
  key: string;
  label: string;
  selected?: boolean
}

@customElement('form-submission-select')
export class FormSubmissionSelect extends FormSubmissionBase {
  @property({type: String}) label = '';
  @property({type: Array}) options: SelectOption[] = [];
  @property({type: Boolean}) multi: boolean = false;
  @property({type: Boolean}) fullwidth: boolean = false;

  private multiSelectedValues: string[] = [];

  static styles = css`
:host {
    display: contents;
}
:host([fullwidth]) mwc-select {
    display: flex;
}
.selected-values-container {
    display: flex;
}
.selected-value {
    border: 1px solid #8e8e8e;
    border-radius: 5px;
    padding: 3px;
    margin-left: 5px;
    display: flex;
    align-items: center;
}
.selected-value mwc-icon {
    cursor: pointer;
}
`;

  /**
   * For multi selects, don't include any automatic `value` changes. We will manually fireEvent
   * and indicate then whether we want to include the value we are firing.
   * @return {boolean}
   */
  protected get shouldInclude(): boolean {
    return this.multi ? false : true;
  }

  private handleSelect(event: CustomEvent): void {
    const option = this.options[event.detail.index];

    if (!option) {
      return;
    }

    if (this.multi) {
      return this.updateMultiSelection(option.key, true);
    }

    this.value = option.key;
  }

  private handleRemoveSelect(value: string): void {
    this.updateMultiSelection(value, false);
  }

  private updateMultiSelection(value: string, isAdding: boolean): void {
    if (isAdding) {
      // if the selection already exist, don't do anything
      if (this.multiSelectedValues.includes(value)) {
        return;
      }

      this.multiSelectedValues.push(value);
    } else {
      this.multiSelectedValues = this.multiSelectedValues.filter((v) => v !== value);
    }

    // manually firing the event so we can dynamically fire the value/shouldInclude fields
    this.fireEvent({value: value, shouldInclude: isAdding});

    this.requestUpdate();
  }

  /**
   * When a mwc-select component is used inside of a mwc-dialog component, the mwc-select's 'closed' event
   * propagates up (across the shadow boundary) and the mwc-dialog takes action on it, thinking it needs
   * to close. What we are doing here is preventing the 'closed' event from going any higher than here.
   * https://github.com/material-components/material-components-web-components/issues/1150
   * @param {Event} e
   */
  private preventClosedEventFromLeaking(e: Event): void {
    e.stopPropagation();
  }

  protected render() {
    return html`
<mwc-select fixedMenuPosition @closed="${this.preventClosedEventFromLeaking}" @selected="${this.handleSelect}" label="${this.label}">
    ${this.options.map((option) => html`<mwc-list-item value="${option.key}" ?selected="${option.selected === true}">${option.label}</mwc-list-item>`)}
</mwc-select>

${this.multi ? html`
<div class="selected-values-container">
    ${this.multiSelectedValues.map((v) => html`
    <p class="selected-value">${v} <mwc-icon @click="${() => this.handleRemoveSelect(v)}">clear</mwc-icon></p>
    `)}
</div>
` : ''}
`;
  }
}
