import {html, LitElement} from 'lit';
import {customElement, query} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessRewardForm} from '../../../symfony/forms';
import {BusinessGamePrize} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import {FormSubmissionTextfield} from '../../../components/form-submission/fields/form-submission-textfield';
import '@material/mwc-button';
import '../../../components/typography/body-1';
import '../../../components/qr-code/qr-code';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/open-dialog-button/open-dialog-button';
import '../../../components/actionable-list-item/actionable-list-item';

@customElement('backend-business-programsgame-page')
export class ProgramsgamePage extends LitElement {
  @symfony({variable: '$qr_url', type: String}) private qrUrl: string = '';
  @symfony({variable: '$form', converter: 'form_fields:["name", "expires_after", "checkin_times", "checkin_per", "game_win_percent", "security_reset_qr", "security_reset_time_qr", "security_notify_qr"]|json', type: Object}) private form!: BusinessRewardForm;
  @symfony({variable: '$form', converter: '_call:"isNew":""', type: Boolean}) private isNew: boolean = false;
  @symfony({variable: '$prizes', converter: '_default:[]|take:["name"]|json', type: Array}) private prizes: BusinessGamePrize[] = [];
  @query('#add-prize-field') private addPrizeField!: FormSubmissionTextfield;

  private prizesAsItemDisplay(): ItemDisplay[] {
    return this.prizes.map((prize) => ({
      leadingIcon: '',
      type: 'hidden',
      label: '',
      value: prize.name,
      name: 'prizes[]',
    }));
  }

  private handleDeletePrizeClicked(index: number): void {
    if (window.confirm('Are you sure?')) {
      this.prizes.splice(index, 1);

      this.prizes = [...this.prizes];
    }
  }

  private handleOpenDialogButtonPrimaryClicked(): void {
    const value = this.addPrizeField.value;

    if (value === '') return;

    this.prizes = [...this.prizes, {id: -1, name: value}];
  }

  protected render() {
    return html`
<narrow-content centered>
    ${!this.isNew ? html`
    <content-block textCenter>
        <qr-code fullurl size="150" link="${this.qrUrl}"></qr-code>
        <body-1>To download, right click and "Save Image/Picture As"</body-1>
    </content-block>
    ` : ''}

    <editable-section
        heading="Game"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'label', 'type': 'text', 'label': 'Name', 'name': 'business_reward[name]', 'value': this.form.name.value},
            {'leadingIcon': 'hourglass_disabled', 'type': 'select', 'label': 'How many days after reward is earned should it expire?', 'name': 'business_reward[expires_after]', 'value': this.form.expires_after.value, 'options': this.form.expires_after.options},
            {'leadingIcon': 'rule', 'type': 'combination', 'label': 'How many times can a customer play?', 'value': `${this.form.checkin_times.options.find((o) => o.selected)?.label || '-'} per ${this.form.checkin_per.options.find((o) => o.selected)?.label || '-'}`, 'inputs': [
                {'leadingIcon': '', 'type': 'select', 'label': 'How many times can a customer play?', 'name': 'business_reward[checkin_times]', 'value': this.form.checkin_times.value, 'options': this.form.checkin_times.options},
                {'leadingIcon': '', 'type': 'select', 'label': 'Per', 'name': 'business_reward[checkin_per]', 'value': this.form.checkin_per.value, 'options': this.form.checkin_per.options},
            ]},
            {'leadingIcon': 'casino', 'type': 'select', 'label': 'Percent of winning spins', 'name': 'business_reward[game_win_percent]', 'value': this.form.game_win_percent.value, 'options': this.form.game_win_percent.options},
        ]}>
    </editable-section>
    
    <editable-section
        individualEdits
        heading="Prizes"
        description=""
        submitName="submit_save"
        .items="${this.prizesAsItemDisplay()}">
        
        ${this.prizes.map((prize: BusinessGamePrize, index: number) => html`
        <actionable-list-item
            heading="${prize.name}"
            subHeading="Prize">
            <mwc-button slot="actions" label="Delete" @click="${() => this.handleDeletePrizeClicked(index)}"></mwc-button>
        </actionable-list-item>
        `)}
            
        <open-dialog-button label="Add A Prize" primaryLabel="Add" @open-dialog-button-primary-clicked="${this.handleOpenDialogButtonPrimaryClicked}">
            <form-submission-textfield id="add-prize-field" name="add-prize-field" label="Prize" value=""></form-submission-textfield>
        </open-dialog-button>
    </editable-section>
    
    <editable-section
        heading="QR Code Security"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'qr_code', 'type': 'select', 'label': 'QR code expires every', 'name': 'business_reward[security_reset_qr]', 'value': this.form.security_reset_qr.value, 'options': this.form.security_reset_qr.options},
            {'leadingIcon': 'qr_code', 'type': 'select', 'label': 'Reset Time (UTC−06:00)', 'name': 'business_reward[security_reset_time_qr]', 'value': this.form.security_reset_time_qr.value, 'options': this.form.security_reset_time_qr.options},
            {'leadingIcon': 'qr_code', 'type': 'textarea', 'label': 'Notify the following email addresses when the QR code expires (separate each email address with a comma)', 'name': 'business_reward[security_notify_qr]', 'value': this.form.security_notify_qr.value},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
