import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '../../components/typography/body-1';
import '../../components/typography/body-2';
import '../../components/button-link/button-link';

export interface Action {
  href: string;
  label: string;
  confirm?: string;
}

@customElement('actionable-list-item')
export class ActionableListItem extends LitElement {
  @property({type: String}) public heading: string = '';
  @property({type: String}) public subHeading: string = '';
  /**
   * We can either supply this property to automatically create button-link's or we can
   * use the slot mechanism to have custom logic.
   */
  @property({type: Array}) public actions: Action[] = [];

  static styles = css`
:host {
    display: flex;
    padding: 15px;
}
:host(:hover) {
    background-color: #f5f5f5;
}
.headings {
    flex: 1;
    overflow: hidden;
}
.subheading {
    color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75%;
}
`;

  protected render() {
    return html`
<div class="headings">
    <body-1>${this.heading}</body-1>
    <body-2 class="subheading">${this.subHeading}</body-2>
</div>
<slot name="actions" class="actions">
    ${this.actions.map((action) => html`
        <button-link href="${action.href}" confirm="${action.confirm || ''}">${action.label}</button-link>
    `)}
</slot>
`;
  }
}
