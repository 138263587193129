import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('carousel-item')
export class CarouselItem extends LitElement {
  static styles = css`
:host {
    display: flex;
    flex: 1;
    overflow: hidden;
    //background-color: #e4e4e4;
    background-color: var(--primary-color);
    opacity: 0.2;
    width: 250px;
    height: 300px;
    margin-right: 15px;
    min-width: 20vw;

    align-items: center;
    justify-content: center;
}
    `;

  protected render() {
    return html`
<slot></slot>
`;
  }
}
