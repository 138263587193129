import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessRewardForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/button-link/button-link';

@customElement('backend-business-sendreward-page')
export class SendrewardPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "greeting", "name_get", "sender"]|json', type: Object}) private form?: BusinessRewardForm;

  protected render() {
    return html`
<narrow-content>

    <content-block textRight>
        <button-link href="/admin/business/customers" raised>Lookup Your Customers</button-link>
    </content-block>

    <editable-section
        fullWidth
        multipart
        heading="Send Rewards"
        description="If you enter a phone number or email that is not associated with one of your current customers they will receive the reward upon joining your program."
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'alternate_email', 'type': 'text', 'label': 'Email or phone number of the customer to send the reward', 'name': 'email_or_phone', 'value': ''},
            {'leadingIcon': 'message', 'type': 'text', 'label': 'Greeting', 'name': 'business_reward[greeting]', 'value': this.form?.greeting.value},
            {'leadingIcon': 'card_giftcard', 'type': 'text', 'label': 'Deal/Message (Ex: Get 20% off!)', 'name': 'business_reward[name_get]', 'value': this.form?.name_get?.value},
            {'leadingIcon': 'send', 'type': 'text', 'label': 'From', 'name': 'business_reward[sender]', 'value': this.form?.sender?.value},
            {'leadingIcon': 'cloud_upload', 'type': 'file', 'label': 'Image or video', 'name': 'business_reward[image]', 'value': ''},
            {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business_reward[id]', 'value': this.form?.id.value},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
