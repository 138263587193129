import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('data-table-cell')
export class DataTableCell extends LitElement {
  @property({type: String}) public link: string = '';
  @property({type: String}) public confirm: string = '';

  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
    `;
  }
}
