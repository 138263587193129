import {css} from 'lit';

// ::slotted(editable-section): this effectively hides the main box, while still showing the dialog
export const style = css`
::slotted(editable-section) {
    position: absolute;
    left: -100%;
}
.prominent-notice {
    text-align: center;
    margin: 0 auto;
}
.prominent-notice__image {
    width: 170px;
    margin-top: 30px ;
}
.prominent-notice--large .prominent-notice__image {
    width: 350px;
}
.prominent-notice__heading {
    margin: 20px 0;
}
.prominent-notice__subtitle {
    margin: 16px 0;
}
`;
