import {ItemDisplay} from '../editable-section';

export class StoredItemDisplay {
  constructor(public submitName: string, public internalId: string, public item: ItemDisplay) {
  }
}

export interface Subscription {
  dispose(): void;
}

export class ItemDisplayStore {
  private items: StoredItemDisplay[] = [];
  private subscribers: Handler[] = [];

  public subscribe(callback: (items: StoredItemDisplay[]) => void): Subscription {
    const handler = new Handler(callback);

    const subscribers = this.subscribers;

    subscribers.push(handler);

    return {
      dispose() {
        const idx = subscribers.indexOf(handler);

        if (idx !== -1) {
          subscribers.splice(idx, 1);
        }
      },
    };
  }

  public addItems(submitName: string, internalId: string, items: ItemDisplay[]): void {
    const storedItems = items.map((item) => new StoredItemDisplay(submitName, internalId, item));

    const itemsWithoutInternalId = this.items.filter((item) => item.internalId !== internalId);

    const newItems = itemsWithoutInternalId.concat(storedItems);

    this.subscribers.forEach((subscriber) => subscriber.handle(newItems));

    this.items = newItems;
  }

  public removeItems(internalId: string): void {
    const itemsWithoutInternalId = this.items.filter((item) => item.internalId !== internalId);

    this.subscribers.forEach((subscriber) => subscriber.handle(itemsWithoutInternalId));

    this.items = itemsWithoutInternalId;
  }
}

class Handler {
  constructor(public callback: (items: StoredItemDisplay[]) => void) {
  }

  public handle(newItems: StoredItemDisplay[]) {
    this.callback.call(null, newItems);
  }
}
