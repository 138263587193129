import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Partner} from '../../../symfony/models';
import '../../../components/button-link/button-link';
import '@material/mwc-button';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/content-block';
import '../../../components/layout/narrow-content';
import '../../../components/prominent-notice/prominent-notice';

@customElement('backend-business-invite-page')
export class InvitePage extends LitElement {
  @symfony({variable: '$show_form', converter: '_default:false', type: Boolean}) private showForm: boolean = false;
  @symfony({variable: '$invites_remaining', type: Number}) private invitesRemaining: number = 0;
  @symfony({variable: '$sf_request', converter: '_call:"isMethod":"post"', type: Boolean}) private isPost: Boolean = false;
  @symfony({variable: '$sf_request', converter: '_call:"getParameter":"source"', type: String}) private source!: string;
  @symfony({variable: '$sf_request', converter: '_call:"getParameter":"emails"', type: String}) private emails: string = '';
  @symfony({variable: '$sf_request', converter: '_call:"getParameter":"subject"', type: String}) private subject: string = '';
  @symfony({variable: '$sf_request', converter: '_call:"getParameter":"message"', type: String}) private message: string = '';
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"google_token"', type: String}) private googleToken: string = '';
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"outlook_token"', type: String}) private outlookToken: string = '';
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_outlook_client_id"', type: String}) private outlookClientId: string = '';
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_outlook_url"', type: String}) private outlookUrl: string = '';
  @symfony({variable: '$partner', converter: 'take:["google_app_id", "domain"]|json', type: Object}) private partner!: Partner;
  @symfony({variable: '$emaillist', converter: '_default:[]|json', type: Array}) private emailList: string[] = [];

  static styles = css`
button-link {
    display: inline-block;
    margin: 5px;
}
`;

  protected render() {
    const emails: ItemDisplay[] = this.source === 'manual' ?
      [{'leadingIcon': 'email', 'type': 'textarea', 'label': 'Email Addresses', 'name': 'emails', 'value': this.emails}] :
      this.emailList.map((email: string) => ({'leadingIcon': 'email', 'type': 'checkbox', 'label': email, 'name': 'emails[]', 'value': email}));

    const items: ItemDisplay[] = [
      {'leadingIcon': 'message', 'type': 'combination', 'label': 'Enter the email addresses you want to invite.', 'value': '', 'inputs': <any>emails.concat([
        {'leadingIcon': 'account_box', 'type': 'text', 'label': 'Subject', 'name': 'subject', 'value': this.isPost ? this.subject : ''},
        {'leadingIcon': 'message', 'type': 'textarea', 'label': 'Message', 'name': 'message', 'value': this.isPost ? this.message : ''},
      ])},
    ];

    return html`
${this.showForm ? html`
<narrow-content>
    <editable-section
        heading="Invite Email Addresses"
        description="${this.source === 'manual' ? 'Enter the email addresses you want to invite. Separate each address with a coma.' : 'Select the email addresses you want to invite.'}"
        submitName="submit_send"
        primaryButtonLabel="Send Invites"
        .items=${items}>
    </editable-section>
</narrow-content>
` : html`
<prominent-notice
        large
        image="/assets/images/undraw_invite_i6u7.svg"
        heading="Invite Customers"
        subtitle="You can invite your contacts to join your loyalty program. You have ${this.invitesRemaining} invites left today. Choose one of the options below. ">
</prominent-notice>

<content-block textCenter>
    <button-link raised href="${this.googleToken !== '' ? '/admin/business/invite/source/google' : `https://accounts.google.com/o/oauth2/auth?client_id=${this.partner.google_app_id}&redirect_uri=http://${this.partner.domain}/admin/business/invitegoogle&scope=https://www.google.com/m8/feeds/&response_type=code`}">
        Invite Friends From Gmail
    </button-link>
    <button-link raised href="${this.outlookToken !== '' ? '/admin/business/invite/source/outlook' : `https://login.live.com/oauth20_authorize.srf?client_id=${this.outlookClientId}&scope=wl.signin%20wl.basic%20wl.emails%20wl.contacts_emails&response_type=code&redirect_uri=${this.outlookUrl}`}">
        Invite Friends From Outlook
    </button-link>
    <button-link raised href="/admin/business/invite/source/manual">
        Manually Invite Friends
    </button-link>
</content-block>
`}
`;
  }
}
