import {businessUrl} from './helpers/formatters/business-url';

export interface ApplicationNavigation {
  actions: ActionItem[];
  navigation: NavigationItem[];
}

export interface ActionItem {
  graphic: string;
  href: string;
  label: string;
}

export interface NavigationItem {
  graphic: string;
  label: string;
  href: string;
  sublinks?: Sublinks;
  dividerBefore?: boolean;
}

export interface Sublinks {
  [label: string]: string;
}

export function backend(attributes: { [key: string]: any }): ApplicationNavigation {
  // Setup actions
  const actions: ActionItem[] = [];

  if (attributes['be_back_to_partner']) {
    actions.push({graphic: 'open_in_browser', label: 'Back To Partner Admin', href: '/admin/user/partnerloginback'});
  }
  if (attributes['be_back_to_manager']) {
    actions.push({graphic: 'open_in_browser', label: 'Back To Manager Admin', href: '/admin/user/managerloginback'});
  }
  actions.push({graphic: 'exit_to_app', label: 'Logout', href: '/admin/user/logout'});

  // Setup navigation
  const navigation: NavigationItem[] = [];

  if (attributes['be_logged_in_business_approval_needed']) {
    navigation.push({graphic: 'payment', label: 'Payment', href: '/admin/business/payment'});
    if (attributes['be_logged_in_business_permission_help']) {
      navigation.push({graphic: 'help', label: 'Help', href: '/admin/business/help'});
    }

    return {actions, navigation};
  }

  if (attributes['be_logged_in_partner_id']) {
    navigation.push({
      graphic: 'dashboard', label: 'Dashboard', href: '/admin/partner', sublinks: {
        'Businesses': '/admin/partner',
        'Contact Us Submissions': '/admin/partner/contactussubmissions',
      },
    });
    navigation.push({
      graphic: 'storefront', label: 'Marketing Website', href: '/admin/partner/website', sublinks: {
        'Website': '/admin/partner/website',
        'Categories': '/admin/partner/categories',
      },
    });
    navigation.push({
      graphic: 'settings', label: 'Account', href: '/admin/partner/account', sublinks: {
        'Partner Account': '/admin/partner/account',
      },
    });
  } else if (attributes['be_logged_in_business_id']) {
    navigation.push({graphic: 'dashboard', label: 'Dashboard', href: '/admin/business/index'});

    if (attributes['be_logged_in_business_permission_app_design']) {
      navigation.push({
        graphic: 'create', label: 'Create Your App', href: '/admin/business/app#listing-details', sublinks: {
          'Listing Details': '/admin/business/app#listing-details',
          'App Design': '/admin/business/app#app-design',
          'Third Party Integrations': '/admin/business/app#integrations',
          'Reviews': '/admin/business/app#reviews',
          'App Add Ons': '/admin/business/app#add-ons',
        },
      });
    }
    if (attributes['be_logged_in_business_permission_programs']) {
      navigation.push({
        graphic: 'loyalty', label: 'Loyalty Programs', href: '/admin/business/programsdashboard', sublinks: {
          'Dashboard': '/admin/business/programsdashboard',
          'Mobile Punch Card': '/admin/business/programs',
          'Cash Back Now': '/admin/business/cashbacknowindex',
          'Send Rewards': '/admin/business/sendreward',
          'Mobile Deals': '/admin/business/programsdeals',
          'Events': '/admin/business/events',
          'Social Rewards': '/admin/business/programssharing',
          // <?php if ($sf_user->getAttribute('be_logged_in_business_permission_profile_page')): ?>
          //     'Profile Design': '/admin/business/profile',
          // <?php endif; ?>
          'Loyalty Games': '/admin/business/programsgames',
        },
      });
    }
    navigation.push({
      graphic: 'people', label: 'Customers', href: '/admin/business/customers', sublinks: {
        'Customers': '/admin/business/customers',
        'Import': '/admin/business/dataimport',
        'Export': '/admin/business/data',
        'Reports': '/admin/business/reports',
        'Invite': '/admin/business/invite',
        'Email Integrations': '/admin/business/emailintegration',
        'Customer Inbox': '/admin/business/inbox',
        'SMS': '/admin/business/messaging',
      },
    });
    navigation.push({
      graphic: 'rate_review', label: 'Manage Reviews', href: '/admin/business/managereviews', sublinks: {
        'Platform Reviews': '/admin/business/managereviews',
        'Moments': '/admin/business/managetestimonies',
        'Video Messaging': '/admin/business/managevideomessaging',
        '10 Mins. of Fame': '/admin/business/managesocialvideos',
        'Embeds': '/admin/business/manageembeds',
      },
    });
    navigation.push({
      graphic: 'photo_library', label: 'Photo Library', href: '/admin/business/gallery', sublinks: {
        'Gallery': '/admin/business/gallery',
        'Linkable Gallery': '/admin/business/linkablegallery',
      },
    });
    navigation.push({graphic: 'qr_code', label: 'QR', href: '/admin/business/qr'});
    navigation.push({
      graphic: 'settings', label: 'Account Settings', href: '/admin/business/account', sublinks: {
        'Account': '/admin/business/account',
        'Help': '/admin/business/help',
        'Mobile Redirect': '/admin/business/mobileredirect',
        'Plans': '/admin/business/plan',
      },
    });
  }

  return {actions, navigation};
}

export function mobile(): ApplicationNavigation {
  // Setup actions
  const actions: ActionItem[] = [];
  actions.push({graphic: 'more_vert', label: 'More', href: ''});

  // Setup navigation
  const navigation: NavigationItem[] = [];

  const base = businessUrl();

  navigation.push({
    graphic: 'explore', label: 'Explore', href: `${base}#explore`, sublinks: {
      'Events': `${base}/events`,
      '360 See Inside Tour': `${base}/contact`,
      'Reviews & Feedback': `${base}/reviews`,
      'Comment Camera': `${base}/testimony`,
      'Video Messaging': `${base}/videomessaging`,
      '10 Mins. of Fame': `${base}/socialvideo`,
      'Shop Online': ``, // TODO we need the actual business to determine these links
      'Twitter': ``,
      'Linkedin': ``,
      'Pinterest': ``,
      'Amazon': ``,
      'See Photos': `${base}/gallery`,
      'Media': `${base}/gallery/linkablegallery`,
      'Contact & Directions': `${base}/contact`,
      'Wifi Access': `${base}/wifi`,

      // TODO homepage buttons

      'My Profile': `${base}/account`,
      'Make a Mobile Payment': `${base}/deals/mobilepaymentform`,
    },
  });
  navigation.push({graphic: 'home', label: 'Home', href: `${base}#home`});
  navigation.push({
    graphic: 'card_giftcard', label: 'Rewards', href: `${base}#rewards`, sublinks: {
      'Rewards & Coupons': `${base}/rewards`,
      'Daily Deal': `${base}/deals/mobilepurchaseindex`,
      'Social Rewards': `${base}/sharing`,
      'Happy Birthday! Claim your gift!': `${base}/card/spendtunitycard`,
      'Todays Deals': `${base}/deals`,
      'Get Cash Back Now!': `${base}/checkin/cashbacknow`,
      'Get Punch Cards': `${base}/checkin`,
      'Play Spin to Win': ``,
      'VIP Discount Card': `${base}/card`,
      'My Cash Card': `${base}/card/cashcard`,
      'Spendtunity': `${base}/card/newspendtunitycard`,
      'Loyalty Game': `https://preview.checkinreward.com/samplebusiness/games/index/qrcode/2081666`,
      'Request Coupons': ``,
    },
  });

  return {actions, navigation};
}
