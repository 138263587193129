import {customElement} from 'lit/decorators.js';
import {css, html, LitElement} from 'lit';
import {symfony} from '../../../symfony/decorators';
import {CategoryForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/typography/heading-5';
import '../../../components/layout/content-block';
import '../../../components/layout/narrow-content';
import '../../../components/editable-section/editable-sections-wrapper';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/button-link/button-link';
import '../../../components/prominent-notice/prominent-notice';

@customElement('backend-partner-category-page')
export class CategoryPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "name", "review_theme", "exclude_from_main_business_list", "seo_title", "seo_description"]|json', type: Object}) private categoryForm!: CategoryForm;

  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
<narrow-content centered>
    <editable-section
        multipart
        heading="Category Settings"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'label', 'type': 'text', 'label': 'Name', 'name': 'category_form[name]', 'value': this.categoryForm?.name.value},
            {'leadingIcon': 'label', 'type': 'select', 'label': 'Review Theme', 'name': 'category_form[review_theme]', 'value': this.categoryForm?.review_theme.value, 'options': this.categoryForm?.review_theme.options},
            {'leadingIcon': 'label', 'type': 'switch', 'label': 'Exclude from main business list', 'name': 'category_form[exclude_from_main_business_list]', 'value': this.categoryForm?.exclude_from_main_business_list.value ? 'on' : 'off'},
        ]
        }>
    </editable-section>
    <editable-section
        multipart
        heading="SEO"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'label', 'type': 'text', 'label': 'Title', 'name': 'category_form[seo_title]', 'value': this.categoryForm?.seo_title.value},
            {'leadingIcon': 'label', 'type': 'textarea', 'label': 'Description', 'name': 'category_form[seo_description]', 'value': this.categoryForm?.seo_description.value},
        ]
        }>
    </editable-section>
</narrow-content>
    `;
  }
}
