import {css, html, LitElement, unsafeCSS} from 'lit';
import {property} from 'lit/decorators.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import typographyStyles from '@material/typography/dist/mdc.typography.css';

export abstract class BaseTypography extends LitElement {
  @property({type: String}) public tag: string = '';

  protected abstract defaultTag(): string;

  protected abstract mdcTypography(): string;

  static styles = [css`${unsafeCSS(typographyStyles)}`, css`
      :host {
          display: block;
      }
      .typography-tag {
          margin: 0;
          padding: 0;
      }
  `];

  connectedCallback() {
    super.connectedCallback();

    this.tag = this.defaultTag();
  }

  protected render() {
    return html`
        ${unsafeHTML(`<${this.tag} class="typography-tag"><slot class="mdc-typography--${this.mdcTypography()}"></slot></${this.tag}>`)}
    `;
  }
}
