import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('marketing-consumer-index-page')
export class IndexPage extends LitElement {
  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
marketing consumer index
<a href="/signin">Signin</a>
`;
  }
}
