import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {FormSubmissionBase} from './form-submission-base';
import {FormSubmission} from '../form-submission';
import '@material/mwc-button';

@customElement('form-submission-submit')
export class FormSubmissionSubmit extends FormSubmissionBase {
  @property({type: String}) confirm = '';
  @property({type: Boolean, reflect: true}) clicked: boolean = false;

  protected get shouldInclude(): boolean {
    // since there can be multiple submit buttons, we only want to send the one that was clicked
    return this.clicked;
  }

  protected watchedAttributes(): string[] {
    return ['clicked'];
  }

  private handleClick(): void {
    let confirmed = this.confirm === '';

    if (!confirmed) {
      confirmed = window.confirm(this.confirm);
    }

    if (confirmed) {
      this.clicked = true;

      // wrapping the event in setTimeout gives this.clicked a chance to fire the updated
      // event to the parent form-submission element
      setTimeout(() => {
        const event = new CustomEvent(FormSubmission.SUBMIT_FORM_EVENT, {bubbles: true, cancelable: true, composed: true});

        this.dispatchEvent(event);
      }, 0);
    }
  }

  protected render() {
    return html`<mwc-button @click="${this.handleClick}" raised label="${this.value}"></mwc-button>`;
  }
}
