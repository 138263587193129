import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {BottomAppBarItemClicked} from './events/bottom-app-bar-item-clicked';
import '@material/mwc-icon';
import '../../components/typography/body-1';

@customElement('bottom-app-bar-nav-item')
export class BottomAppBarNavItem extends LitElement {
  @property({type: String}) public label: string = '';
  @property({type: String}) public icon: string = '';
  @property({type: String}) public href: string = '';
  @property({type: Boolean}) public active: boolean = false;

  static styles = css`
:host {
    --mdc-icon-size: 1.4rem;
    --mdc-typography-body1-font-size: 0.8rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    opacity: 0.6;
}
:host([active]) {
    opacity: 1;
}
`;

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this.handleClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', this.handleClick);
  }

  private handleClick(): void {
    this.dispatchEvent(new BottomAppBarItemClicked(this.href));
  }

  protected render() {
    return html`
<mwc-icon>${this.icon}</mwc-icon>
<body-1>${this.label}</body-1>
`;
  }
}
