import {property} from 'lit/decorators.js';

export function symfony(options: SymfonyDeclaration): any {
  return property({type: options.type});
}


export function slugify(str: String): string {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

interface SymfonyDeclaration {
  variable: string;
  converter?: string;
  type: Object | Array<any> | String | Number | Boolean;
}
