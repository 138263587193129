import {css, html, LitElement, unsafeCSS} from 'lit';
import {customElement, state, query} from 'lit/decorators.js';
import cardStyles from '@material/card/dist/mdc.card.css';
import {classMap} from 'lit/directives/class-map.js';

@customElement('card-display')
export class CardDisplay extends LitElement {
  @state() private hasActions: boolean = false;
  @query('.mdc-card__primary-action') private primaryAction!: HTMLElement;
  @query('slot[name="actions"]') private actionsSlot!: HTMLSlotElement;

  static styles = [unsafeCSS(cardStyles), css`
:host {
    display: block;
    padding: 5px;
}
.main-content {
    padding: 16px;
}
.mdc-card__actions--hidden {
    display: none;
}
.mdc-card__action-buttons {
    width: 100%;
    justify-content: flex-end;
}
`];

  protected firstUpdated(): void {
    // this.primaryAction.ripple = rippleNode({surfaceNode: this.primaryAction, unbounded: false});

    this.hasActions = this.actionsSlot.assignedNodes().length > 0;
  }

  protected render() {
    return html`
<div class="mdc-card mdc-card__primary-action">
    <slot name="media" class="mdc-card__media"></slot>
    <div class="main-content">
        <slot name="content"></slot>
    </div>
    <div class="mdc-card__actions ${classMap({'mdc-card__actions--hidden': !this.hasActions})}">
        <div class="mdc-card__action-buttons">
            <slot name="actions"></slot>
        </div>
    </div>
</div>
`;
  }
}
