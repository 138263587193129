import {css, LitElement} from 'lit';
import {property} from 'lit/decorators.js';
import {FormSubmission} from '../form-submission';

export abstract class FormSubmissionBase extends LitElement {
  /**
   * These are the only required properties for any implementing subclass. The subclass
   * may also define it's own properties.
   */
  @property({type: String}) name: string = '';
  @property({type: String, reflect: true}) value: string = '';

  /**
   * Override if the subclass needs to have it's own styles.
   */
  static styles = css`
:host {
    display: contents;
}
`;

  /**
   * Some fields (like checkboxes) do not get sent to the server when their value is "off-ish"
   */
  protected abstract get shouldInclude(): boolean;

  /**
   * The file component should override this and return true because we need to take special actions
   * for file inputs.
   */
  protected get isFile(): boolean {
    return false;
  }

  /**
   * Specify which attributes should trigger a 'change' event to the parent <form-submission>
   * By default we only watch the value but some subclasses will want to watch different attributes.
   * @return {string[]}
   */
  protected watchedAttributes(): string[] {
    return ['value'];
  }

  /**
   * The subclasses primary objective is to ensure that it's own "value" property gets updated
   * whenever it's underlying component is changed. This base class will listen for when that
   * value is changed and fire an event to notify the parent <form-submission> element.
   * @param {string} name
   * @param {string?} old
   * @param {string?} value
   */
  attributeChangedCallback(name: string, old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, old, value);

    if (this.watchedAttributes().includes(name)) this.fireEvent();
  }

  protected fireEvent(overrideOptions?: OverrideOptions): void {
    const name = overrideOptions?.name || this.name;
    const value = overrideOptions?.value || this.value;
    const shouldInclude = overrideOptions?.shouldInclude || this.shouldInclude;
    const isFile = overrideOptions?.isFile || this.isFile;

    // composed - so the event bubbles through the shadow DOM boundary
    const event = new CustomEvent(FormSubmission.FIELD_UPDATED_EVENT, {bubbles: true, cancelable: true, composed: true, detail: {name, value, shouldInclude, isFile}});

    this.dispatchEvent(event);
  }
}

interface OverrideOptions {
  name?: string;
  value?: string;
  shouldInclude?: boolean;
  isFile?: boolean;
}
