import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessGallery} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/image-grid/image-grid';
import '../../../components/image-grid/image-grid-item';

@customElement('backend-business-gallery-page')
export class GalleryPage extends LitElement {
  @symfony({variable: '$photos', converter: 'take:["id", "business_id", "filename"]|json', type: Array}) private photos: BusinessGallery[] = [];

  protected render() {
    return html`
<narrow-content>
    <editable-section
        heading="Upload New Photo"
        description="Image should be at least 600x600 pixels"
        submitName="save"
        multipart
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'cloud_upload', 'type': 'file', 'label': 'Choose a new photo for your gallery', 'name': 'business_gallery[filename]', 'value': ''},
            {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business_gallery[id]', 'value': ''},
        ]}>
    </editable-section>

</narrow-content>

<image-grid>
    ${this.photos.map((photo) => html`
        <image-grid-item deleteUrl="/admin/business/deletegalleryimage/image_id/${photo.id}" src="/mobile/images/business/${photo.business_id}/gallery/th${photo.filename}" alt="Gallery Image"></image-grid-item>
    `)}
</image-grid>
`;
  }
}
