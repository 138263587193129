import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {BusinessExtraForm, BusinessForm} from '../../../../symfony/forms';
import {BusinessLocation} from '../../../../symfony/models';
import './hours-editable-section';
import {ItemDisplay} from '../../../../components/editable-section/editable-section';
import '../../../../components/account/account-location';
import '../../../../components/button-link/button-link';
import '../../../../components/editable-section/editable-sections-wrapper';
import '../../../../components/typography/body-1';

@customElement('app-page-tab-listing-details')
export class AppPageTabListingDetails extends LitElement {
  @property({type: Object}) public businessForm!: BusinessForm;
  @property({type: Object}) public businessExtraForm!: BusinessExtraForm;
  @property({type: Array}) public locations: BusinessLocation[] = [];

  protected render() {
    return html`
<editable-sections-wrapper>
    <editable-section
        multipart
        heading="Listing Info"
        description="Here you can update your email and/or password that you use to login."
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'label', 'type': 'text', 'label': 'Listing Name', 'name': 'business[name]', 'value': this.businessForm?.name.value},
            {'leadingIcon': 'phone', 'type': 'text', 'label': 'Listing Phone', 'name': 'business[phone_one]', 'value': this.businessForm?.phone_one.value},
            {'leadingIcon': 'visibility_off', 'type': 'switch', 'label': 'Hide Phone Number', 'name': 'business[hide_phone]', 'value': this.businessForm?.hide_phone.value ? 'on' : 'off', 'help': 'Prevents the public from seeing your phone number.'},
            {'leadingIcon': 'web', 'type': 'text', 'label': 'Website URL', 'name': 'business[website]', 'value': this.businessForm?.website.value},
            {'leadingIcon': 'label_important', 'type': 'text', 'label': 'Tagline', 'name': 'business_extra[desktop_tagline]', 'value': this.businessExtraForm?.desktop_tagline.value, 'help': 'Create your own personalized message to appear on desktop banner.'},
            {'leadingIcon': 'article', 'type': 'textarea', 'label': 'Listing Description', 'name': 'business[description]', 'value': this.businessForm?.description.value},
        ]}>
    </editable-section>
    <editable-section
        heading="Locations"
        description="List all your locations so that local customers will be able to find you when browsing listings."
        submitName="locations"
        individualEdits>
        ${this.locations.length > 0 ? html`
            ${this.locations.map((location) => html`
                <account-location
                name="${location.name}"
                phone="${location.phone_one}"
                address="${location.address_one}, ${location.city}, ${location.State.initials} ${location.zip_code}"
                editHref="/admin/business/locationsedit/id/${location.id}"
                deleteHref="/admin/business/locationsdelete/id/${location.id}"
                ?showDelete="${this.locations.length > 1}"
                >
                </account-location>
            `)}
            <button-link href="/admin/business/locationsadd">Add More</button-link>
        ` : html`
            <body-1>There are no locations setup.</body-1>
        `}
    </editable-section>
    <hours-editable-section .businessExtraForm="${this.businessExtraForm}"></hours-editable-section>
</editable-sections-wrapper>
`;
  }
}
