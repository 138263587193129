import {customElement} from 'lit/decorators.js';
import {css, html, LitElement} from 'lit';
import {symfony} from '../../../symfony/decorators';
import {Category} from '../../../symfony/models';
import '../../../components/button-link/button-link';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/layout/content-block';
import '@material/mwc-icon/mwc-icon';

@customElement('backend-partner-categories-page')
export class CategoriesPage extends LitElement {
    @symfony({variable: '$categories', converter: 'take:["id", "name"]|json', type: Array}) private categories: Category[] = [];

  static styles = css`
:host {
  display: block;
}
`;

  protected render() {
    return html`
<content-block textCenter>
    <button-link raised href="/admin/partner/category">Create New Category</button-link>
</content-block>
<data-table fullWidth>
    <data-table-head>
        <data-table-row>
            <data-table-cell>Name</data-table-cell>
            <data-table-cell>Edit</data-table-cell>
            <data-table-cell>Delete</data-table-cell>
        </data-table-row>
    </data-table-head>
    <data-table-body class="sortabletable">
        <!-- TODO: Make table sortable-->
        ${this.categories.map((category) => html`
        <data-table-row>
            <data-table-cell>${category.name}</data-table-cell>
            <data-table-cell link="/admin/partner/category/id/${category.id}">Edit</data-table-cell>
            <data-table-cell confirm="Are you sure you want to delete this category? This will unlink all businesses attached to it!" link="/admin/partner/categorydelete/id/${category.id}"><mwc-icon>delete</mwc-icon></data-table-cell>
        </data-table-row>
        `)}
    </data-table-body>
</data-table>
    `;
  }
}
