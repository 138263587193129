import {LitElement, html, css} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import '@material/mwc-icon';

@customElement('help-tooltip')
export class HelpTooltip extends LitElement {
  @property({type: String}) public tooltip: string = '';
  @property({type: Boolean, reflect: true}) public active: boolean = false;
  @state() private isMouseOver: boolean = false;
  @state() private hasClicked: boolean = false;

  static styles = css`
    :host {
      display: inline-block;
      padding: 0 10px;
    }
    :host([active]) .tooltip {
      display: block;
    }
    .tooltip {
      display: none;
      max-width: 200px;
      background-color: var(--primary-color);
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;

      /* Position the tooltip */
      position: absolute;
      margin-left: 30px;
    }
    .help-icon {
      color: var(--primary-color);
      margin-left: 5px;
      --mdc-icon-size: 18px;
      cursor: pointer;
    }
  `;

  constructor() {
    super();

    // show the tooltip when hovered over
    this.addEventListener('mouseover', () => {
      this.isMouseOver = true;
      this.active = true;
    });
    // hide the tooltip when the not hovered over, unless the user has recently clicked
    this.addEventListener('mouseout', () => {
      this.isMouseOver = false;
      this.active = this.hasClicked ? this.active : false;
    });
    // show the tooltip for 2 seconds when we are clicked (and potentially longer if the user remains hovered)
    this.addEventListener('click', () => {
      this.hasClicked = true;
      this.active = true;

      setTimeout(() => {
        this.hasClicked = false;
        this.active = this.isMouseOver ? this.active : false;
      }, 2000);
    });
  }

  protected render() {
    return html`
      <span class="tooltip">${this.tooltip}</span>
      <mwc-icon class="help-icon">help</mwc-icon>
    `;
  }
}
