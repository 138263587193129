import {html, LitElement} from 'lit';
import {customElement, state, property, query} from 'lit/decorators.js';
import {BusinessExtraForm, BusinessForm} from '../../../../symfony/forms';
import {BusinessCustomField, BusinessExtra, BusinessHomepageButton} from '../../../../symfony/models';
import {SelectOption} from '../../../../components/form-submission/fields/form-submission-select';
import {FormSubmissionTextfield} from '../../../../components/form-submission/fields/form-submission-textfield';
import {ItemDisplay} from '../../../../components/editable-section/editable-section';
import '@material/mwc-button';
import '../../../../components/actionable-list-item/actionable-list-item';
import '../../../../components/button-link/button-link';
import '../../../../components/editable-section/editable-sections-wrapper';
import '../../../../components/open-dialog-button/open-dialog-button';
import '../../../../components/sortable-list/sortable-list';
import '../../../../components/sortable-list/sortable-list-item';

@customElement('app-page-tab-app-design')
export class AppPageTabAppDesign extends LitElement {
  @property({type: Object}) public businessForm!: BusinessForm;
  @property({type: Object}) public businessExtra!: BusinessExtra;
  @property({type: Object}) public businessExtraForm!: BusinessExtraForm;
  @property({type: String}) public baseBusinessImageDir!: string;
  @property({type: Array}) public customFields: BusinessCustomField[] = [];
  @state() private customLinks: BusinessHomepageButton[] = [];
  @query('#add-button-text-field') private addButtonTextField!: FormSubmissionTextfield;
  @query('#add-button-link-field') private addButtonLinkField!: FormSubmissionTextfield;

  connectedCallback() {
    super.connectedCallback();

    this.customLinks = this.businessForm.BusinessHomepageButton.map((buttonForm) => {
      return {
        id: +buttonForm.id.value,
        button_text: buttonForm.button_text.value,
        button_link: buttonForm.button_link.value,
      };
    });
  }

  private imageValue(value?: string): string {
    if (!value) {
      return '';
    }

    return this.baseBusinessImageDir + value;
  }

  private webCoverOptions(): SelectOption[] {
    const options = ['woman', 'professional', 'girl', 'black', 'blue', 'blue gray', 'bright yellow', 'brown', 'gray', 'green',
      'light blue', 'light green', 'magenta', 'orange', 'pink', 'purple', 'red', 'taupe', 'teal', 'yellow'];

    return options.map((option) => {
      return {
        key: option.replace(/\s+/g, ''), // remove spaces
        label: option.replace(/\b\w/g, (l) => l.toUpperCase()), // capitalize words
        selected: this.businessExtraForm.web_cover.value === option,
      };
    });
  }

  private customLinksAsItemDisplay(): ItemDisplay[] {
    const items: ItemDisplay[] = [];

    this.customLinks.forEach((button: BusinessHomepageButton, index: number) => {
      const isNewButton = button.id === undefined;
      const textName = isNewButton ? 'business[newHomepageButton][0][button_text]' : `business[BusinessHomepageButton][${index}][button_text]`;
      const linkName = isNewButton ? 'business[newHomepageButton][0][button_link]' : `business[BusinessHomepageButton][${index}][button_link]`;

      items.push({leadingIcon: '', type: 'hidden', label: '', value: button.button_text, name: textName});
      items.push({leadingIcon: '', type: 'hidden', label: '', value: button.button_link, name: linkName});
    });

    return items;
  }

  private handleOpenDialogButtonPrimaryClicked(): void {
    const textValue = this.addButtonTextField.value;
    const linkValue = this.addButtonLinkField.value;

    if (textValue === '' || linkValue === '') return;

    this.customLinks = [...this.customLinks, {button_text: textValue, button_link: linkValue}];
  }

  private handleDeleteCustomLinkClicked(index: number): void {
    // if its a new button, simply remove it from the array
    // if its an existing button, there is a dedicated page we need to navigate to
    const isNewButton = this.customLinks[index].id === undefined;

    if (isNewButton) {
      this.customLinks = this.customLinks.filter((l: BusinessHomepageButton, i: number) => i !== index);

      return;
    }

    if (window.confirm('Are you sure?')) {
      window.location.href = `/admin/business/deletehomepagebutton/id/${this.customLinks[index].id}`;
    }
  }

  /**
   * the backend can only handle adding 1 link at a time
   * @return {boolean}
   */
  private hasNewCustomLinkBeenAdded(): boolean {
    const found = this.customLinks.find((button: BusinessHomepageButton) => button.id === undefined);

    return found !== undefined;
  }

  protected render() {
    return html`
<editable-sections-wrapper>
    <editable-section
        multipart
        heading="Main App & Profile Image"
        description="Update your app images and other aspects of it's design."
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'person', 'type': 'switch', 'label': 'Use Personal Layout', 'name': 'business_extra[use_personal_layout]', 'value': this.businessExtraForm?.use_personal_layout.value ? 'on' : 'off'},
            {'leadingIcon': 'label', 'type': 'text', 'label': 'App Title', 'name': 'business_extra[app_title]', 'value': this.businessExtraForm?.app_title.value},
            {'leadingIcon': 'fireplace', 'type': 'switch', 'label': 'Display Business Image As Logo', 'name': 'business_extra[display_business_image_as_logo]', 'value': this.businessExtraForm?.display_business_image_as_logo.value ? 'on' : 'off', 'help': 'Logo must be a PNG logo image.'},
            {'leadingIcon': 'local_fire_department', 'type': 'file', 'extra': {deleteUrl: '/admin/business/deletelogo'}, 'label': 'Logo', 'name': 'business_extra[logo]', 'value': this.imageValue(this.businessExtraForm?.logo.value)},
            {'leadingIcon': 'font_download', 'type': 'file', 'extra': {deleteUrl: '/admin/business/deleteappicon'}, 'label': 'App Icon', 'name': 'business_extra[app_icon]', 'value': this.imageValue(this.businessExtraForm?.app_icon.value), 'help': 'The icon that will be used as a shortcut to your app on iPhone devices.'},
            {'leadingIcon': 'corporate_fare', 'type': 'file', 'extra': {deleteUrl: '/admin/business/deleteaffiliationlogo'}, 'label': 'Affiliation Logo', 'name': 'business_extra[affiliation_logo]', 'value': this.imageValue(this.businessExtraForm?.affiliation_logo.value), 'help': 'Shown on the personal layout only.'},
            {'leadingIcon': 'color_lens', 'type': 'combination', 'label': 'App Colors', 'value': 'Custom', 'inputs': [
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Background Color', 'name': 'business_extra[color_background]', 'value': this.businessExtraForm?.color_background.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Font Color', 'name': 'business_extra[color_font]', 'value': this.businessExtraForm?.color_font.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Link Color', 'name': 'business_extra[color_link]', 'value': this.businessExtraForm?.color_link.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Line Font Color', 'name': 'business_extra[color_line]', 'value': this.businessExtraForm?.color_line.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Button Color', 'name': 'business_extra[button]', 'value': this.businessExtraForm?.button.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Button Font Color', 'name': 'business_extra[button_font]', 'value': this.businessExtraForm?.button_font.value},
            ]},
            {'leadingIcon': 'smart_button', 'type': 'select', 'label': 'Checkin Button Text', 'name': 'business_extra[button_text]', 'value': this.businessExtraForm?.button_text.value, 'options': this.businessExtraForm?.button_text.options, 'help': 'The text that occupies the check-in button.'},
        ]}>
    </editable-section>

    <editable-section
        multipart
        heading="Desktop Design"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'insert_photo', 'type': 'file', 'extra': {deleteUrl: '/admin/business/deletecover'}, 'label': 'Custom Cover', 'name': 'business_extra[web_cover_custom]', 'value': this.imageValue(this.businessExtraForm?.web_cover_custom.value)},
            {'leadingIcon': 'dynamic_feed', 'type': 'select', 'label': 'Cover', 'name': 'business_extra[web_cover]', 'value': this.businessExtraForm?.web_cover.value, 'options': this.webCoverOptions()},
            {'leadingIcon': 'ondemand_video', 'type': 'text', 'label': 'Promotional YouTube Video', 'name': 'business_extra[youtube_video]', 'value': this.businessExtraForm?.youtube_video.value},
            {'leadingIcon': 'color_lens', 'type': 'combination', 'label': 'Desktop Colors', 'value': 'Custom', 'inputs': [
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Background Color', 'name': 'business_extra[web_color_background]', 'value': this.businessExtraForm?.web_color_background.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Font Color', 'name': 'business_extra[web_color_font]', 'value': this.businessExtraForm?.web_color_font.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Link Color', 'name': 'business_extra[web_color_link]', 'value': this.businessExtraForm?.web_color_link.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Button Font Color', 'name': 'business_extra[web_button_font]', 'value': this.businessExtraForm?.web_button_font.value},
                {'leadingIcon': 'color_lens', 'type': 'text', 'label': 'Button Color', 'name': 'business_extra[web_button]', 'value': this.businessExtraForm?.web_button.value},
            ]},
        ]}>
    </editable-section>

    <editable-section
        multipart
        heading="Extra Customization"
        description="Here you can update multiple aspects of your app. Hide & show different pages, configure user registration fields, and more."
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'tab', 'type': 'combination', 'label': 'Enable/Disable Pages', 'value': '', 'inputs': [
                {'type': 'switch', 'label': 'Show Testimony Tab', 'name': 'business_extra[show_testimony_tab]', 'value': this.businessExtraForm?.show_testimony_tab.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Video Messaging Tab', 'name': 'business_extra[show_videomessaging_tab]', 'value': this.businessExtraForm?.show_videomessaging_tab.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show 10 Mins. To Fame Tab', 'name': 'business_extra[show_socialvideo_tab]', 'value': this.businessExtraForm?.show_socialvideo_tab.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Your Customers Reviews', 'name': 'business_extra[show_reviews_tab]', 'value': this.businessExtraForm?.show_reviews_tab.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Yelp Reviews', 'name': 'business_extra[show_yelp_reviews]', 'value': this.businessExtraForm?.show_yelp_reviews.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Foursquare Reviews', 'name': 'business_extra[show_foursquare_reviews]', 'value': this.businessExtraForm?.show_foursquare_reviews.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Google Reviews', 'name': 'business_extra[show_google_reviews]', 'value': this.businessExtraForm?.show_google_reviews.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Citygrid Reviews', 'name': 'business_extra[show_citygrid_reviews]', 'value': this.businessExtraForm?.show_citygrid_reviews.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Facebook Reviews', 'name': 'business_extra[show_facebook_reviews]', 'value': this.businessExtraForm?.show_facebook_reviews.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Street View', 'name': 'business_extra[show_street_view]', 'value': this.businessExtraForm?.show_street_view.value ? 'on' : 'off'},
            ]},
            {'leadingIcon': 'notes', 'type': 'textarea', 'label': 'Homepage Text', 'name': 'business_extra[homepage_text]', 'value': this.businessExtraForm?.homepage_text.value},
            {'leadingIcon': 'short_text', 'type': 'textarea', 'label': 'Profile Text', 'name': 'business_extra[profile_text]', 'value': this.businessExtraForm?.profile_text.value},
            {'leadingIcon': 'person_add', 'type': 'combination', 'label': 'Standard Registration Fields', 'value': 'Name/DOB/Gender/Zip', 'inputs': [
                {'type': 'switch', 'label': 'Show First Name Field', 'name': 'business_extra[reg_show_first]', 'value': this.businessExtraForm?.reg_show_first.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Require First Name Field', 'name': 'business_extra[reg_require_first]', 'value': this.businessExtraForm?.reg_require_first.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Last Name Field', 'name': 'business_extra[reg_show_last]', 'value': this.businessExtraForm?.reg_show_last.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Require Last Name Field', 'name': 'business_extra[reg_require_last]', 'value': this.businessExtraForm?.reg_require_last.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Phone Field', 'name': 'business_extra[reg_show_phone]', 'value': this.businessExtraForm?.reg_show_phone.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Require Phone Field', 'name': 'business_extra[reg_require_phone]', 'value': this.businessExtraForm?.reg_require_phone.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Birthday Field', 'name': 'business_extra[reg_show_dob]', 'value': this.businessExtraForm?.reg_show_dob.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Require Birthday Field', 'name': 'business_extra[reg_require_dob]', 'value': this.businessExtraForm?.reg_require_dob.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Gender Field', 'name': 'business_extra[reg_show_gender]', 'value': this.businessExtraForm?.reg_show_gender.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Require Gender Field', 'name': 'business_extra[reg_require_gender]', 'value': this.businessExtraForm?.reg_require_gender.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Show Zip Field', 'name': 'business_extra[reg_show_zip]', 'value': this.businessExtraForm?.reg_show_zip.value ? 'on' : 'off'},
                {'type': 'switch', 'label': 'Require Zip Field', 'name': 'business_extra[reg_require_zip]', 'value': this.businessExtraForm?.reg_require_zip.value ? 'on' : 'off'},
            ]},
        ]}>
    </editable-section>
    
    <editable-section
        multipart
        individualEdits
        heading="Custom Links"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items="${this.customLinksAsItemDisplay()}">
        
        ${this.customLinks.map((button: BusinessHomepageButton, index: number) => html`
        <actionable-list-item
            heading="${button.button_text}"
            subHeading="${button.button_link}">
            <mwc-button slot="actions" label="Delete" @click="${() => this.handleDeleteCustomLinkClicked(index)}"></mwc-button>
        </actionable-list-item>
        `)}
        
        ${!this.hasNewCustomLinkBeenAdded() ? html`
        <open-dialog-button label="Add A Custom Link" primaryLabel="Add" @open-dialog-button-primary-clicked="${this.handleOpenDialogButtonPrimaryClicked}">
            <form-submission-textfield id="add-button-text-field" name="business[newHomepageButton][0][button_text]" label="Text" value=""></form-submission-textfield>
            <form-submission-textfield id="add-button-link-field" name="business[newHomepageButton][0][button_link]" label="Link" value=""></form-submission-textfield>
        </open-dialog-button>
        ` : ''}
    </editable-section>
    
    <editable-section
        multipart
        heading="Custom Registration Fields"
        description=""
        alignContent="right"
        submitName="submit_save"
        individualEdits>
        
        <sortable-list url="/admin/business/customfieldorder" fieldName="fields">
            ${this.customFields.map((field) => html`
            <sortable-list-item sortId="${field.id}">
                <actionable-list-item
                    heading="${field.name}"
                    subHeading="${field.BusinessCustomFieldType.name}"
                    .actions=${[{'href': `/admin/business/customfield/id/${field.id}`, 'label': 'Edit'}, {'href': `/admin/business/customfielddelete/id/${field.id}`, 'label': 'Delete', 'confirm': 'This will delete all consumers data for this field. Are you sure?'}]}>
                </actionable-list-item>
            </sortable-list-item>
            `)}
        </sortable-list>
        
        <button-link href="/admin/business/customfield">Add Field</button-link>
    </editable-section>

    <editable-section
        multipart
        heading="Check-In Mobile Ad Banner"
        description="This is a pop-up ad that will be presented to your customers upon checking in. Promote an offer, events, services, etc..."
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'ad_units', 'type': 'combination', 'label': 'Mobile Ad', 'value': `Total Clicks: ${this.businessExtra.mobile_ad_clicks || 0}`, 'inputs': [
                {'type': 'file', 'label': 'Mobile Ad Image', 'name': 'business_extra[mobile_ad_image]', 'value': this.imageValue(this.businessExtraForm?.mobile_ad_image.value)},
                {'type': 'text', 'label': 'Mobile Ad Header', 'name': 'business_extra[mobile_ad_header]', 'value': this.businessExtraForm?.mobile_ad_header.value},
                {'type': 'textarea', 'label': 'Mobile Ad Description', 'name': 'business_extra[mobile_ad_description]', 'value': this.businessExtraForm?.mobile_ad_description.value},
                {'type': 'text', 'label': 'Mobile Ad Link', 'name': 'business_extra[mobile_ad_link]', 'value': this.businessExtraForm?.mobile_ad_link.value},
                {'type': 'text', 'label': 'Mobile Ad Start Date', 'name': 'business_extra[mobile_ad_start_date]', 'value': this.businessExtraForm?.mobile_ad_start_date.value},
                {'type': 'text', 'label': 'Mobile Ad End Date', 'name': 'business_extra[mobile_ad_end_date]', 'value': this.businessExtraForm?.mobile_ad_end_date.value},
                {'type': 'text', 'label': 'Mobile Ad Quantity', 'name': 'business_extra[mobile_ad_quantity]', 'value': this.businessExtraForm?.mobile_ad_quantity.value, 'help': 'The number of times this ad can be clicked before it ceases to show up. Blank is unlimited.'},
            ]},
        ]}>
    </editable-section>
</editable-sections-wrapper>
`;
  }
}
