import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Business, BusinessConsumer, PartnerPlan, PartnerPlanFeatures} from '../../../symfony/models';
import '../../../components/typography/body-1';
import '../../../components/typography/heading-5';
import '../../../components/layout/content-block';
import '../../../components/layout/narrow-content';
import '../../../components/open-dialog-button/open-dialog-button';
import '../../../components/pricing-table/pricing-table';
import '../../../components/pricing-table/pricing-table-plan';
import '../../../components/pricing-table/pricing-table-plan-feature';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-radio';
import '../../../components/form-submission/fields/form-submission-submit';

@customElement('backend-business-plan-page')
export class PlanPage extends LitElement {
  @symfony({variable: '$business', converter: 'take:["partner_plan_id"]|json', type: Object}) private business!: Business;
  @symfony({variable: '$consumers', converter: 'take:["id"]|json', type: Array}) private consumers: BusinessConsumer[] = [];
  @symfony({variable: '$rewards', converter: 'take:["id"]|json', type: Array}) private rewards: BusinessConsumer[] = [];
  @symfony({variable: '$rewards_share', converter: 'take:["id"]|json', type: Array}) private rewardsShare: BusinessConsumer[] = [];
  @symfony({variable: '$deals', converter: 'take:["id"]|json', type: Array}) private deals: BusinessConsumer[] = [];
  @symfony({variable: '$rewards_games', converter: 'take:["id"]|json', type: Array}) private rewardsGames: BusinessConsumer[] = [];
  @symfony({variable: '$qrs', converter: 'take:["id"]|json', type: Array}) private qrs: BusinessConsumer[] = [];
  @symfony({variable: '$plans', converter: 'dynamic_property:"isAvailable":"$consumers, $rewards, $rewards_share, $deals, $rewards_games, $qrs, $rewards_tablet, $rewards_nh, $business"|take:["isAvailable", "id", "name", "status_id", "price", "frequency_text", "price_text", "consumer_type", "consumer_max", "messaging_type", "messaging", "messaging_cost", "tablet_loyalty", "nohardware_loyalty", "punch_card_programs", "mobile_deals", "mobile_games", "sharing_programs", "qr_codes"]|json', type: Array}) private plans: PartnerPlan[] = [];
  @symfony({variable: '$features', converter: 'take:["id", "name", "plan_one", "plan_two", "plan_three"]|json', type: Array}) private features: PartnerPlanFeatures[] = [];

  private plansWithStatusId(id: number): PartnerPlan[] {
    return this.plans.filter((plan) => plan.status_id == id);
  }

  private isFeatureAvailable(feature: PartnerPlanFeatures, index: number): boolean {
    type PlanProperty = 'plan_one' | 'plan_two' | 'plan_three';

    const map = new Map<number, PlanProperty>([[0, 'plan_one'], [1, 'plan_two'], [2, 'plan_three']]);

    const property = map.get(index);

    return property ? feature[property] : false;
  }

  protected render() {
    return html`
    <narrow-content centered>
        <form-submission>

            <content-block centered>
                <form-submission-submit name="submit_save" value="Save Plan Selection"></form-submission-submit>

                <open-dialog-button label="Show Current Statistics">
                    <heading-5>Current Statistics</heading-5>
                    <body-1># of Customers: ${this.consumers.length}</body-1>
                    <body-1># of Mobile Punch Cards: ${this.rewards.length}</body-1>
                    <body-1># of Social Rewards: ${this.rewardsShare.length}</body-1>
                    <body-1># of Mobile Deals: ${this.deals.length}</body-1>
                    <body-1># of Loyalty Games: ${this.rewardsGames.length}</body-1>
                    <body-1># of QR Codes: ${this.qrs.length}</body-1>
                </open-dialog-button>
            </content-block>

            <content-block>
                <pricing-table>
                    ${this.plansWithStatusId(1).map((plan) => html`
                    <pricing-table-plan planName="${plan.name}" price="${plan.price * 100}" priceFrequency="${plan.frequency_text}" priceText="${plan.price_text}">
                        ${plan.isAvailable ? html`
                        <form-submission-radio slot="plan-selection" name="partner_plan_id" value="${plan.id}" ?checked="${plan.id == this.business.partner_plan_id}"></form-submission-radio>
                        ` : ''}
                    
                        <pricing-table-plan-feature label="Customers" text="${plan.consumer_type == 2 ? 'Unlimited' : `Up to ${plan.consumer_max}`}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="Messaging Customers" text="${plan.messaging_type == 1 ? `Up to ${plan.messaging}/mo` : `${plan.messaging_cost}/message`}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="Table Loyalty" ?available="${plan.tablet_loyalty}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="No Hardware Loyalty" ?available="${plan.nohardware_loyalty}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="Mobile Punch Card" ?available="${plan.punch_card_programs}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="Mobile Deals" ?available="${plan.mobile_deals}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="Loyalty Games" ?available="${plan.mobile_games}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="Social Rewards" ?available="${plan.sharing_programs}"></pricing-table-plan-feature>
                        <pricing-table-plan-feature label="QR Codes" ?available="${plan.qr_codes}"></pricing-table-plan-feature>
                        
                        ${this.features.map((feature, index) => html`
                        <pricing-table-plan-feature label="${feature.name}" ?available="${this.isFeatureAvailable(feature, index)}"></pricing-table-plan-feature>
                        `)}
                    </pricing-table-plan>
                    `)}
                </pricing-table>
            </content-block>

        </form-submission>
    </narrow-content>
`;
  }
}
