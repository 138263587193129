import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessCustomFieldValueForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';

@customElement('backend-business-customfieldvalue-page')
export class CustomfieldvaluePage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "value"]|json', type: Object}) private form!: BusinessCustomFieldValueForm;

  protected render() {
    return html`
<narrow-content centered>
    <editable-section
        heading="Custom Field Value"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'label', 'type': 'text', 'label': 'Value', 'name': 'business_custom_field_value[value]', 'value': this.form.value.value},
            {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business_custom_field_value[id]', 'value': this.form.id.value},
        ]}>
    </editable-section>
    
</narrow-content>
`;
  }
}
