import {html, LitElement, unsafeCSS} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '@material/mwc-button';
import {style} from './prominent-notice-css';
import {classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';
import {EditableSection} from '../editable-section/editable-section';
import themeStyles from '@material/theme/dist/mdc.theme.css';
import '../../components/typography/heading-5';
import '../../components/typography/subtitle-1';
import '../../components/themable-image/themeable-image';

@customElement('prominent-notice')
export class ProminentNotice extends LitElement {
  @property({type: String}) image = '';
  @property({type: String}) heading = '';
  @property({type: String}) subtitle = '';
  @property({type: String}) buttonLabel = '';
  @property({type: String}) secondaryButtonLabel = '';
  @property({type: Number}) maxWidth = 420;
  @property({type: Boolean}) large = false;
  @property({type: Boolean}) public nonThemeableImage: boolean = false;

  static styles = [unsafeCSS(themeStyles), style];

  private handleButtonClick() {
    const editableSection = this.querySelector('editable-section');

    if (editableSection) {
      (editableSection as EditableSection).showDialog = true;
    }
  }

  private handleSecondaryButtonClick(): void {
    console.log('secondary clicked');
  }

  protected render() {
    return html`
<div class="prominent-notice ${classMap({'prominent-notice--large': this.large})}" style="${styleMap({maxWidth: `${this.maxWidth}px`})}">
    ${this.nonThemeableImage ? html`
    <img class="prominent-notice__image" src="${this.image}" alt="">
    ` : html`
    <themeable-image class="prominent-notice__image" src="${this.image}"></themeable-image>
    `}
    <heading-5 tag="h2" class="prominent-notice__heading mdc-theme--text-primary-on-light">${this.heading}</heading-5>
    <subtitle-1 class="prominent-notice__subtitle mdc-theme--text-secondary-on-light">${this.subtitle}</subtitle-1>
    ${this.buttonLabel !== '' ? html`<mwc-button @click="${this.handleButtonClick}" id="myButton" label="${this.buttonLabel}" raised></mwc-button>` : ''}
    ${this.secondaryButtonLabel !== '' ? html`<mwc-button @click="${this.handleSecondaryButtonClick}" label="${this.secondaryButtonLabel}"></mwc-button>` : ''}
    
    <slot name="editable-section"></slot>
    <slot></slot>
</div>
`;
  }
}
