import {FormSubmissionBase} from './form-submission-base';
import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import '@material/mwc-formfield';
import '@material/mwc-checkbox';
import {Checkbox} from '@material/mwc-checkbox';

@customElement('form-submission-checkbox')
export class FormSubmissionCheckbox extends FormSubmissionBase {
  @property({type: String}) label = '';
  @property({type: Boolean, reflect: true}) checked = false;
  @property({type: String}) toggleAll = '';
  @query('mwc-checkbox') private mwcCheckbox!: Checkbox;

  protected get shouldInclude(): boolean {
    return this.checked;
  }

  protected watchedAttributes(): string[] {
    return ['checked'];
  }

  private handleChange(): void {
    this.checked = this.mwcCheckbox.checked;

    if (this.toggleAll === '') {
      return;
    }

    const checkboxes = this.parentElement!.querySelectorAll(this.toggleAll);

    checkboxes.forEach((checkbox) => {
      if (this.checked) {
        checkbox.setAttribute('checked', 'true');
      } else {
        checkbox.removeAttribute('checked');
      }
    });
  }

  protected render() {
    return html`<mwc-formfield label="${this.label}"><mwc-checkbox @change="${this.handleChange}" ?checked="${this.checked}"></mwc-checkbox></mwc-formfield>`;
  }
}
