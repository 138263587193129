import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

@customElement('content-block')
export class ContentBlock extends LitElement {
  @property({type: Boolean}) public textRight: boolean = false;
  @property({type: Boolean}) public textCenter: boolean = false;
  @property({type: Boolean}) public centered: boolean = false;

  static styles = css`
:host {
    display: block;
}
.content-block {
    margin: 20px 0;
}
.content-block--textRight {
    text-align: right;
}
.content-block--textCenter {
    text-align: center;
}
.content-block--centered {
    display: flex;
    justify-content: center;
    align-content: center;
}
`;

  protected render() {
    const classes = classMap({
      'content-block--textRight': this.textRight,
      'content-block--textCenter': this.textCenter,
      'content-block--centered': this.centered,
    });

    return html`
<div class="content-block ${classes}">
    <slot></slot>
</div>
`;
  }
}
