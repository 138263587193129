import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {VideoMessage} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/review/review-display';
import '../../../components/review/review-display-action';

@customElement('backend-business-managevideomessaging-page')
export class ManagevideomessagingPage extends LitElement {
  @symfony({variable: '$videoMessages', converter: 'take:["id", "media_url", "Consumer.consumer_feedback_header", "created_at"]|json', type: Array}) private videoMessages: VideoMessage[] = [];

  protected render() {
    return html`
${this.videoMessages.length == 0 ? html`
<prominent-notice
        large
        image="/assets/images/undraw_video_influencer_9oyy.svg"
        heading="Manage Video Messaging"
        subtitle="No video messages at this moment.">
</prominent-notice>
` : ''}

<flex-column-container gap="10" style="justify-content: flex-start;">
    ${this.videoMessages.map((message) => html`
        <flex-column flex="initial" style="max-width: 500px;">
            <review-display
                    media="${message.media_url}"
                    reviewerName="${message.Consumer.consumer_feedback_header}"
                    reviewDate="${date(message.created_at)}">
                <review-display-action slot="actions" href="/admin/business/deletevideomessage/id/${message.id}" label="Delete" confirm="Are you sure you want to delete this video message?"></review-display-action>
            </review-display>
        </flex-column>
    `)}
</flex-column-container>
`;
  }
}
