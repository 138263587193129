import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessDealForm} from '../../../symfony/forms';
import {date} from '../../../helpers/formatters/date-formatter';
import {BusinessDeal} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/thumbnail-image/thumbnail-image';

@customElement('backend-business-programsdeal-page')
export class ProgramsdealPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["deal_image", "deal", "start_date", "end_date"]|json', type: Object}) private form!: BusinessDealForm;
  @symfony({variable: '$deal', converter: '_default/{"business_id":"","deal_image":""}|take:["business_id", "deal_image"]|json', type: Object}) private deal?: BusinessDeal;

  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
<narrow-content centered>
    ${this.deal && this.deal.deal_image ? html`
    <content-block centered>
        <thumbnail-image large src="/mobile/images/business/${this.deal.business_id}/${this.deal.deal_image}"></thumbnail-image>
    </content-block>
    ` : ''}

    <editable-section
        heading="Mobile Deal"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'image', 'type': 'file', 'label': 'Deal Image', 'name': 'business_deal[deal_image]', 'value': ''},
            {'leadingIcon': 'local_activity', 'type': 'text', 'label': 'Deal', 'name': 'business_deal[deal]', 'value': this.form.deal.value},
            {'leadingIcon': 'date_range', 'type': 'combination', 'label': 'Start & End Dates', 'value': `${this.form.start_date.value && this.form.end_date.value ? `${date(this.form.start_date.value)} - ${date(this.form.end_date.value)}` : ''}`, 'inputs': [
                {'leadingIcon': '', 'type': 'date', 'label': 'Start Date', 'name': 'business_deal[start_date]', 'value': this.form.start_date.value},
                {'leadingIcon': '', 'type': 'date', 'label': 'End Date', 'name': 'business_deal[end_date]', 'value': this.form.end_date.value},
            ]},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
