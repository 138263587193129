import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessSupportContactForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';

@customElement('backend-business-help-page')
export class HelpPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["name", "email", "phone", "message"]|json', type: Object}) private form?: BusinessSupportContactForm;

  protected render() {
    return html`
<narrow-content>
    <editable-section
        heading="Support"
        description="Give your gallery a descriptive name."
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'message', 'type': 'combination', 'label': 'Reach out to us for assistance.', 'value': '', 'inputs': [
                {'type': 'text', 'label': 'Name', 'name': 'support[name]', 'value': this.form?.name.value},
                {'type': 'text', 'label': 'Email', 'name': 'support[email]', 'value': this.form?.email.value},
                {'type': 'text', 'label': 'Phone', 'name': 'support[phone]', 'value': this.form?.phone.value},
                {'type': 'textarea', 'label': 'Message', 'name': 'support[message]', 'value': this.form?.message.value},
            ]},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
