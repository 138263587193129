import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Business, BusinessExtra, BusinessReward, BusinessUser} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/floating-action-button/floating-action-button';
import '../../../components/data-table/data-table';
import '../../../components/button-link/button-link';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';

@customElement('backend-business-programs-page')
export class ProgramsPage extends LitElement {
  @symfony({variable: '$reward_checkin', converter: 'take:["id", "name", "security_code", "expires_after", "checkin_times", "checkin_per", "reset", "is_punch_purchase", "punch_purchase_price"]|json', type: Array}) private rewardCheckin: BusinessReward[] = [];
  @symfony({variable: '$business_extra', converter: 'take:["qr_only"]|json', type: Object}) private businessExtra!: BusinessExtra;
  @symfony({variable: '$users', converter: 'take:["id", "User.id", "User.first_name", "User.last_name", "User.email", "User.UserStatus.name"]|json', type: Array}) private users: BusinessUser[] = [];
  @symfony({variable: '$business', converter: 'take:["slug"]|json', type: Object}) private business!: Business;

  private checkinsText(reward: BusinessReward): string {
    if (reward.checkin_times == -1) {
      return 'Unlimited';
    }

    const timesText = reward.checkin_times == 1 ? 'time' : 'times';
    let periodText = 'day';

    if (reward.checkin_per == 3) {
      periodText = 'month';
    }

    if (reward.checkin_per == 2) {
      periodText = 'week';
    }

    return `${reward.checkin_times} ${timesText} per ${periodText}`;
  }

  protected render() {
    return html`
<narrow-content>
    <content-block textRight>
        <floating-action-button href="/admin/business/programscheckin" icon="add" actionLabel="Create Mobile Punch Card"></floating-action-button>
    </content-block>
    
    ${this.rewardCheckin.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Name</data-table-cell>
                <data-table-cell>Security Code</data-table-cell>
                <data-table-cell>Expires</data-table-cell>
                <data-table-cell>Scans</data-table-cell>
                <data-table-cell>Reset</data-table-cell>
                <data-table-cell>Edit</data-table-cell>
                <data-table-cell>Delete</data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.rewardCheckin.map((r) => html`
                <data-table-row>
                    <data-table-cell>${r.is_punch_purchase ? html`$${r.punch_purchase_price}` : r.name}</data-table-cell>
                    <data-table-cell>${r.security_code}</data-table-cell>
                    <data-table-cell>${r.expires_after == 0 ? html`No Expiration` : html`${r.expires_after} months after earned`}</data-table-cell>
                    <data-table-cell>${this.checkinsText(r)}</data-table-cell>
                    <data-table-cell>${r.reset ? 'Yes' : 'No'}</data-table-cell>
                    <data-table-cell link="/admin/business/programscheckin/id/${r.id}">Edit</data-table-cell>
                    <data-table-cell link="/admin/business/programscheckindelete/id/${r.id}" confirm="Are you sure you want to delete this program? This will delete consumer punches that have been recorded for this program.">Delete</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
<content-block textCenter>
    <body-1>You have not setup a mobile punch card</body-1>
</content-block>
    `}
    
    <editable-section
        fullWidth
        multipart
        heading="Settings"
        description="Check if you want to only accept QR code scans as punches/check-ins. This will remove the punch/check-in button on the mobile loyalty app."
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'qr_code', 'type': 'switch', 'label': 'QR Scans Only', 'name': 'qr_only', 'value': this.businessExtra?.qr_only ? 'on' : 'off'},
        ]}>
    </editable-section>
    
    <content-block>
        <body-1>Users can login and manually punch a consumers mobile punch card for them. This will be needed if you have consumers that don't have smart phones. The manual punch portal is located at...<a href="${window.location.origin}/${this.business.slug}/manual" target="_blank">${window.location.origin}/${this.business.slug}/manual</a></body-1>
    </content-block>
    <content-block textCenter>
        <button-link href="/admin/business/usersadd" raised>Add User</button-link>
    </content-block>
    
    ${this.users.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Name</data-table-cell>
                <data-table-cell>Email</data-table-cell>
                <data-table-cell>Status</data-table-cell>
                <data-table-cell>Edit</data-table-cell>
                <data-table-cell>Delete</data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.users.map((user) => html`
                <data-table-row>
                    <data-table-cell>${user.User.first_name} ${user.User.last_name}</data-table-cell>
                    <data-table-cell>${user.User.email}</data-table-cell>
                    <data-table-cell>${user.User.UserStatus.name}</data-table-cell>
                    <data-table-cell link="/admin/business/usersedit/id/${user.User.id}">Edit</data-table-cell>
                    <data-table-cell link="/admin/business/usersdelete/id/${user.User.id}" confirm="Are you sure?">Delete</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : ''}

</narrow-content>
       
`;
  }
}
