import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessConsumerReward, Consumer, ConsumerCustom, LogCheckin} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import '@material/mwc-list';
import '@material/mwc-list/mwc-list-item';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/card-display/card-display';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/layout/content-block';

@customElement('backend-business-customer-page')
export class CustomerPage extends LitElement {
  @symfony({variable: '$customer', converter: 'take:["first_name", "last_name", "email", "phone", "zip_code", "gender", "dob"]|json', type: Object}) private customer!: Consumer;
  @symfony({variable: '$custom_fields', converter: 'take:["id", "value", "BusinessCustomField.name", "BusinessCustomField.type_id", "BusinessCustomFieldValue.value"]|json', type: Array}) private customFields: ConsumerCustom[] = [];
  @symfony({variable: '$rewards', converter: 'take:["reward_name", "BusinessReward.name", "checkins", "RewardStatus.name", "earned", "redeemed"]|json', type: Array}) private rewards: BusinessConsumerReward[] = [];
  @symfony({variable: '$logs', converter: 'take:["BusinessReward.name", "checkin_date"]|json', type: Array}) private logs: LogCheckin[] = [];

  private customFieldValue(field: ConsumerCustom): string {
    if (field.BusinessCustomField.type_id == '1' || field.BusinessCustomField.type_id == '2') {
      return field.value;
    }

    if (field.BusinessCustomField.type_id == '3') {
      return field.value == '1' ? 'Yes' : 'No';
    }

    if (field.BusinessCustomField.type_id == '4') {
      return field.field_value_id ? field.BusinessCustomFieldValue.value : '';
    }

    if (field.BusinessCustomField.type_id == '5') {
      return field.value ? date(field.value) : '';
    }

    return '';
  }

  protected render() {
    return html`
<narrow-content centered>
    <card-display>
        <mwc-list slot="content">
            <mwc-list-item twoline><span>${this.customer.first_name} ${this.customer.last_name}</span><span slot="secondary">Name</span></mwc-list-item>
            <mwc-list-item twoline><span>${this.customer.email}</span><span slot="secondary">Email</span></mwc-list-item>
            <mwc-list-item twoline><span>${this.customer.phone}</span><span slot="secondary">Mobile</span></mwc-list-item>
            <mwc-list-item twoline><span>${this.customer.zip_code}</span><span slot="secondary">Postal Code</span></mwc-list-item>
            <mwc-list-item twoline><span>${this.customer.gender || 'N/A'}</span><span slot="secondary">Gender</span></mwc-list-item>
            <mwc-list-item twoline><span>${date(this.customer.dob)}</span><span slot="secondary">Birthday</span></mwc-list-item>
            ${this.customFields.map((field) => html`
            <mwc-list-item twoline><span>${this.customFieldValue(field)}</span><span slot="secondary">${field.BusinessCustomField.name}</span></mwc-list-item>
            `)}
        </mwc-list>
    </card-display>
    
    
    ${this.rewards.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Reward</data-table-cell>
                <data-table-cell>Punches/Checkins</data-table-cell>
                <data-table-cell>Status</data-table-cell>
                <data-table-cell>Earned</data-table-cell>
                <data-table-cell>Redeemed</data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.rewards.map((reward) => html`
                <data-table-row>
                    <data-table-cell>${reward.reward_name != '' ? reward.reward_name : reward.BusinessReward.name}</data-table-cell>
                    <data-table-cell>${reward.checkins}</data-table-cell>
                    <data-table-cell>${reward.RewardStatus.name}</data-table-cell>
                    <data-table-cell>${reward.earned ? date(reward.earned) : ''}</data-table-cell>
                    <data-table-cell>${reward.redeemed ? date(reward.redeemed) : ''}</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
<content-block textCenter>
    <body-1>This customer has no reward programs.</body-1>
</content-block>
    `}
    
    ${this.logs.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Reward</data-table-cell>
                <data-table-cell>Date</data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.logs.map((log) => html`
                <data-table-row>
                    <data-table-cell>${log.BusinessReward.name}</data-table-cell>
                    <data-table-cell>${date(log.checkin_date)}</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
<content-block textCenter>
    <body-1>This customer has no reward checkins/punches.</body-1>
</content-block>
    `}
    
    
</narrow-content>
`;
  }
}
