import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '@material/mwc-icon';
import '../../components/typography/body-2';
import '../../components/layout/flex-columns/flex-column';
import '../../components/layout/flex-columns/flex-column-container';

@customElement('review-reply')
export class ReviewReply extends LitElement {
  @property({type: String}) public reply: string = '';

  static styles = css`
.review-reply {
    margin: 0 0 0 35px;
    color: rgba(0, 0, 0, 0.54);
}
`;

  protected render() {
    return html`
<flex-column-container class="review-reply">
    <flex-column flex="initial">
        <mwc-icon>subdirectory_arrow_right</mwc-icon>
    </flex-column>
    <flex-column>
        <body-2><strong>Your Reply: </strong>${this.reply}</body-2>
    </flex-column>
</flex-column-container>
`;
  }
}
