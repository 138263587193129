import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '../../components/typography/body-2';
import '../../components/typography/heading-6';
import '../../components/typography/subtitle-2';
import '../../components/card-display/card-display';
import '../../components/form-submission/fields/form-submission-textarea';
import '@material/mwc-button';

@customElement('embed-display')
export class EmbedDisplay extends LitElement {
  @property({type: String}) public redirectTo: string = '';
  @property({type: String}) public embedCodeSrc: string = '';
  @property({type: String}) public type: string = 'review-grade';

  static styles = css`
:host {
    display: block;
    margin-bottom: 15px;
}
.embed-display {
    width: 100%;
    min-width: 450px;
}
.mdc-text-field {
  width: 100%;
}
`;

  protected getEmbed(): string {
    if (this.type == 'review-grade') {
      return '<a href="'+ this.redirectTo + '" target="_blank" style="align-self: center;" alt="review-grade"><img style="width: 100%" src="' + this.embedCodeSrc + '"></a>';
    }

    if (this.type == 'horizontal-reviews-feed') {
      return '<iframe title="reviews feed" src="' + this.embedCodeSrc + '" style="min-height: 18vw; width: 100%;" scrolling="no"></iframe>';
    }

    if (this.type == 'vertical-reviews-feed') {
      return '<iframe title="reviews feed" src="' + this.embedCodeSrc + '" style="min-height: 18vw; width: 100%;" scrolling="yes"></iframe>';
    }

    return '';
  }

  protected getEmbedPreview() {
    if (this.type == 'review-grade') {
      return html`<a href="${this.redirectTo}" slot="media" target="_blank" style="align-self: center;" alt="review-grade"><img style="width: 100%" src="${this.embedCodeSrc}"></a>`;
    }

    if (this.type == 'horizontal-reviews-feed') {
      return html`<iframe title="reviews feed" slot="media" src="${this.embedCodeSrc}" style="min-height: 18vw; width: 100%;" scrolling="no"></iframe>`;
    }

    if (this.type == 'vertical-reviews-feed') {
      return html`<iframe title="reviews feed" slot="media" src="${this.embedCodeSrc}" style="min-height: 18vw; width: 100%;" scrolling="yes"></iframe>`;
    }

    return html``;
  }

  private handleCopyEmbed(output: string) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(output);
  }

  protected render() {
    return html`
<div class="embed-display">
    <card-display>
      ${this.getEmbedPreview()}

      <div slot="content" class="content">
        <form-submission-textarea label="Embed Code" name="code-preview" value='${this.getEmbed()}' rows="5" cols="100"></form-submission-textarea>
      </div>
      <mwc-button slot="actions" label="Copy" icon="code" id="btn-embed-copy" @click="${() => this.handleCopyEmbed(this.getEmbed())}"></mwc-button>
    </card-display>
</div>
`;
  }
}
