import {css, html, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';

@customElement('themeable-image')
export class ThemeableImage extends LitElement {
  @property({type: String}) public src: string = '';
  @query('object') private object!: HTMLObjectElement;

  static styles = css`
:host {
    display: inline-block;
}
object {
    width: 100%;
    height: 100%;
}
`;

  connectedCallback() {
    super.connectedCallback();

    if (!this.src.endsWith('svg')) {
      throw new Error('The themeable-image component can only work with svg images.');
    }
  }

  firstUpdated() {
    // When we download each svg image, we need to download them using this color as the default color.
    // That way we can target all the svg components that are using that color and change them to the new color.
    const defaultPrimaryColor = '#296d6f';

    const themePrimaryColor = getComputedStyle(document.querySelector('app-shell')!).getPropertyValue('--primary-color');

    this.object.addEventListener('load', () => this.updateColor(defaultPrimaryColor, themePrimaryColor));
  }

  private updateColor(fromColor: string, toColor: string): void {
    const fillElements = this.object.contentDocument!.querySelectorAll(`*[fill="${fromColor}"]`);

    fillElements.forEach((el) => el.setAttribute('fill', toColor));
  }

  protected render() {
    return html`
<object data="${this.src}" type="image/svg+xml"></object>
`;
  }
}
