import {html, LitElement, TemplateResult} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessLocationExtraForm, BusinessLocationForm, MultiBusinessLocationForm} from '../../../symfony/forms';
import {State} from '../../../symfony/models';
import {SelectOption} from '../../../components/form-submission/fields/form-submission-select';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';

@customElement('backend-business-locationsadd-page')
export class LocationsaddPage extends LitElement {
  @symfony({
    variable: '$form',
    converter: `form_fields:[
    "newLocations.*.business_location.id",
    "newLocations.*.business_location.public_address",
    "newLocations.*.business_location.name",
    "newLocations.*.business_location.website",
    "newLocations.*.business_location.phone_one",
    "newLocations.*.business_location.address_one",
    "newLocations.*.business_location.city",
    "newLocations.*.business_location.country_id",
    "newLocations.*.business_location.state_id",
    "newLocations.*.business_location.zip_code",
    "newLocations.*.business_location.lat",
    "newLocations.*.business_location.lng",
    "newLocations.*.business_location_extra.id",
    "newLocations.*.business_location_extra.has_atm",
    "newLocations.*.business_location_extra.has_wifi",
    "newLocations.*.business_location_extra.has_restroom",
    "newLocations.*.business_location_extra.is_byob",
    "newLocations.*.business_location_extra.has_refreshments",
    "newLocations.*.business_location_extra.has_santas_mailbox",
    "newLocations.*.business_location_extra.is_sponsor",
    "newLocations.*.business_location_extra.offers_aaa_rewards",
    "newLocations.*.business_location_extra.is_hiring",
    "newLocations.*.business_location_extra.accepts_cash",
    "newLocations.*.business_location_extra.accepts_visa",
    "newLocations.*.business_location_extra.accepts_mastercard",
    "newLocations.*.business_location_extra.accepts_discover",
    "newLocations.*.business_location_extra.accepts_american_express"
    ]|json`,
    type: Object,
  })
  private form!: MultiBusinessLocationForm;

  @symfony({variable: '$states', converter: 'take:["id", "country_id", "name"]|json', type: Array}) private states: State[] = [];


  protected render() {
    return html`
<narrow-content centered>
    ${locationEditTemplate(this.form.newLocations[0].business_location, this.form.newLocations[0].business_location_extra, this.states)}
</narrow-content>
`;
  }
}

/**
 * Since the locationsadd & locationsedit page are so similar, we will share the templates
 * using this factory function.
 * @param {BusinessLocationForm} form
 * @param {BusinessLocationExtraForm} formExtra
 * @param {State[]} states
 * @param {number?} lat
 * @param {number?} lng
 * @return {TemplateResult}
 */
export const locationEditTemplate = function(form: BusinessLocationForm, formExtra: BusinessLocationExtraForm, states: State[], lat?: number, lng?: number): TemplateResult {
  const stateOptions = (countryId: number, states: State[]): SelectOption[] => {
    const stateIdsForCountry = states.filter((state) => +state.country_id === countryId).map((state) => +state.id);

    return form.state_id.options.filter((option) => stateIdsForCountry.includes(+option.key));
  };

  // Different forms are used between the add/edit actions, so submit to the correct form name based on whether we
  // are on the adding or the editing page.
  const isAdding = lat === undefined && lng === undefined;
  const baseFormNameBusiness = isAdding ? 'newLocations[0][business_location]' : 'business_location';
  const baseFormNameBusinessExtra = isAdding ? 'newLocations[0][business_location_extra]' : 'business_location_extra';

  const items: ItemDisplay[] = [
    {'leadingIcon': 'storefront', 'type': 'switch', 'label': 'Will customers be visiting this location?', 'name': `${baseFormNameBusiness}[public_address]`, 'value': form.public_address.value ? 'on' : 'off'},
    {'leadingIcon': 'label', 'type': 'text', 'label': 'Name', 'name': `${baseFormNameBusiness}[name]`, 'value': form.name.value},
    {'leadingIcon': 'web', 'type': 'text', 'label': 'Website URL', 'name': `${baseFormNameBusiness}[website]`, 'value': form.website.value},
    {'leadingIcon': 'phone', 'type': 'text', 'label': 'Phone Number', 'name': `${baseFormNameBusiness}[phone_one]`, 'value': form.phone_one.value},
    {'leadingIcon': 'place', 'type': 'combination', 'label': 'Address', 'value': `${form.address_one.value}, ${form.city.value}, ${form.state_id.options.find((o) => o.selected)?.label} ${form.zip_code.value}`, 'inputs': [
      {'type': 'text', 'label': 'Address One', 'name': `${baseFormNameBusiness}[address_one]`, 'value': form.address_one.value},
      {'type': 'text', 'label': 'City', 'name': `${baseFormNameBusiness}[city]`, 'value': form.city.value},
      {'type': 'select', 'label': 'Country', 'name': `${baseFormNameBusiness}[country_id]`, 'value': form.country_id.value, 'options': form.country_id.options},
      {'type': 'select', 'label': 'State/Province', 'name': `${baseFormNameBusiness}[state_id]`, 'value': form.state_id.value, 'options': stateOptions(+form.country_id.value, states)},
      {'type': 'text', 'label': 'Postal Code', 'name': `${baseFormNameBusiness}[zip_code]`, 'value': form.zip_code.value},
    ]},
  ];

  if (lat && lng) {
    items.push({'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': `${baseFormNameBusiness}[lat]`, 'value': `${lat}`});
    items.push({'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': `${baseFormNameBusiness}[lng]`, 'value': `${lng}`});
  } else {
    items.push({'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': `${baseFormNameBusiness}[id]`, 'value': form.id.value});
  }

  return html`
<editable-section
    fullWidth
    heading="Location"
    description=""
    submitName="submit_save"
    .items=${items}>
</editable-section>

<editable-section
    fullWidth
    heading="Extra Location Info"
    description=""
    submitName="submit_save"
    .items=${<ItemDisplay[]>[
        {'leadingIcon': 'atm', 'type': 'switch', 'label': 'Has atm', 'name': `${baseFormNameBusinessExtra}[has_atm]`, 'value': formExtra.has_atm.value ? 'on' : 'off'},
        {'leadingIcon': 'wifi', 'type': 'switch', 'label': 'Has wifi', 'name': `${baseFormNameBusinessExtra}[has_wifi]`, 'value': formExtra.has_wifi.value ? 'on' : 'off'},
        {'leadingIcon': 'family_restroom', 'type': 'switch', 'label': 'Has restroom', 'name': `${baseFormNameBusinessExtra}[has_restroom]`, 'value': formExtra.has_restroom.value ? 'on' : 'off'},
        {'leadingIcon': 'local_bar', 'type': 'switch', 'label': 'Is byob', 'name': `${baseFormNameBusinessExtra}[is_byob]`, 'value': formExtra.is_byob.value ? 'on' : 'off'},
        {'leadingIcon': 'fastfood', 'type': 'switch', 'label': 'Has refreshments', 'name': `${baseFormNameBusinessExtra}[has_refreshments]`, 'value': formExtra.has_refreshments.value ? 'on' : 'off'},
        {'leadingIcon': 'markunread_mailbox', 'type': 'switch', 'label': 'Has santas mailbox', 'name': `${baseFormNameBusinessExtra}[has_santas_mailbox]`, 'value': formExtra.has_santas_mailbox.value ? 'on' : 'off'},
        {'leadingIcon': 'military_tech', 'type': 'switch', 'label': 'Is sponsor', 'name': `${baseFormNameBusinessExtra}[is_sponsor]`, 'value': formExtra.is_sponsor.value ? 'on' : 'off'},
        {'leadingIcon': 'directions_car', 'type': 'switch', 'label': 'Offers AAA rewards', 'name': `${baseFormNameBusinessExtra}[offers_aaa_rewards]`, 'value': formExtra.offers_aaa_rewards.value ? 'on' : 'off'},
        {'leadingIcon': 'work', 'type': 'switch', 'label': 'Is hiring', 'name': `${baseFormNameBusinessExtra}[is_hiring]`, 'value': formExtra.is_hiring.value ? 'on' : 'off'},
        {'leadingIcon': 'credit_card', 'type': 'combination', 'label': 'Accepted Payment Types', 'value': 'Cash/Cards', 'inputs': [
            {'leadingIcon': '', 'type': 'switch', 'label': 'Accepts cash', 'name': `${baseFormNameBusinessExtra}[accepts_cash]`, 'value': formExtra.accepts_cash.value ? 'on' : 'off'},
            {'leadingIcon': '', 'type': 'switch', 'label': 'Accepts visa', 'name': `${baseFormNameBusinessExtra}[accepts_visa]`, 'value': formExtra.accepts_visa.value ? 'on' : 'off'},
            {'leadingIcon': '', 'type': 'switch', 'label': 'Accepts mastercard', 'name': `${baseFormNameBusinessExtra}[accepts_mastercard]`, 'value': formExtra.accepts_mastercard.value ? 'on' : 'off'},
            {'leadingIcon': '', 'type': 'switch', 'label': 'Accepts discover', 'name': `${baseFormNameBusinessExtra}[accepts_discover]`, 'value': formExtra.accepts_discover.value ? 'on' : 'off'},
            {'leadingIcon': '', 'type': 'switch', 'label': 'Accepts american express', 'name': `${baseFormNameBusinessExtra}[accepts_american_express]`, 'value': formExtra.accepts_american_express.value ? 'on' : 'off'},
        ]},
        {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': `${baseFormNameBusinessExtra}[id]`, 'value': formExtra.id.value},
    ]}>
</editable-section>
`;
};
