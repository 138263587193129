import {html, LitElement, PropertyValues} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import '@material/mwc-icon';
import '@material/mwc-snackbar';
import {Snackbar} from '@material/mwc-snackbar';
import {style} from './alert-message-css';
import {classMap} from 'lit/directives/class-map.js';
import '../../components/typography/body-1';

@customElement('alert-message')
export class AlertMessage extends LitElement {
  @property({type: Array}) messages: string[] = [];
  @property({type: String}) type: 'error' | 'success' = 'error';
  @property({type: Boolean}) toast: boolean = false;
  @query('mwc-snackbar') private snackbar!: Snackbar;

  static styles = style;

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    if (this.toast && this.messages.length > 0) {
      this.snackbar.show();
    }
  }

  protected render() {
    const classes = classMap({
      'alert-message--hidden': this.messages.length === 0,
      'alert-message--error': this.type === 'error',
      'alert-message--success': this.type === 'success',
    });

    return html`
${this.toast ? html`
    <mwc-snackbar labelText="${this.messages.join(' | ')}"></mwc-snackbar>
` : html`
<div class="alert-message ${classes}">
    <mwc-icon class="alert-message__icon">error</mwc-icon>
    <ul class="alert-message__messages">
        ${this.messages.map((message) => html`<li><body-1>${message}</body-1></li>`)}
    </ul>
</div>
`}
`;
  }
}
