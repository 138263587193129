import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {SelectOption} from '../../../components/form-submission/fields/form-submission-select';
import '../../../components/typography/body-2';
import '../../../components/layout/narrow-content';
import '../../../components/email-integration-partner/email-integration-partner';
import '../../../components/layout/content-block';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/form-submission/fields/form-submission-submit';

/* eslint-disable camelcase */
interface BusinessSocialMedia {
  mailchimp_token: string;
  constantcontact_token: string;
  icontact_username: string;
  mailchimp_list_id: number;
  constantcontact_list_id: number;
  icontact_list_id: number;
}
/* eslint-enable camelcase */

interface List {
  id: number;
  name: string;
}

@customElement('backend-business-emailintegration-page')
export class EmailintegrationPage extends LitElement {
  @symfony({variable: '$social', converter: 'take:["mailchimp_token", "constantcontact_token", "icontact_username", "mailchimp_list_id", "constantcontact_list_id", "icontact_list_id"]|json', type: Object}) private social!: BusinessSocialMedia;
  @symfony({variable: '$mailchimp_url', type: String}) private mailchimpUrl: string = '';
  @symfony({variable: '$mailchimp_lists', converter: '_default/{"data":[]}|field:"data"|take:["id", "name"]|json', type: Array}) private mailchimpLists: List[] = [];
  @symfony({variable: '$cc_url', type: String}) private ccUrl: string = '';
  @symfony({variable: '$constantcontact_list', converter: '_default:[]|take:["id", "name"]|json', type: Array}) private constantContactLists: List[] = [];
  @symfony({variable: '$icontact_lists', converter: '_default:[]|take:["listId", "name"]|rename_field:"listId":"id"|json', type: Array}) private icontactLists: List[] = [];
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_icontact_app_id"', type: String}) private icontactAppId?: string;
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_icontact_app_password"', type: String}) private icontactAppPassword?: string;

  private listToOptions(list: List[]): SelectOption[] {
    return list.map((item) => {
      return {key: item.id + '', label: item.name, selected: this.social.mailchimp_list_id === item.id};
    });
  }

  protected render() {
    return html`
<narrow-content>
    <email-integration-partner
            name="MailChimp"
            key="mailchimp"
            ?connected="${this.social.mailchimp_token !== ''}"
            signupUrl="https://login.mailchimp.com/signup?"
            connectUrl="${this.mailchimpUrl}"
            disconnectUrl="/admin/business/emailintegration/disconnect/mailchimp"
            .lists="${this.listToOptions(this.mailchimpLists)}">
    </email-integration-partner>

    <email-integration-partner
            name="Constant Contact"
            key="constantcontact"
            ?connected="${this.social.constantcontact_token !== ''}"
            signupUrl="http://www.constantcontact.com/"
            connectUrl="${this.ccUrl}"
            disconnectUrl="/admin/business/emailintegration/disconnect/constantcontact"
            .lists="${this.listToOptions(this.constantContactLists)}">
    </email-integration-partner>

    <email-integration-partner
            name="iContact"
            key="icontact"
            ?connected="${this.social.icontact_username !== ''}"
            signupUrl="https://www.icontact.com/getstarted/06"
            connectUrl=""
            disconnectUrl="/admin/business/emailintegration/disconnect/icontact">
        <content-block>
            <body-2>
                To connect your iContact account you need to do the following...
                <ol>
                    <li>Login into your iContact account</li>
                    <li>Authorize our app to connect to your iContact account. <a href="https://app.icontact.com/icp/core/externallogin" target="_blank">Click Here</a></li>
                    <li>Under "Allow another External Program to Access Your Account" enter '${this.icontactAppId}' into the Application ID field and '${this.icontactAppPassword}' into the Create a Password field. Then click save.</li>
                    <li>Enter your iContact username below and click "Save iContact Settings"</li>
                </ol>
            </body-2>
            <form-submission>
                <content-block textCenter>
                    <form-submission-textfield name="icontact_username" label="Enter your iContact username"></form-submission-textfield>
                    <br>
                    <form-submission-submit name="save_icontact_user" value="Save iContact Settings"></form-submission-submit>
                </content-block>
            </form-submission>
        </content-block>
    </email-integration-partner>
</narrow-content>

`;
  }
}
