export function businessUrl(url?: string): string {
  // Valid characters for a business slug
  const chars = 'a-zA-Z0-9_-';

  // The base business urls should start with either '/usa/:state/:city/:slug' or '/:slug'
  const regex = window.location.pathname.startsWith('/usa') ?
    new RegExp(`^/usa/[${chars}]+/[${chars}]+/[${chars}]+`) :
    new RegExp(`^/[${chars}]+`);

  const found = window.location.pathname.match(regex);

  if (!found) {
    throw new Error(`Unexpected pathname: '${window.location.pathname}'`);
  }

  const base = found[0];

  // If a url was specified, prepend the base onto the desired url. If not, then
  // just return the base url for the business.
  return url ? `${base}/${url.replace(new RegExp('^/'), '')}` : base; // Remove leading slash in url to allow specifying with or without it
}
