import {LitElement, html, css} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {FormSubmission} from '../form-submission/form-submission';
import '@material/mwc-fab';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '../../components/typography/heading-5';

@customElement('floating-action-button')
export class FloatingActionButton extends LitElement {
  @property({type: Boolean}) public dialogOpen: boolean = false;
  @property({type: String}) public href: string = '';
  @property({type: String}) public icon: string = '';
  @property({type: String}) public heading: string = '';
  @property({type: String}) public actionLabel: string = 'Save';
  @query('form-submission') private form!: FormSubmission;

  // give the fab some padding so that it's box-shadow is including when calculating its dimensions (useful when it is inside a flex-column
  // which has overflow:hidden).
  static styles = css`
.floating-action-button {
    display: inline-block;
    padding: 15px;
}
.floating-action-button {
    text-align: center;
}
`;

  private handleFabClick(): void {
    if (this.href !== '') {
      window.location.href = this.href;

      return;
    }

    this.dialogOpen = true;
  }

  private handleDialogClosed(event: CustomEvent): void {
    this.dialogOpen = false;

    if (event.detail?.action === 'save') {
      this.form.submit();
    }
  }

  protected render() {
    return html`
<mwc-fab class="floating-action-button__fab" icon="${this.icon}" @click="${this.handleFabClick}"></mwc-fab>

<mwc-dialog ?open="${this.dialogOpen}" @closed="${this.handleDialogClosed}">
    <heading-5 class="floating-action-button__heading">${this.heading}</heading-5>

    <form-submission>
        <slot></slot>
    </form-submission>
    
    <mwc-button slot="secondaryAction" dialogAction="cancel">Cancel</mwc-button>
    <mwc-button slot="primaryAction" dialogAction="save" raised>${this.actionLabel}</mwc-button>
</mwc-dialog>
`;
  }
}
