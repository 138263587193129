import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {SelectOption} from '../form-submission/fields/form-submission-select';
import '../../components/typography/body-2';
import '../../components/typography/heading-6';
import '../../components/button-link/button-link';
import '../../components/card-display/card-display';
import '../../components/open-dialog-button/open-dialog-button';
import '../../components/form-submission/form-submission';
import '../../components/form-submission/fields/form-submission-submit';

@customElement('email-integration-partner')
export class EmailIntegrationPartner extends LitElement {
  @property({type: String}) public name: string = '';
  @property({type: String}) public key: string = '';
  @property({type: Boolean}) public connected: boolean = false;
  @property({type: String}) public signupUrl: string = '';
  @property({type: String}) public connectUrl: string = '';
  @property({type: String}) public disconnectUrl: string = '';
  @property({type: Array}) public lists: SelectOption[] = [];

  protected render() {
    return html`
<card-display>
    <div slot="content">
        <heading-6 tag="h3">${this.name}</heading-6>
        
        ${this.connected ? html`
          <body-2>${this.lists.length > 0 ? html`To integrate ${this.name}, start by selecting "Select List" below.` : html`There are no "Lists" in your ${this.name} account. Please login to your ${this.name} account and add one.`}</body-2>
          ` : html`
          <body-2>If you would like to use ${this.name} and you do not have an account, you can create one <a href="${this.signupUrl}" target="_blank">here</a></body-2>
        `}
        
        <slot></slot>
    </div>
    
    ${this.connected ? html`<button-link slot="actions" href="${this.disconnectUrl}">Disconnect</button-link>` : ''}
    ${!this.connected && this.connectUrl !== '' ? html`<button-link slot="actions" href="${this.connectUrl}" raised>Connect</button-link>` : ''}
    
    ${this.lists.length ? html`
        <open-dialog-button slot="actions" raised label="Select List">
            <form-submission>
                <form-submission-select label="Select your ${this.name} list" name="${this.key}_list" .options="${this.lists}"></form-submission-select>
                <form-submission-submit name="save_${this.key}" value="Save"></form-submission-submit>
            </form-submission>
        </open-dialog-button>
    ` : ''}
</card-display>
`;
  }
}
