import {customElement} from 'lit/decorators.js';
import {BaseTypography} from './base-typography';

@customElement('body-2')
export class Body2 extends BaseTypography {
  protected mdcTypography(): string {
    return 'body2';
  }

  protected defaultTag(): string {
    return 'p';
  }
}
