import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import '@material/mwc-button';
import '@material/mwc-dialog';

@customElement('open-dialog-button')
export class OpenDialogButton extends LitElement {
  @property({type: String}) public label: string = '';
  @property({type: Boolean}) public raised: boolean = false;
  @property({type: Boolean}) public showDialog: boolean = false;
  @property({type: String}) public dialogHeading: string = '';
  @property({type: Boolean}) public largeDialog: boolean = false;
  @property({type: String}) public primaryLabel: string = '';
  @property({type: String}) public secondaryLabel: string = 'Cancel';

  static styles = css`
:host {
    display: inline-block;
    text-align: center;
}
mwc-dialog.open-dialog-button__dialog--large {
    --mdc-dialog-max-width: 80%;
}
`;

  private handleDialogClosed(event: CustomEvent): void {
    if (event.detail.action === 'primary') {
      this.dispatchEvent(new OpenDialogButtonPrimaryClicked());
    }
    this.showDialog = false;
  }

  protected render() {
    const classes = {
      'open-dialog-button__dialog': true,
      'open-dialog-button__dialog--large': this.largeDialog,
    };

    return html`
<div>
    <mwc-button ?raised="${this.raised}" label="${this.label}" @click="${() => this.showDialog = true}"></mwc-button>
    
    <mwc-dialog class=${classMap(classes)} heading="${this.dialogHeading}" ?open="${this.showDialog}" @closed="${this.handleDialogClosed}">
        <slot></slot>
        
        ${this.secondaryLabel ? html`
        <mwc-button slot="secondaryAction" dialogAction="cancel">${this.secondaryLabel}</mwc-button>
        ` : ''}
        
        ${this.primaryLabel ? html`
        <mwc-button raised slot="primaryAction" dialogAction="primary">${this.primaryLabel}</mwc-button>
        ` : ''}
    </mwc-dialog>
</div>
`;
  }
}

export class OpenDialogButtonPrimaryClicked extends Event {
  static type = 'open-dialog-button-primary-clicked';

  constructor() {
    super(OpenDialogButtonPrimaryClicked.type, {bubbles: true, composed: true});
  }
}
