import {html, LitElement, unsafeCSS} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {style} from './footer-links-item-css';
import themeStyles from '@material/theme/dist/mdc.theme.css';
import typographyStyles from '@material/typography/dist/mdc.typography.css';

@customElement('footer-links-item')
export class FooterLinksItem extends LitElement {
  @property({type: String}) href = '';

  static styles = [unsafeCSS(themeStyles), unsafeCSS(typographyStyles), style];

  protected render() {
    return html`
<li class="footer-links__item"><a class="footer-links__link mdc-typography--subtitle1 mdc-theme--text-secondary-on-light" href="${this.href}"><slot></slot></a></li>
`;
  }
}
