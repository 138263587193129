import {css, html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {FormSubmissionBase} from './form-submission-base';
import {TextField} from '@material/mwc-textfield';

@customElement('form-submission-textfield')
export class FormSubmissionTextfield extends FormSubmissionBase {
  @property({type: String}) label = '';
  @property({type: String}) type: 'text' | 'password' | 'date' = 'text';
  @property({type: String}) icon = '';
  @property({type: Boolean}) fullwidth: boolean = false;
  @query('mwc-textfield') private mwcTextfield!: TextField;

  static styles = css`
:host {
    display: contents;
}
:host([fullwidth]) mwc-textfield {
    width: 100%;
}
`;

  protected get shouldInclude(): boolean {
    return true;
  }

  protected render() {
    return html`<mwc-textfield @input="${() => this.value = this.mwcTextfield.value}" type="${this.type}" label="${this.label}" value="${this.value}" icon="${this.icon}"></mwc-textfield>`;
  }
}
