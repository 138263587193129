import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessDeal} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/floating-action-button/floating-action-button';

@customElement('backend-business-programsdeals-page')
export class ProgramsdealsPage extends LitElement {
  @symfony({variable: '$deals', converter: 'take:["id", "deal", "start_date", "end_date"]|json', type: Array}) private deals: BusinessDeal[] = [];

  protected render() {
    return html`
        
<narrow-content>
    <content-block textRight>
        <floating-action-button href="/admin/business/programsdeal" icon="add" actionLabel="Create Mobile Deal"></floating-action-button>
    </content-block>
    
    ${this.deals.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Deal</data-table-cell>
                <data-table-cell>Start</data-table-cell>
                <data-table-cell>End</data-table-cell>
                <data-table-cell>Message</data-table-cell>
                <data-table-cell></data-table-cell>
                <data-table-cell></data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.deals.map((deal) => html`
                <data-table-row>
                    <data-table-cell>${deal.deal}</data-table-cell>
                    <data-table-cell>${date(deal.start_date)}</data-table-cell>
                    <data-table-cell>${date(deal.end_date)}</data-table-cell>
                    <data-table-cell link="/admin/business/messaging/deal/${deal.id}">Send Message</data-table-cell>
                    <data-table-cell link="/admin/business/programsdeal/id/${deal.id}">Edit</data-table-cell>
                    <data-table-cell link="/admin/business/programsdealdelete/id/${deal.id}" confirm="Are you sure you want to delete this mobile deal?">Delete</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
<content-block textCenter>
    <body-1>You have not setup any mobile deals.</body-1>
</content-block>
    `}
    
</narrow-content>
        
`;
  }
}
