import '@material/mwc-button';
import {css, html, LitElement} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';

@customElement('button-link')
export class ButtonLink extends LitElement {
  @property({type: String}) public href: string = '';
  @property({type: String}) public confirm: string = '';
  @property({type: String}) public icon: string = '';
  @property({type: Boolean}) public disabled: boolean = false;
  @property({type: Boolean}) public outlined: boolean = false;
  @property({type: Boolean}) public raised: boolean = false;
  @property({type: Boolean}) public fullWidth: boolean = false;
  @state() private label: string = '';

  private observer: MutationObserver;

  static styles = css`
:host {
    display: contents;
}
:host([fullWidth]) mwc-button {
    width: 100%;
}
`;

  constructor() {
    super();

    this.observer = new MutationObserver(this.updateLabel.bind(this));

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.updateLabel();

    this.observer.observe(this, {childList: true});

    this.shadowRoot?.addEventListener('click', this.handleButtonClick);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.observer.disconnect();

    this.shadowRoot?.removeEventListener('click', this.handleButtonClick);
  }

  private updateLabel(): void {
    this.label = this.textContent || '';
  }

  private handleButtonClick() {
    let confirmed = this.confirm === '';

    if (!confirmed && window.confirm(this.confirm)) {
      confirmed = true;
    }

    if (confirmed) {
      window.location.href = this.href;
    }
  }

  protected render() {
    return html`
<mwc-button icon="${this.icon}" ?disabled="${this.disabled}" ?raised="${this.raised}" ?outlined="${this.outlined}" label="${this.label}"></mwc-button>
`;
  }
}
