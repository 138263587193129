import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import '@material/mwc-formfield';
import '@material/mwc-radio';
import {Radio} from '@material/mwc-radio';
import {FormSubmissionBase} from './form-submission-base';

@customElement('form-submission-radio')
export class FormSubmissionRadio extends FormSubmissionBase {
  @property({type: String}) label = '';
  @property({type: Boolean, reflect: true}) checked = false;
  @query('mwc-radio') private mwcRadio!: Radio;

  protected get shouldInclude(): boolean {
    return this.checked;
  }

  protected watchedAttributes(): string[] {
    return ['checked'];
  }

  private handleChange(): void {
    this.checked = this.mwcRadio.checked;
  }

  protected render() {
    return html`<mwc-formfield label="${this.label}"><mwc-radio ?global="${true}" name="${this.name}" @change="${this.handleChange}" ?checked="${this.checked}"></mwc-radio></mwc-formfield>`;
  }
}
