import {css} from 'lit';

export const style = css`
.alert-message {
    display: flex;
    align-items: center;
    margin: 15px auto;
    color: #fff;
    padding: 20px;
}
.alert-message--hidden {
    display: none;
}
.alert-message--error {
    background-color: #b00020;
}
.alert-message--success {
    background-color: #296d6f;
}
.alert-message__messages {
    margin-left: 15px;
    list-style-type: none;
}
`;
