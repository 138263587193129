import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import '@material/mwc-icon';
import '../../../components/layout/content-block';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/form-submission/fields/form-submission-checkbox';
import '../../../components/form-submission/fields/form-submission-submit';
import {symfony} from '../../../symfony/decorators';
import {Pager} from '../../../symfony/built-ins';
import {ContactUsConsumer, Partner} from '../../../symfony/models';

@customElement('backend-partner-contactussubmissions-page')
export class ContactussubmissionsPage extends LitElement {
  @symfony({variable: '$submissions', converter: 'take:["id", "name", "phone", "email", "Partner.domain", "business_name", "business_address", "business_phone", "created_at"]|json', type: Array}) private submissions: ContactUsConsumer[] = [];
  @symfony({variable: '$showDuplicates', converter: '_default:false', type: Boolean}) private showDuplicates: boolean = false;
  @symfony({variable: '$search', type: String}) private search: string = '';
  @symfony({variable: '$partner', converter: 'take:["id"]|json', type: Object}) private partner!: Partner;
  @symfony({variable: '$pager', converter: 'pager|json', type: Object}) private pager!: Pager;

  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
<content-block>
    <form-submission>
        <flex-column-container gap="10">
            <flex-column flex="initial">
                <form-submission-checkbox name="show_duplicates" label="Show Duplicates" value="" ?checked="${false}"></form-submission-checkbox>
            </flex-column>
            <flex-column flex="1">
                <form-submission-textfield fullWidth name="search" label="Search Businesses" value="${this.search}"></form-submission-textfield>
            </flex-column>
            <flex-column flex="initial">
                <form-submission-submit value="Search"></form-submission-submit>
            </flex-column>
        </flex-column-container>
    </form-submission>
</content-block>

<data-table fullWidth .pager="${this.pager}">
    <data-table-head>
        <data-table-row>
            <data-table-cell>Partner</data-table-cell>
            <data-table-cell>Name & Email</data-table-cell>
            <data-table-cell>Phone</data-table-cell>
            <data-table-cell>Business Name & Address</data-table-cell>
            <data-table-cell>Business Phone</data-table-cell>
            <data-table-cell>Date Received</data-table-cell>
            <data-table-cell>Actions</data-table-cell>
        </data-table-row>
    </data-table-head>
    <data-table-body>
        ${this.submissions.map((submission: ContactUsConsumer) => html`
            <data-table-row>
                <data-table-cell>${submission.Partner.domain}</data-table-cell>
                <data-table-cell>
                  ${submission.name}
                  <br/>- ${submission.email}
                </data-table-cell>
                <data-table-cell>${submission.phone}</data-table-cell>
                <data-table-cell>
                  ${submission.business_name}
                  <br/>- ${submission.business_address}
                </data-table-cell>
                <data-table-cell>${submission.business_phone}</data-table-cell>
                <data-table-cell>${submission.created_at}</data-table-cell>
                <data-table-cell confirm="Are you sure you want to delete this submission?" link="/admin/partner/deletecontactussubmission/id/${submission.id}"><mwc-icon>delete</mwc-icon></data-table-cell>
            </data-table-row>
        `)}
    </data-table-body>
</data-table>
    `;
  }
}
