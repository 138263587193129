import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/prominent-notice/prominent-notice';

@customElement('backend-business-dataimport-page')
export class DataimportPage extends LitElement {
  protected render() {
    return html`
<narrow-content>
    <prominent-notice
            image="/assets/images/undraw_file_synchronization_8dh6.svg"
            heading="Import Customers"
            subtitle="You can import customers from another loyalty program by uploading a CSV file. Below is the CSV format that you must use."
            buttonLabel="Import From File">

        <editable-section
                slot="editable-section"
                heading="Import Customers"
                description="Select a CSV file containing your customer information."
                submitName="submit_import"
                multipart
                .items=${<ItemDisplay[]>[{'leadingIcon': 'label', 'type': 'file', 'label': 'Upload Customer File', 'name': 'file', 'value': ''}]}>
        </editable-section>
    </prominent-notice>
</narrow-content>
`;
  }
}
