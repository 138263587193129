import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('image-grid')
export class ImageGrid extends LitElement {
  static styles = css`
.image-grid {
    display: flex;
    flex-wrap: wrap;
}
`

  protected render() {
    return html`
<div class="image-grid">
    <slot></slot>
</div>
`;
  }
}
