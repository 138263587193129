import {html, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {CustomersFilterCountUpdated} from '../../../components/customers-filter/events/customers-filter-count-updated';
import {symfony} from '../../../symfony/decorators';
import {Business, BusinessCustomField} from '../../../symfony/models';
import {Filters} from '../../../components/customers-filter/customers-filter';
import '../../../components/typography/body-1';
import '../../../components/typography/heading-6';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/open-dialog-button/open-dialog-button';
import '../../../components/customers-filter/customers-filter';
import '../../../components/customers-filter/customers-filter-submission';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-select';
import '../../../components/form-submission/fields/form-submission-textarea';
import '../../../components/form-submission/fields/form-submission-submit';

@customElement('backend-business-inbox-page')
export class InboxPage extends LitElement {
  @symfony({variable: '$business', converter: 'take:["inbox_credits"]|json', type: Object}) private business!: Business;
  @symfony({variable: '$sf_request', converter: '_call:"extractParameters":["gender", "age_start", "age_end", "join_start", "join_end", "zipcode"]|json', type: Object}) private filters!: Filters;
  @symfony({variable: '$zip_data', converter: 'json', type: Array}) private zipCodes: string[] = [];
  @symfony({variable: '$custom_fields', converter: 'take:["id", "name", "type_id", "BusinessCustomFieldValue.id", "BusinessCustomFieldValue.value"]|json', type: Array}) private customFields: BusinessCustomField[] = [];
  @state() private count: number = 0;

  protected render() {
    return html`
<narrow-content>
    <prominent-notice
        image="/assets/images/undraw_message_sent_1030.svg"
        heading="Customer Inbox"
        subtitle="Send custom messages directly to your customers.">
        <body-1>${this.count} Customers Selected | ${this.business.inbox_credits} Credits Available</body-1>
    </prominent-notice>

    <content-block centered>
        <open-dialog-button label="Buy Message Credits">
            <heading-6>Buy Message Credits</heading-6>
            <br>
            <form-submission action="/admin/business/buyinboxmessages">
                <form-submission-select label="Plans" name="message_plans" value="" .options=${[
                {'key': '250', 'label': '250 messages 10&cent;/per'},
                {'key': '500', 'label': '500 messages 8&cent;/per'},
                {'key': '1000', 'label': '1000 messages 7&cent;/per'},
                {'key': '2500', 'label': '2500 messages 5&cent;/per'},
                {'key': '5000', 'label': '5000 messages 4&cent;/per'},
                ]}></form-submission-select>

                <content-block centered>
                    <form-submission-submit name="submit_purchase" value="Purchase"></form-submission-submit>
                </content-block>
            </form-submission>
        </open-dialog-button>
    </content-block>

    <content-block centered>
        <customers-filter-submission actionLabel="Send Messages">
            <form-submission-textarea slot="extra-form-fields" label="Message" name="message" value=""></form-submission-textarea>

            <customers-filter slot="customers-filter" @customers-filter-count-updated="${(event: CustomersFilterCountUpdated) => this.count = event.count}"
                .filters="${this.filters}"
                .zipCodes="${this.zipCodes}"
                .customFields="${this.customFields}">
            </customers-filter>
        </customers-filter-submission>

    </content-block>
</narrow-content>
`;
  }
}
