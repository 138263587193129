import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessQrForm} from '../../../symfony/forms';
import {BusinessQr} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/typography/body-1';
import '../../../components/qr-code/qr-code';
import '../../../components/layout/narrow-content';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/button-link/button-link';
import '../../../components/prominent-notice/prominent-notice';

@customElement('backend-business-qr-page')
export class QrPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "name", "link"]|json', type: Object}) private form?: BusinessQrForm;
  @symfony({variable: '$codes', converter: 'take:["id", "scans", "name", "link"]|json', type: Array}) private codes: BusinessQr[] = [];

  protected render() {
    return html`
<narrow-content centered>
    <prominent-notice
            image="/assets/images/undraw_hologram_fjwp.svg"
            heading="QR Codes"
            subtitle="Create linkable QR codes."
            buttonLabel="Create QR Code">

        <editable-section
                slot="editable-section"
                heading="QR Codes"
                description="Create QR Code"
                submitName="submit_save"
                .items=${<ItemDisplay[]>[
            {'leadingIcon': 'qr_code', 'type': 'combination', 'label': 'Create A QR Code', 'value': '', 'inputs': [
                {'leadingIcon': 'label', 'type': 'text', 'label': 'Name', 'name': 'business_qr[name]', 'value': this.form?.name.value},
                {'leadingIcon': 'link', 'type': 'text', 'label': 'Link', 'name': 'business_qr[link]', 'value': this.form?.link.value},
                {'type': 'hidden', 'label': '', 'name': 'business_qr[id]', 'value': ''},
            ]},
        ]}>
        </editable-section>
    </prominent-notice>



    <flex-column-container gap="10" columnForMobile>
        ${this.codes.map((code) => html`
            <flex-column>
                <editable-section
                    heading="Number of scans: ${code.scans}"
                    description="Edit QR Code"
                    submitName="submit_save_${code.id}"
                    action="/admin/business/qr/editid/${code.id}"
                    .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Name', 'name': 'business_qr[name]', 'value': code.name},
                        {'leadingIcon': 'link', 'type': 'text', 'label': 'Link', 'name': 'business_qr[link]', 'value': code.link},
                    ]}>
                    <qr-code link="${code.link}" track="${code.id}"></qr-code>
                    <button-link confirm="This will delete all tracking of this QR Code. Are you sure?" href="/admin/business/qrdelete/id/${code.id}">Delete</button-link>
                </editable-section>
            </flex-column>
        `)}
    </flex-column-container>

    ${this.codes.length > 0 ? html`
    <body-1>*To download, right click the QR Code and click "Save Image/Picture As"</body-1>
    ` : ''}
</narrow-content>
`;
  }
}
