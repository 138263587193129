import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {
  BusinessConsumerDeal,
  BusinessConsumerReward,
  BusinessDeal,
  BusinessLocation,
  BusinessReward,
  CustomerMobilePurchase,
  MobilePayment,
} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import {SelectOption} from '../../../components/form-submission/fields/form-submission-select';
import '../../../components/typography/body-1';
import '../../../components/typography/body-2';
import '../../../components/typography/heading-5';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/tabbable-content/tabbable-content';
import '../../../components/tabbable-content/tabbable-content-pane';
import '../../../components/layout/content-block';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/form-submission/fields/form-submission-submit';

interface StoreData {
  [key: string]: StoreDataValue;
}

interface StoreDataValue {
  name: string;
  programs: Program;
}

interface Program {
  [key: string]: ProgramValue
}

interface ProgramValue {
  name: string;
  count: number;
}

interface StoreDataProgram {
  storeName: string;
  programName: string;
  programCount: number;
}

@customElement('backend-business-reports-page')
export class ReportsPage extends LitElement {
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"report_customer_start"', type: String}) private reportCustomerStart: string = '';
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"report_customer_end"', type: String}) private reportCustomerEnd: string = '';
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"report_customer_program"', type: String}) private reportCustomerProgram: string = '';
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"report_customer_store"', type: String}) private reportCustomerStore: string = '';
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"report_store_program"', type: String}) private reportStoreProgram: string = '';
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"report_store_store"', type: String}) private reportStoreStore: string = '';
  @symfony({variable: '$customers', converter: 'take:["id", "BusinessConsumer.Consumer.first_name", "BusinessConsumer.Consumer.last_name", "BusinessReward.name", "LogCheckins.id", "BusinessLocation.name", "reward_name", "redeemed"]|json', type: Array}) private customers: BusinessConsumerReward[] = [];
  @symfony({variable: '$programs', converter: 'take:["id", "name", "type_id", "name_buy", "name_get"]|json', type: Array}) private programs: BusinessReward[] = [];
  @symfony({variable: '$deals', converter: 'take:["id", "deal"]|json', type: Array}) private deals: BusinessDeal[] = [];
  @symfony({variable: '$stores', converter: 'take:["id", "name"]|json', type: Array}) private stores: BusinessLocation[] = [];
  @symfony({variable: '$customers_deals', converter: 'take:["id", "BusinessConsumer.Consumer.first_name", "BusinessConsumer.Consumer.last_name", "BusinessDeal.deal", "BusinessLocation.name", "redeemed"]|json', type: Array}) private customerDeals: BusinessConsumerDeal[] = [];
  @symfony({variable: '$store_data', converter: 'json', type: Object}) private storeData!: StoreData;
  @symfony({variable: '$customer_mobile_purchases', converter: 'take:["BusinessConsumer.Consumer.first_name", "BusinessConsumer.Consumer.last_name", "MobilePurchase.deal", "MobilePurchase.price"]|json', type: Array}) private customerMobilePurchases: CustomerMobilePurchase[] = [];
  @symfony({variable: '$mobile_payments', converter: 'take:["BusinessConsumer.Consumer.first_name", "BusinessConsumer.Consumer.last_name", "description", "price"]|json', type: Array}) private mobilePayments: MobilePayment[] = [];
  @symfony({variable: '$cashBackNowGenerated', type: Number}) private cashBackNowGenerated: number = 0;
  @symfony({variable: '$cashBackNowPutOut', type: Number}) private cashBackNowPutOut: number = 0;

  private customersWithRewardType(typeId: number): BusinessConsumerReward[] {
    return this.customers.filter((customer) => customer.BusinessReward.type_id == typeId);
  }

  private customerProgramOptions(selectedId: string): SelectOption[] {
    const programOptions: SelectOption[] = this.programs.map((program) => {
      return {
        key: program.id + '',
        label: program.name,
        selected: selectedId === program.id + '',
      };
    });

    const dealOptions: SelectOption[] = this.deals.map((deal) => {
      return {
        key: deal.id + '',
        label: deal.deal,
        selected: selectedId === deal.id + '',
      };
    });

    return programOptions.concat(dealOptions);
  }

  private customerStoreOptions(selectedId: string): SelectOption[] {
    return this.stores.map((store) => {
      return {
        key: store.id + '',
        label: store.name,
        selected: selectedId === store.id + '',
      };
    });
  }

  private storeDataPrograms(): StoreDataProgram[] {
    const programs: StoreDataProgram[] = [];

    Object.keys(this.storeData).forEach((key) => {
      const storeName = this.storeData[key].name;

      Object.keys(this.storeData[key].programs).forEach((programKey) => {
        const programName = this.storeData[key].programs[programKey].name;
        const programCount = this.storeData[key].programs[programKey].count;

        programs.push({storeName, programName, programCount});
      });
    });

    return programs;
  }

  protected render() {
    return html`
<tabbable-content activeHeading="Customer Statistics" rounded>
    <tabbable-content-pane heading="Customer Statistics">
        <content-block centered>
            <form-submission>
                <flex-column-container gap="10" columnForMobile>
                    <flex-column flex="initial">
                        <form-submission-textfield type="date" name="report_customer_start" label="Start" value="${this.reportCustomerStart}"></form-submission-textfield>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-textfield type="date" name="report_customer_end" label="End" value="${this.reportCustomerEnd}"></form-submission-textfield>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-select name="report_customer_program" label="Program" value="${this.reportCustomerProgram}" .options="${this.customerProgramOptions(this.reportCustomerProgram)}"></form-submission-select>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-select name="report_customer_store" label="Store" value="${this.reportCustomerStore}" .options="${this.customerStoreOptions(this.reportCustomerStore)}"></form-submission-select>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-submit name="submit_run_customers" value="Run"></form-submission-submit>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-submit name="submit_run_customers_clear" value="Clear"></form-submission-submit>
                    </flex-column>
                </flex-column-container>
            </form-submission>
        </content-block>

        <content-block centered>
            ${this.customers.length > 0 || this.customerDeals.length > 0 ? html`
                <data-table fullWidth>
                    <data-table-head>
                        <data-table-row>
                            <data-table-cell>Customer</data-table-cell>
                            <data-table-cell>Email</data-table-cell>
                            <data-table-cell>Mobile</data-table-cell>
                            <data-table-cell>Program</data-table-cell>
                            <data-table-cell>Punches</data-table-cell>
                            <data-table-cell>Redeemed</data-table-cell>
                            <data-table-cell>Store</data-table-cell>
                        </data-table-row>
                    </data-table-head>
                    <data-table-body>
                        ${this.customers.map((customer) => html`
                        <data-table-row>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.first_name} ${customer.BusinessConsumer.Consumer.last_name}</data-table-cell>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.email}</data-table-cell>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.phone}</data-table-cell>
                            <data-table-cell>${customer.reward_name != '' ? customer.reward_name : customer.BusinessReward.name}</data-table-cell>
                            <data-table-cell>${customer.LogCheckins.length}</data-table-cell>
                            <data-table-cell>${customer.redeemed ? date(customer.redeemed) : ''}</data-table-cell>
                            <data-table-cell>${customer.BusinessLocation ? customer.BusinessLocation.name : ''}</data-table-cell>
                        </data-table-row>
                        `)}
                        ${this.customerDeals.map((customer) => html`
                        <data-table-row>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.first_name} ${customer.BusinessConsumer.Consumer.last_name}</data-table-cell>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.email}</data-table-cell>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.phone}</data-table-cell>
                            <data-table-cell>${customer.BusinessDeal.deal}</data-table-cell>
                            <data-table-cell>0</data-table-cell>
                            <data-table-cell>${customer.redeemed ? date(customer.redeemed) : ''}</data-table-cell>
                            <data-table-cell>${customer.BusinessLocation ? customer.BusinessLocation.name : ''}</data-table-cell>
                        </data-table-row>
                        `)}
                    </data-table-body>
                </data-table>
            ` : html`
                <body-1>No data has been returned.</body-1>
            `}
        </content-block>
    </tabbable-content-pane>
    <tabbable-content-pane heading="Store Statistics">
        <content-block centered>
            <form-submission>
                <flex-column-container gap="10" columnForMobile>
                    <flex-column flex="initial">
                        <form-submission-textfield type="date" name="report_store_start" label="Start" value="{{ sf_user.getAttribute('report_store_start') }}"></form-submission-textfield>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-textfield type="date" name="report_store_end" label="End" value="{{ sf_user.getAttribute('report_store_end') }}"></form-submission-textfield>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-select name="report_store_program" label="Program" value="${this.reportStoreProgram}" .options="${this.customerProgramOptions(this.reportStoreProgram)}"></form-submission-select>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-select name="report_store_store" label="Store" value="${this.reportStoreStore}" .options="${this.customerStoreOptions(this.reportStoreStore)}"></form-submission-select>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-submit name="submit_run_stores" value="Run"></form-submission-submit>
                    </flex-column>
                    <flex-column flex="initial">
                        <form-submission-submit name="submit_run_stores_clear" value="Clear"></form-submission-submit>
                    </flex-column>
                </flex-column-container>
            </form-submission>
        </content-block>

        <content-block centered>
            ${this.storeDataPrograms().length > 0 ? html`
                <data-table fullWidth>
                    <data-table-head>
                        <data-table-row>
                            <data-table-cell>Store</data-table-cell>
                            <data-table-cell>Program</data-table-cell>
                            <data-table-cell>Redeemed</data-table-cell>
                        </data-table-row>
                    </data-table-head>
                    <data-table-body>
                        ${this.storeDataPrograms().map((sdp) => html`
                            <data-table-row>
                                <data-table-cell>${sdp.storeName}</data-table-cell>
                                <data-table-cell>${sdp.programName}</data-table-cell>
                                <data-table-cell>${sdp.programCount}</data-table-cell>
                            </data-table-row>
                        `)}
                    </data-table-body>
                </data-table>
            ` : html`
                <body-1>No data has been returned.</body-1>
            `}
        </content-block>
    </tabbable-content-pane>
    <tabbable-content-pane heading="Mobile Purchases">
        <content-block centered>
            ${this.customerMobilePurchases.length > 0 ? html`
                <data-table fullWidth>
                    <data-table-head>
                        <data-table-row>
                            <data-table-cell>Customer</data-table-cell>
                            <data-table-cell>Product</data-table-cell>
                            <data-table-cell>Price</data-table-cell>
                        </data-table-row>
                    </data-table-head>
                    <data-table-body>
                        ${this.customerMobilePurchases.map((cmp) => html`
                        <data-table-row>
                            <data-table-cell>${cmp.BusinessConsumer.Consumer.first_name} ${cmp.BusinessConsumer.Consumer.last_name}</data-table-cell>
                            <data-table-cell>${cmp.MobilePurchase.deal}</data-table-cell>
                            <data-table-cell>${cmp.MobilePurchase.price}</data-table-cell>
                        </data-table-row>
                        `)}
                    </data-table-body>
                </data-table>
            ` : html`
                <body-1>No data has been returned.</body-1>
            `}
        </content-block>
    </tabbable-content-pane>
    <tabbable-content-pane heading="Mobile Payments">
        <content-block centered>
            ${this.mobilePayments.length > 0 ? html`
                <data-table fullWidth>
                    <data-table-head>
                        <data-table-row>
                            <data-table-cell>Customer</data-table-cell>
                            <data-table-cell>Description</data-table-cell>
                            <data-table-cell>Price</data-table-cell>
                        </data-table-row>
                    </data-table-head>
                    <data-table-body>
                        ${this.mobilePayments.map((payment) => html`
                        <data-table-row>
                            <data-table-cell>${payment.BusinessConsumer.Consumer.first_name} ${payment.BusinessConsumer.Consumer.last_name}</data-table-cell>
                            <data-table-cell>${payment.description}</data-table-cell>
                            <data-table-cell>${payment.price}</data-table-cell>
                        </data-table-row>
                        `)}
                    </data-table-body>
                </data-table>
            ` : html`
                <body-1>No data has been returned.</body-1>
            `}
        </content-block>
    </tabbable-content-pane>
    <tabbable-content-pane heading="Cash Back Now">
        <content-block centered>
            <data-table fullWidth>
                <data-table-head>
                    <data-table-row>
                        <data-table-cell>Customer</data-table-cell>
                        <data-table-cell>Email</data-table-cell>
                        <data-table-cell>Mobile</data-table-cell>
                        <data-table-cell>Spend Amount</data-table-cell>
                        <data-table-cell>Get Amount</data-table-cell>
                        <data-table-cell>Redeemed</data-table-cell>
                        <data-table-cell>Store</data-table-cell>
                    </data-table-row>
                </data-table-head>
                <data-table-body>
                    ${this.customersWithRewardType(8).map((customer) => html`
                        <data-table-row>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.first_name} ${customer.BusinessConsumer.Consumer.last_name}</data-table-cell>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.email}</data-table-cell>
                            <data-table-cell>${customer.BusinessConsumer.Consumer.phone}</data-table-cell>
                            <data-table-cell>Spend $${customer.BusinessReward.name_buy}</data-table-cell>
                            <data-table-cell>Get $${customer.BusinessReward.name_get}</data-table-cell>
                            <data-table-cell>${customer.redeemed ? date(customer.redeemed) : 'Not redeemed yet'}</data-table-cell>
                            <data-table-cell>${customer.BusinessLocation ? customer.BusinessLocation.name : ''}</data-table-cell>
                        </data-table-row>
                    `)}
                </data-table-body>
            </data-table>

        </content-block>
        <content-block textCenter>
            <body-2>Total Money Generated: $${this.cashBackNowGenerated}</body-2>
            <body-2>Total Money Put Out: $${this.cashBackNowPutOut}</body-2>
            <heading-5>Net Gain: $${this.cashBackNowGenerated - this.cashBackNowPutOut}</heading-5>
        </content-block>
    </tabbable-content-pane>
</tabbable-content>
`;
  }
}
