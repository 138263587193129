import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('sortable-list-item')
export class SortableListItem extends LitElement {
  @property({type: String}) public sortId?: string;
  @property({type: Boolean}) public invisible: boolean = false;

  static styles = css`
:host {
    display: contents;
}
:host([invisible]) ::slotted(*) {
    opacity: 0;
}
::slotted(*):before {
    font-family: 'Material Icons';
    content: 'drag_indicator';
    cursor: move;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
`;

  connectedCallback() {
    super.connectedCallback();

    if (this.sortId === undefined) {
      throw new Error('You must give every sortable-list-item a sortId attribute.');
    }
  }

  firstUpdated() {
    // we need to make our content draggable
    const slot = this.shadowRoot!.querySelector('slot') as HTMLSlotElement;

    const item = slot.assignedElements()[0] as HTMLElement;

    item.draggable = true;
  }

  protected render() {
    return html`
<slot></slot>
`;
  }
}
