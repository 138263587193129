import {css, html, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {CustomersFilter} from './customers-filter';
import {FormSubmissionHidden} from '../form-submission/fields/form-submission-hidden';
import '@material/mwc-button';
import '../../components/open-dialog-button/open-dialog-button';
import '../../components/layout/content-block';

@customElement('customers-filter-submission')
export class CustomersFilterSubmission extends LitElement {
  @property({type: String}) public actionLabel: string = '';

  @query('slot[name="extra-form-fields"]') private extraFormFieldsSlot!: HTMLSlotElement;
  @query('slot[name="customers-filter"]') private customersFilterSlot!: HTMLSlotElement;

  static styles = css`
slot[name="extra-form-fields"] {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
`;

  private async handleActionClicked() {
    const customersFilter = this.customersFilterSlot.assignedElements()[0] as CustomersFilter;

    const formSubmission = customersFilter.getFormSubmission();

    this.extraFormFieldsSlot.assignedElements().forEach((field: any) => {
      // don't include fields that are "off"
      if (!field.shouldInclude) {
        return;
      }

      const hiddenField = document.createElement('form-submission-hidden') as FormSubmissionHidden;
      hiddenField.setAttribute('name', field.getAttribute('name'));
      hiddenField.setAttribute('value', field.getAttribute('value'));
      formSubmission.appendChild(hiddenField);
    });

    setTimeout(() => {
      formSubmission.submit();
    }, 0);
  }

  protected render() {
    return html`
<content-block centered>
    <slot name="extra-form-fields"></slot>
</content-block>

<content-block centered>
    <mwc-button raised label="${this.actionLabel}" @click="${this.handleActionClicked}"></mwc-button>
    <open-dialog-button label="Adjust Filters" primaryLabel="Apply Filters" secondaryLabel="">
        <slot name="customers-filter"></slot>
    </open-dialog-button>
</content-block>

`;
  }
}
