import {LitElement, html, css, TemplateResult} from 'lit';
import {customElement, property} from 'lit/decorators.js';

const centeredStyles = html`<style>:host { align-items: center }</style>`;

@customElement('narrow-content')
export class NarrowContent extends LitElement {
  @property({type: Boolean}) centered = false;

  private readonly perInstanceStyle: TemplateResult;

  static styles = css`
:host {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin: 0 auto;
}
::slotted(*) {
    width: 100%;
}
`;

  constructor() {
    super();

    // see example from: https://lit-element.polymer-project.org/guide/styles#expressions-and-style-elements
    this.perInstanceStyle = this.hasAttribute('centered') ? centeredStyles : html``;
  }

  protected render() {
    return html`
${this.perInstanceStyle}
<slot></slot>
`;
  }
}
