import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('virtual-card')
export class VirtualCard extends LitElement {
  static styles = css`
:host {
    display: block;
    height: 230px;
    width: 370px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 #000;
    border-radius: 20px;
    position: relative;
}
`;

  protected render() {
    return html`
<slot></slot>
`;
  }
}
