import {customElement} from 'lit/decorators.js';
import {BaseTypography} from './base-typography';

@customElement('subtitle-1')
export class Subtitle1 extends BaseTypography {
  protected mdcTypography(): string {
    return 'subtitle1';
  }

  protected defaultTag(): string {
    return 'p';
  }
}
