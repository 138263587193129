import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('tabbable-content-pane')
export class TabbableContentPane extends LitElement {
  @property({type: String}) public heading: string = '';

  protected render() {
    return html`<slot></slot>`;
  }
}
