import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {NavigationItem} from '../../navigation';
import '../../components/bottom-app-bar/bottom-app-bar-nav-item';

@customElement('bottom-app-bar')
export class BottomAppBar extends LitElement {
  @property({type: Array}) public navigation: NavigationItem[] = [];
  @property({type: Object}) public activeNavigationItem?: NavigationItem;

  static styles = css`
:host {
    --bottom-app-bar-height: 64px;
    display: block;
}
#spacer {
    height: var(--bottom-app-bar-height);
}
#items {
    display: flex;
    background-color: var(--primary-color);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: var(--bottom-app-bar-height);
}
`;

  protected render() {
    // since we want the bottom app bar to be position: fixed, we'll use a spacer div
    // that is not fixed to take up the same amount of height as the actual bar so that
    // the bottom of the actual page is not covered up by the bar

    return html`
<div id="spacer"></div>
<div id="items">
    ${this.navigation.map((item) => html`
        <bottom-app-bar-nav-item href="${item.href}" label="${item.label}" icon="${item.graphic}" ?active="${this.activeNavigationItem?.href === item.href}"></bottom-app-bar-nav-item>
    `)}
</div>
`;
  }
}
