import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '../../components/typography/subtitle-1';
import '../../components/button-link/button-link';
import '../../components/editable-section/editable-section-item-display';
import '../../components/layout/flex-columns/flex-column';
import '../../components/layout/flex-columns/flex-column-container';

@customElement('account-location')
export class AccountLocation extends LitElement {
  @property({type: String}) public name: string = '';
  @property({type: String}) public phone: string = '';
  @property({type: String}) public address: string = '';
  @property({type: String}) public editHref: string = '';
  @property({type: String}) public deleteHref: string = '';
  @property({type: Boolean}) public showDelete: boolean = false;

  static styles = css`
.account-location {
    margin: 15px 0;
}
.account-location__heading {
    text-align: center;
}
`;

  protected render() {
    return html`
<div class="account-location">
    <subtitle-1 class="account-location__heading">${this.name} <small>${this.phone}</small></subtitle-1>
    
    <flex-column-container>
        <flex-column>
            <editable-section-item-display leadingIcon="place" type="combination" value="${this.address}"></editable-section-item-display>
        </flex-column>
        <flex-column flex="initial">
            <button-link href="${this.editHref}">Edit</button-link>
            ${this.showDelete ? html`
            <button-link confirm="Are you sure you want to delete ${this.name}?" href="${this.deleteHref}">Delete</button-link>
            ` : ''}
        </flex-column>
    </flex-column-container>
</div>
`;
  }
}
