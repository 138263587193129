import {css} from 'lit';

export const style = css`
.footer-links__item {
    margin-left: 10px;
}
.footer-links__link:hover {
    text-decoration: underline;
}
`;
