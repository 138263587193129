import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {style} from './footer-links-css';

@customElement('footer-links')
export class FooterLinks extends LitElement {
  static styles = style;

  protected render() {
    return html`
<ul class="footer-links">
    <slot></slot>
</ul>
`;
  }
}
