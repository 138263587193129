import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Business} from '../../../symfony/models';
import '../../../components/layout/content-block';
import '../../../components/code-block/code-block';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/open-dialog-button/open-dialog-button';

@customElement('backend-business-mobileredirect-page')
export class MobileredirectPage extends LitElement {
  @symfony({variable: '$business', converter: 'take:["slug"]|json', type: Object}) private business!: Business;

  protected render() {
    return html`
<prominent-notice
        image="/assets/images/undraw_mobile_prototyping_grmd.svg"
        heading="Mobile Redirect"
        subtitle="Mobile redirection allows you to automatically redirect visitors from your existing website to your loyalty app if they are viewing from a mobile device. To implement, copy and paste the code below into the home page (index.html, home.html, default.html, index.php, etc) of your existing website."
        maxWidth="800">
</prominent-notice>

<content-block textCenter>
    <open-dialog-button raised label="Show Redirect Code" largeDialog>
        <code-block>
&lt;script type="text/javascript"&gt;
var url='${window.location.origin}/${this.business.slug}';var host_name=document.location.hostname;var request_uri=document.location.pathname;var no_mobile=location.search;var cookie=document.cookie;
function detect(){var ua=navigator.userAgent.toLowerCase();var devices=['vnd.wap.xhtml+xml','sony','symbian','nokia','samsung','mobile','windows ce','epoc','opera mini','nitro','j2me','midp-','cldc-','netfront','mot','up.browser','up.link','audiovox','blackberry','ericsson','panasonic','philips','sanyo','sharp','sie-','portalmmm','blazer','avantgo','danger','palm','series60','palmsource','pocketpc','smartphone','rover','ipaq','au-mic','alcatel','ericy','vodafone','wap1','wap2','teleca','playstation','lge','lg-','iphone','android','htc','dream','webos','bolt','nintendo'];for(var i in devices){if(ua.indexOf(devices[i])!=-1){return true}}}
if(no_mobile!='?nomobile=1'&&cookie.indexOf('no_mobile')==-1){is_mobile=detect();if(is_mobile){window.location=url}}else{if(cookie.indexOf('no_mobile')!=-1){}else{cookie_expires=new Date();cookie_expires.setTime(cookie_expires.getTime()+60*60*24);document.cookie="no_mobile=1; expires="+cookie_expires.toGMTString()+"; path=/;"}}
&lt;/script&gt;
        </code-block>
    </open-dialog-button>
</content-block>
`;
  }
}
