import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '@material/mwc-icon';

@customElement('pricing-table-plan-feature')
export class PricingTablePlanFeature extends LitElement {
  @property({type: String}) public label: string = '';
  @property({type: String}) public text: string = '';
  @property({type: Boolean}) public available: boolean = false;

  protected render() {
    return html`
${this.text !== '' ? this.text : this.available ? html`<mwc-icon>check</mwc-icon>` : html`<mwc-icon>cancel</mwc-icon>`}
`;
  }
}
