import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('editable-sections-wrapper')
export class EditableSectionsWrapper extends LitElement {
  static styles = css`
::slotted(editable-section) {
    margin: 0;
}
::slotted(editable-section:not(:first-child):not(:last-child)) {
  border-radius: 0;
  border-bottom: none;
}
::slotted(editable-section:first-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}
::slotted(editable-section:last-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
`;

  protected render() {
    return html`
<slot></slot>
`;
  }
}
