import {customElement} from 'lit/decorators.js';
import {BaseTypography} from './base-typography';

@customElement('heading-6')
export class Heading6 extends BaseTypography {
  protected mdcTypography(): string {
    return 'headline6';
  }

  protected defaultTag(): string {
    return 'h6';
  }
}
