import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '../../components/typography/body-2';
import '../../components/typography/heading-6';
import '../../components/typography/subtitle-2';
import '../../components/card-display/card-display';

@customElement('review-display')
export class ReviewDisplay extends LitElement {
  @property({type: String}) public rating: string = '';
  @property({type: String}) public message: string = '';
  @property({type: String}) public reviewerName: string = '';
  @property({type: Boolean}) public reviewerVerified: boolean = false;
  @property({type: String}) public reviewDate: string = '';
  @property({type: String}) public media: string = '';

  static styles = css`
:host {
    display: block;
    margin-bottom: 15px;
}
a {
    text-decoration: none;
}
.review-display__media {
    max-width: 100%;
}
.review-display__date, .review-display__message {
    color: rgba(0, 0, 0, 0.54);
}
.review-display__verification {
    font-size: 0.8rem;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 5px;
    padding: 4px;
}
.review-display__rating {
    float: right;
}
.review-display__message {
    margin-top: 1rem;
}
`;

  private isMediaVideo(): boolean {
    if (!this.media) {
      return false;
    }

    const ext = this.media.substr(this.media.lastIndexOf('.') + 1).toLowerCase();

    // matching the logic in the backend found in Testimony.class.php
    return ['jpg', 'jpeg', 'png', 'gif'].indexOf(ext) === -1;
  }

  protected render() {
    return html`
<div class="review-display">
    <card-display>
        ${this.isMediaVideo() ? html`
          <video slot="media" class="review-display__media" controls><source src="${this.media}"></video>
          `: html`
          <img slot="media" class="review-display__media" src="${this.media}" >
        `}
        
        <div slot="content" class="review-display__content">
            <heading-6 tag="h3">
                ${this.reviewerName}
                ${this.reviewerVerified ? html`
                <small class="review-display__verification">Verified</small>
                ` : ''}
                ${this.rating !== '' ? html`
                <span class="review-display__rating">${this.rating}/5</span>
                ` : ''}
            </heading-6>
            <subtitle-2 class="review-display__date">Reviewed on ${this.reviewDate}</subtitle-2>
            <body-2 class="review-display__message">${this.message}</body-2>
        </div>
        
        <slot name="actions" slot="actions"></slot>
    </card-display>
</div>
`;
  }
}
