import {html, LitElement, TemplateResult} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {PricingTablePlanFeature} from './pricing-table-plan-feature';

@customElement('pricing-table-plan')
export class PricingTablePlan extends LitElement {
  @property({type: String}) public planName: string = '';
  @property({type: Number}) public price: number = 0;
  @property({type: String}) public priceFrequency: string = '';
  @property({type: String}) public priceText: string = '';
  public features: PricingTablePlanFeature[] = [];

  connectedCallback() {
    super.connectedCallback();

    this.features = Array.from(this.querySelectorAll('pricing-table-plan-feature'));
  }

  private displayPrice(): TemplateResult {
    if (this.priceText !== '') {
      return html`${this.priceText}`;
    }

    return this.price === 0 ? html`Free` : html`$${this.price / 100}/${this.priceFrequency}`;
  }

  protected render() {
    return html`
${this.planName}
<br>
${this.displayPrice()}
<br>
<slot name="plan-selection"></slot>
`;
  }
}
