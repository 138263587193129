import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('flex-column-container')
export class FlexColumnContainer extends LitElement {
  @property({type: String}) align = 'center';
  @property({type: Number}) gap = 0;
  @property({type: Boolean}) columnForMobile: boolean = false;

  private instanceStyles: any;

  connectedCallback(): void {
    super.connectedCallback();

    this.instanceStyles = html`
<style>
:host {
    align-items: ${this.align};
}
::slotted(flex-column) {
    margin-top: ${this.gap}px;
    margin-left: ${this.gap}px;
}
</style>
`;
  }

  static styles = css`
:host {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}
@media screen and (max-width: 600px) {
    :host([columnForMobile]) {
        flex-direction: column;
    }
}
`;

  protected render() {
    return html`
${this.instanceStyles}
<slot></slot>
`;
  }
}
