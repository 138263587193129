import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessEventForm} from '../../../symfony/forms';
import {date} from '../../../helpers/formatters/date-formatter';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/editable-section/editable-section';

@customElement('backend-business-addevent-page')
export class AddeventPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["image", "title", "description", "start_date", "end_date", "start_time", "end_time", "is_special"]|json', type: Object}) private form!: BusinessEventForm;

  protected render() {
    return html`
<narrow-content centered>
    <editable-section
        multipart
        heading="Event"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'image', 'type': 'file', 'label': 'Image', 'name': 'business_event[image]', 'value': ''},
            {'leadingIcon': 'label', 'type': 'text', 'label': 'Title', 'name': 'business_event[title]', 'value': this.form.title.value},
            {'leadingIcon': 'subject', 'type': 'text', 'label': 'Description', 'name': 'business_event[description]', 'value': this.form.description.value},
            {'leadingIcon': 'date_range', 'type': 'combination', 'label': 'Start & End Dates', 'value': `${this.form.start_date.value && this.form.end_date.value ? `${date(this.form.start_date.value)} - ${date(this.form.end_date.value)}` : ''}`, 'inputs': [
                {'leadingIcon': '', 'type': 'date', 'label': 'Start Date', 'name': 'business_event[start_date]', 'value': this.form.start_date.value},
                {'leadingIcon': '', 'type': 'date', 'label': 'End Date', 'name': 'business_event[end_date]', 'value': this.form.end_date.value},
            ]},
            {'leadingIcon': 'access_time', 'type': 'combination', 'label': 'Start & End Times', 'value': `${this.form.start_time.value && this.form.end_time.value ? `${this.form.start_time.value} - ${this.form.end_time.value}` : ''}`, 'inputs': [
                {'leadingIcon': '', 'type': 'text', 'label': 'Start Time', 'name': 'business_event[start_time]', 'value': this.form.start_time.value},
                {'leadingIcon': '', 'type': 'text', 'label': 'End Time', 'name': 'business_event[end_time]', 'value': this.form.end_time.value},
            ]},
            {'leadingIcon': 'star', 'type': 'switch', 'label': 'Is this a special event?', 'name': 'business_event[is_special]', 'value': this.form.description.value ? 'on' : 'off'},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
