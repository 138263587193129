import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {ConsumerForm} from '../../../symfony/forms';
import {BusinessCustomField, BusinessExtra} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import {date} from '../../../helpers/formatters/date-formatter';
import '../../../components/layout/narrow-content';

@customElement('backend-business-customerform-page')
export class CustomerformPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["email", "first_name", "last_name", "password", "password_confirm", "zip_code", "phone", "gender", "dob"]|json', type: Object}) private form!: ConsumerForm;
  @symfony({variable: '$form', converter: '_call:"isNew":""', type: Boolean}) private isNew: boolean = false;
  @symfony({variable: '$sf_request', converter: '_call:"isMethod":"post"', type: Boolean}) private isPost: Boolean = false;
  @symfony({variable: '$sf_request', converter: '_call:"getParameterHolder"|_call:"getAll"|json', type: Object}) private parameters?: any;
  @symfony({variable: '$business_extra', converter: 'take:["reg_show_first", "reg_show_last", "reg_show_zip", "reg_show_phone", "reg_show_gender", "reg_show_dob"]|json', type: Object}) private businessExtra!: BusinessExtra;
  @symfony({variable: '$custom_fields', converter: 'take:["id", "name", "type_id", "BusinessCustomFieldValue.value"]|json', type: Array}) private customFields: BusinessCustomField[] = [];
  @symfony({variable: '$customer_data', converter: 'json', type: Object}) private customerData?: { [key: string]: string };

  private getFieldItems(): ItemDisplay[] {
    const fields: any = {
      email: {leadingIcon: 'alternate_email', type: 'text', label: 'Email', name: 'consumer[email]', value: this.form.email.value},
      first_name: {leadingIcon: 'account_circle', type: 'text', label: 'First Name', name: 'consumer[first_name]', value: this.form.first_name.value},
      last_name: {leadingIcon: 'account_circle', type: 'text', label: 'Last Name', name: 'consumer[last_name]', value: this.form.last_name.value},
      passwords: {
        leadingIcon: 'lock', type: 'combination', label: 'Password', value: '********', inputs: [
          {type: 'password', label: `Password${this.isNew ? '' : ' (leave blank if not changing)'}`, name: 'consumer[password]', value: ''},
          {type: 'password', label: 'Confirm Password', name: 'consumer[password_confirm]', value: ''},
        ],
      },
      zip: {leadingIcon: 'place', type: 'text', label: 'Postal Code', name: 'consumer[zip_code]', value: this.form.zip_code.value},
      phone: {leadingIcon: 'phone', type: 'text', label: 'Phone', name: 'consumer[phone]', value: this.form.phone.value},
      gender: {leadingIcon: 'people', type: 'select', label: 'Gender', name: 'consumer[gender]', value: this.form.gender.value, options: this.form.gender.options},
      dob: {leadingIcon: 'cake', type: 'date', label: 'Birthday', name: 'consumer[dob]', value: this.form.dob.value},
    };

    const filtered: any = {
      email: true,
      first_name: this.businessExtra.reg_show_first,
      last_name: this.businessExtra.reg_show_last,
      passwords: true,
      zip: this.businessExtra.reg_show_zip,
      phone: this.businessExtra.reg_show_phone,
      gender: this.businessExtra.reg_show_gender,
      dob: this.businessExtra.reg_show_dob,
    };

    const items: ItemDisplay[] = [];

    Object.keys(fields).forEach((field) => {
      if (filtered[field]) {
        items.push(fields[field]);
      }
    });

    const customFields: ItemDisplay[] = this.customFields.map((field) => {
      const param = this.parameters[`field_${field.id}`] || '';
      const data = this.customerData?.[field.id] || '';
      const value = this.isPost ? param : data;

      if (field.type_id == '1') {
        return {leadingIcon: 'label', type: 'text', label: field.name, name: `field_${field.id}`, value: value};
      }

      if (field.type_id == '2') {
        return {leadingIcon: 'notes', type: 'textarea', label: field.name, name: `field_${field.id}`, value: value};
      }

      if (field.type_id == '3') {
        return {leadingIcon: 'visibility', type: 'switch', label: field.name, name: `field_${field.id}`, value: value == '1' ? 'on' : 'off'};
      }

      if (field.type_id == '4') {
        const options = field.BusinessCustomFieldValue?.map((value) => ({
          key: String(value.id),
          label: value.value,
          selected: value.id == +data,
        }));

        return {leadingIcon: 'label', type: 'select', label: field.name, name: `field_${field.id}`, value: value, options: options};
      }

      if (field.type_id == '5') {
        return {leadingIcon: 'event', type: 'date', label: field.name, name: `field_${field.id}`, value: (this.isPost || !this.isNew) && value ? date(value) : ''};
      }

      throw new Error(`We don't know how to handle field type "${field.type_id}"`);
    });

    return items.concat(customFields);
  }

  protected render() {
    return html`
<narrow-content centered>
    <editable-section
        heading="Customer"
        description=""
        submitName="submit_save"
        .items=${this.getFieldItems()}>
    </editable-section>
</narrow-content>
`;
  }
}
