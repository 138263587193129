import {html, LitElement} from 'lit';
import {customElement, state, query} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessRewardForm} from '../../../symfony/forms';
import {Business, BusinessReviewQuestion, BusinessReviewResponse} from '../../../symfony/models';
import {FormSubmissionTextfield} from '../../../components/form-submission/fields/form-submission-textfield';
import {date} from '../../../helpers/formatters/date-formatter';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/typography/body-1';
import '../../../components/qr-code/qr-code';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/sortable-list/sortable-list';
import '../../../components/sortable-list/sortable-list-item';
import '../../../components/open-dialog-button/open-dialog-button';
import '../../../components/actionable-list-item/actionable-list-item';
import '../../../components/form-submission/fields/form-submission-switch';

@customElement('backend-business-programscheckin-page')
export class ProgramscheckinPage extends LitElement {
  @symfony({variable: '$qr_url', type: String}) private qrUrl: string = '';
  @symfony({variable: '$form', converter: 'form_fields:["id", "is_punch_purchase", "name_buy", "name_get", "punch_purchase_price", "minimum_punch_purchase_checkins", "checkins", "refresh_time", "expires_after", "checkin_times", "checkin_per", "allow_qr", "allow_fb_free_punch", "review", "review_allow_free_punch", "review_positive", "review_facebook_post", "security_code", "security_reset", "security_reset_time", "security_notify", "security_access_code", "security_reset_qr", "security_reset_time_qr", "security_notify_qr"]|json', type: Object}) private form!: BusinessRewardForm;
  @symfony({variable: '$form', converter: '_call:"isNew":""', type: Boolean}) private isNew: boolean = false;
  @symfony({variable: '$business', converter: 'take:["slug"]|json', type: Object}) private business!: Business;
  @symfony({variable: '$questions', converter: '_default:[]|take:["id", "question"]|json', type: Array}) private questions: BusinessReviewQuestion[] = [];
  @symfony({variable: '$reviews_submitted', converter: '_default:[]|take:["id", "feedback", "rating", "created_at"]|json', type: Array}) private reviewsSubmitted: BusinessReviewResponse[] = [];
  @state() private isPunchPurchase: boolean = false;
  @state() private allowQr: boolean = false;
  @state() private requestReviewAfterPunch: boolean = false;
  @query('#add-question-field') private addQuestionField!: FormSubmissionTextfield;

  connectedCallback() {
    super.connectedCallback();

    this.isPunchPurchase = Boolean(this.form.is_punch_purchase.value);
    this.allowQr = Boolean(this.form.allow_qr.value);
    this.requestReviewAfterPunch = Boolean(this.form.review.value);
  }

  private programDescription(): string {
    if (this.isPunchPurchase) {
      return this.form.punch_purchase_price.value !== '' && this.form.minimum_punch_purchase_checkins.value !== '' ? `${this.form.punch_purchase_price.value} ${this.form.minimum_punch_purchase_checkins.value}` : `Not Ready`;
    }

    return this.form.name_buy.value !== '' && this.form.name_get.value !== '' ? `${this.form.name_buy.value} ${this.form.name_get.value}` : `Not Ready`;
  }

  private handleOpenDialogButtonPrimaryClicked(): void {
    const value = this.addQuestionField.value;

    if (value === '') return;

    fetch('/admin/business/reviewsquestionadd', {
      method: 'post',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: `rewardid=${this.form.id.value}&question=${value}`,
    });

    window.location.reload();
  }

  protected render() {
    return html`
<narrow-content centered>
    ${!this.isNew && this.allowQr ? html`
    <content-block textCenter>
        <qr-code fullurl size="150" link="${this.qrUrl}"></qr-code>
        <body-1>To download, right click and "Save Image/Picture As"</body-1>
    </content-block>
    ` : ''}

    <editable-section
        heading="Loyalty Program"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'loyalty', 'type': 'combination', 'label': 'Program Description', 'value': this.programDescription(), 'inputs': [
                {'leadingIcon': '', 'type': 'text', 'label': this.isPunchPurchase ? 'Set punch purchase amount' : 'Buy/Spend Description', 'name': `business_reward[${this.isPunchPurchase ? 'punch_purchase_price' : 'name_buy'}]`, 'value': this.isPunchPurchase ? this.form.punch_purchase_price.value : this.form.name_buy.value},
                {'leadingIcon': '', 'type': 'text', 'label': this.isPunchPurchase ? 'Set minimum number of punches for redeem' : 'Get Description', 'name': `business_reward[${this.isPunchPurchase ? 'minimum_punch_purchase_checkins' : 'name_get'}]`, 'value': this.isPunchPurchase ? this.form.minimum_punch_purchase_checkins.value : this.form.name_get.value},
                {'leadingIcon': '', 'type': 'select', 'label': 'Number of checkins/punches', 'name': 'business_reward[checkins]', 'value': this.form.checkins.value, 'options': this.form.checkins.options},
                {'leadingIcon': '', 'type': 'select', 'label': 'How long after the reward has been earned should this card refresh?', 'name': 'business_reward[refresh_time]', 'value': this.form.refresh_time.value, 'options': this.form.refresh_time.options},
                {'leadingIcon': '', 'type': 'select', 'label': 'How many days after reward is earned should it expire?', 'name': 'business_reward[expires_after]', 'value': this.form.expires_after.value, 'options': this.form.expires_after.options},
                {'leadingIcon': '', 'type': 'select', 'label': 'How many times can a customer checkin?', 'name': 'business_reward[checkin_times]', 'value': this.form.checkin_times.value, 'options': this.form.checkin_times.options},
                {'leadingIcon': '', 'type': 'select', 'label': 'Per', 'name': 'business_reward[checkin_per]', 'value': this.form.checkin_per.value, 'options': this.form.checkin_per.options},
            {'leadingIcon': '', 'type': 'switch', 'label': 'Give customers their last punch free if they share each punch through Facebook', 'name': 'business_reward[allow_fb_free_punch]', 'value': this.form.allow_fb_free_punch.value ? 'on' : 'off'},
            ]},
            {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business_reward[is_punch_purchase]', 'value': '${this.isPunchPurchase ? \'on\' : \'off\'}'},
            {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business_reward[allow_qr]', 'value': '${this.allowQr ? \'on\' : \'off\'}'},
        ]}>
        <content-block>
            <form-submission-switch @form-submission-field-updated="${(e: CustomEvent) => this.isPunchPurchase = e.detail.value === 'on'}" name="is_punch_purchase" label="Set as Punch Purchase?" value="${this.form.is_punch_purchase.value ? 'on' : 'off'}"></form-submission-switch>
            <br>
            <br>
            <form-submission-switch @form-submission-field-updated="${(e: CustomEvent) => this.allowQr = e.detail.value === 'on'}" name="allow_qr" label="Allow customers to use QR codes to punch their card?" value="${this.form.allow_qr.value ? 'on' : 'off'}"></form-submission-switch>
        </content-block>
    </editable-section>
    
    ${!this.isNew ? html`
    <editable-section
        ?individualEdits="${!this.requestReviewAfterPunch}"
        heading="Review Questions & Settings"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'question_answer', 'type': this.requestReviewAfterPunch ? 'combination' : 'hidden', 'label': 'Review Questions', 'value': '', 'inputs': [
                {'leadingIcon': '', 'type': 'switch', 'label': 'Give customers a free punch if they fill out the review', 'name': 'business_reward[review_allow_free_punch]', 'value': this.form.review_allow_free_punch.value ? 'on' : 'off'},
                {'leadingIcon': '', 'type': 'select', 'label': 'Review will be positive if average rating is greater than', 'name': 'business_reward[review_positive]', 'value': this.form.review_positive.value, 'options': this.form.review_positive.options},
                {'leadingIcon': '', 'type': 'switch', 'label': 'Automatically post to Facebook if review is positive', 'name': 'business_reward[review_facebook_post]', 'value': this.form.review_facebook_post.value ? 'on' : 'off'},
            ]},
            {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business_reward[review]', 'value': this.requestReviewAfterPunch ? 'on' : 'off'},
        ]}>
    
        ${this.requestReviewAfterPunch ? html`
        <sortable-list url="/admin/business/reviewsquestionorder" fieldName="questions">
            ${this.questions.map((question) => html`
            <sortable-list-item sortId="${question.id}">
                <actionable-list-item
                    heading="${question.question}"
                    subHeading="Question"
                    .actions=${[{'href': `/admin/business/reviewsquestiondelete/id/${question.id}`, 'label': 'Delete', 'confirm': 'Are you sure?'}]}>
                </actionable-list-item>
            </sortable-list-item>
            `)}
        </sortable-list>
        
        <open-dialog-button label="Add A Question" primaryLabel="Add" @open-dialog-button-primary-clicked="${this.handleOpenDialogButtonPrimaryClicked}">
            <form-submission-textfield id="add-question-field" name="question" label="Question" value=""></form-submission-textfield>
        </open-dialog-button>
        
        <content-block>
            <data-table fullWidth>
                <data-table-head>
                    <data-table-row>
                        <data-table-cell>Feedback</data-table-cell>
                        <data-table-cell>Rating</data-table-cell>
                        <data-table-cell>Date</data-table-cell>
                        <data-table-cell>View</data-table-cell>
                    </data-table-row>
                </data-table-head>
                <data-table-body>
                    ${this.reviewsSubmitted.map((review) => html`
                        <data-table-row>
                            <data-table-cell>${review.feedback}</data-table-cell>
                            <data-table-cell>${review.rating}</data-table-cell>
                            <data-table-cell>${date(review.created_at)}</data-table-cell>
                            <data-table-cell link="/admin/business/review/id/${review.id}">View</data-table-cell>
                        </data-table-row>
                    `)}
                </data-table-body>
            </data-table>
        </content-block>
        
        ` : ''}
        
        <content-block>
            <form-submission-switch @form-submission-field-updated="${(e: CustomEvent) => this.requestReviewAfterPunch = e.detail.value === 'on'}" name="review" label="Request consumer review after a successful punch" value="${this.form.review.value ? 'on' : 'off'}"></form-submission-switch>
        </content-block>
    </editable-section>
    ` : ''}
    
    <editable-section
        heading="Security"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'security', 'type': 'text', 'label': 'Security Code', 'name': 'business_reward[security_code]', 'value': this.form.security_code.value},
            {'leadingIcon': 'loyalty', 'type': 'combination', 'label': 'Reset Time', 'value': `Every ${this.form.security_reset.options.find((o) => o.selected)?.label || 'N/A'} at ${this.form.security_reset_time.value}`, 'inputs': [
                {'leadingIcon': '', 'type': 'select', 'label': 'Reset security code every', 'name': 'business_reward[security_reset]', 'value': this.form.security_reset.value, 'options': this.form.security_reset.options},
                {'leadingIcon': '', 'type': 'select', 'label': 'Reset Time (UTC−06:00)', 'name': 'business_reward[security_reset_time]', 'value': this.form.security_reset_time.value, 'options': this.form.security_reset_time.options},
            ]},
            {'leadingIcon': 'notes', 'type': 'textarea', 'label': 'Notify the following email addresses when security code is reset (separate each email address with a comma)', 'name': 'business_reward[security_notify]', 'value': this.form.security_notify.value},
        ]}>
    </editable-section>
    
    <editable-section
        heading="Security Code Page"
        description="If you would like to have the code available on a computer, tablet, or mobile phone for your staff to see, use the security code page. It is recommended to use this page if you set the security code to change after each checkin/punch."
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'qr_code', 'type': 'text', 'label': 'Security Page Access Code', 'name': 'business_reward[security_access_code]', 'value': this.form.security_access_code.value},
        ]}>
        
        <content-block centered>
            <body-1>Security Code Page: <a href="${window.location.origin}/${this.business.slug}/code" target="_blank">${window.location.host}/${this.business.slug}/code</a></body-1>
        </content-block>
        
    </editable-section>
    
    ${this.allowQr ? html`
    <editable-section
        heading="QR Code Security"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'qr_code', 'type': 'select', 'label': 'QR code expires every', 'name': 'business_reward[security_reset_qr]', 'value': this.form.security_reset_qr.value, 'options': this.form.security_reset_qr.options},
            {'leadingIcon': 'qr_code', 'type': 'select', 'label': 'Reset Time (UTC−06:00)', 'name': 'business_reward[security_reset_time_qr]', 'value': this.form.security_reset_time_qr.value, 'options': this.form.security_reset_time_qr.options},
            {'leadingIcon': 'qr_code', 'type': 'textarea', 'label': 'Notify the following email addresses when the QR code expires (separate each email address with a comma)', 'name': 'business_reward[security_notify_qr]', 'value': this.form.security_notify_qr.value},
        ]}>
    </editable-section>
    ` : ''}
</narrow-content>
`;
  }
}

