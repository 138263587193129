import {css} from 'lit';

export const style = css`
:host {
    display: block;
}
:host(:not([hash="home"])) .top-section {
    display: none;
}
.top-section {
    position: relative;
}
.top-section-desktop {
    display: none;
}
.top-section-artwork img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}
.top-section-information {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -5px;
    z-index: 1;
}
.top-section-information__extra-info {
    display: none;
}
.top-section-gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    //background: linear-gradient(rgba(0, 0, 0, 0), #3c3c3c);
    background: linear-gradient(rgba(0, 0, 0, 0), #fff);
}

.bottom-section {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.7);
    padding: 15px;
}
`;

export const desktopStyle = css`
@media screen and (min-width: 800px) {
    .top-section-desktop {
        display: block;
    }
    .top-section {
        display: none;
    }
    .top-section-desktop {
        --app-logo-width: 175px;
        --app-logo-height: 175px;
        --desktop-margin-sides: 100px;
        margin-bottom: 40px;
    }
    .cover-section {
        display: flex;
    }
    .cover-image-container {
        flex: 1;
    }
    .cover-image {
        max-width: 100%;
    }
    .review-grade {
        width: 500px;
        padding: 15px;
        background-color: #f0f1f5;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .review-grade__partner-image {
        max-width: 100%;
    }
    .app-logo {
        width: var(--app-logo-width);
        height: var(--app-logo-height);
        position: absolute;
        margin-top: -40px;
        margin-left: var(--desktop-margin-sides);
        border: 5px solid #fff;
        border-radius: 10%;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
    }
    .titlebar-section {
        background-color: var(--primary-color);
        padding-left: calc(var(--app-logo-width) + var(--desktop-margin-sides) + 20px);
        padding-right: var(--desktop-margin-sides);
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        color: #fff;
    }
    .titlebar-section__share {
        display: flex;
        align-items: center;
    }
    .titlebar-section__share img {
        width: 26px;
        margin-left: 5px;
    }
    .navigation-section {
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #f0f1f5;
        padding-left: calc(var(--app-logo-width) + var(--desktop-margin-sides) + 20px);
    }
    .navigation-section__link {
        padding: 0 15px;
        text-align: center;
    }
}
`;
