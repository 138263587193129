import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('carousel-container')
export class CarouselContainer extends LitElement {
  static styles = css`
:host {
    display: block;
    margin-top: 15px;
    overflow-x: scroll;
}
.items {
    display: flex;
    min-width: min-content;
}
`;

  protected render() {
    return html`
<div class="items">
    <slot></slot>
</div>
`;
  }
}
