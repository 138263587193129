import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';

@customElement('backend-business-url-page')
export class UrlPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "slug"]|json', type: Object}) private form!: BusinessForm;

  protected render() {
    return html`
<narrow-content>
    <editable-section
        fullWidth
        heading="Website URL"
        description="If you change this URL, any existing links will become outdated."
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
          {'leadingIcon': 'web_asset', 'type': 'text', 'label': `${window.location.host}/`, 'name': 'business[slug]', 'value': this.form.slug.value},
          {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business[id]', 'value': this.form.id.value},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
