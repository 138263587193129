import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {BusinessExtraForm} from '../../../../symfony/forms';
import {Business, BusinessExtra} from '../../../../symfony/models';
import {ItemDisplay} from '../../../../components/editable-section/editable-section';
import '../../../../components/typography/body-1';
import '../../../../components/code-block/code-block';
import '../../../../components/editable-section/editable-sections-wrapper';
import '../../../../components/layout/content-block';
import '../../../../components/virtual-card/cards/spendtunity-card';
import '../../../../components/virtual-card/cards/membership-card';

@customElement('app-page-tab-add-ons')
export class AppPageTabAddOns extends LitElement {
  @property({type: Object}) public businessExtraForm!: BusinessExtraForm;
  @property({type: Object}) public businessExtra!: BusinessExtra;
  @property({type: Object}) public business!: Business;
  @property({type: String}) public baseBusinessImageDir: string = '';

  private imageValue(value?: string): string {
    if (!value) {
      return '';
    }

    return this.baseBusinessImageDir + value;
  }

  private escapedQuotes(value: string): string {
    return value.replace(/"/g, '\\"');
  }

  protected render() {
    return html`
<editable-sections-wrapper>
    <editable-section
        multipart
        heading="30 Day Birthday Club Card"
        description="Offer your members something special during the month of their birthday. Ex: Free appetizer, 50% Off."
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'credit_card', 'type': 'combination', 'label': 'Enable Birthday Club Card', 'value': this.businessExtraForm?.show_spendtunity_card.value ? 'ON' : 'OFF', 'inputs': [
                {'type': 'switch', 'label': 'Enable Birthday Club Card', 'name': 'business_extra[show_spendtunity_card]', 'value': this.businessExtraForm?.show_spendtunity_card.value ? 'on' : 'off'},
                {'type': 'text', 'label': 'Birthday Card Spend Condition', 'name': 'business_extra[spendtunity_spend_condition]', 'value': this.businessExtraForm?.spendtunity_spend_condition.value},
                {'type': 'text', 'label': 'Birthday Card Reward', 'name': 'business_extra[spendtunity_reward]', 'value': this.businessExtraForm?.spendtunity_reward.value},
                {'type': 'text', 'label': 'Birthday Card Background Color', 'name': 'business_extra[spendtunity_background_color]', 'value': this.businessExtraForm?.spendtunity_background_color.value},
            ]},
        ]}>
        <content-block centered>
            <spendtunity-card backgroundColor="${this.businessExtra.spendtunity_background_color}" logoUrl="${this.baseBusinessImageDir}${this.businessExtra.logo}" reward="${this.businessExtra.spendtunity_reward}" condition="${this.businessExtra.spendtunity_spend_condition}"></spendtunity-card>
        </content-block>
    </editable-section>

    <editable-section
        multipart
        heading="Wifi Access for Customers - Lead Gen"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'wifi', 'type': 'text', 'label': 'WiFi Network Name', 'name': 'business_extra[wifi_network_name]', 'value': this.businessExtraForm?.wifi_network_name.value},
            {'leadingIcon': 'lock', 'type': 'text', 'label': 'WiFi Network Password', 'name': 'business_extra[wifi_password]', 'value': this.businessExtraForm?.wifi_password.value},
        ]}>
    </editable-section>

    <editable-section
        multipart
        heading="Membership Card"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'card_membership', 'type': 'combination', 'label': 'Enable Membership Card', 'value': this.businessExtraForm?.show_membership_card.value ? 'ON' : 'OFF', 'inputs': [
                {'type': 'switch', 'label': 'Disable Membership Card in App', 'name': 'business_extra[show_membership_card]', 'value': this.businessExtraForm?.show_membership_card.value ? 'on' : 'off'},
                {'type': 'file', 'label': 'Membership Card Design', 'name': 'business_extra[membership_card_design]', 'value': this.imageValue(this.businessExtraForm?.membership_card_design.value)},
                {'type': 'text', 'label': 'Membership Card Discount', 'name': 'business_extra[membership_card_discount]', 'value': this.businessExtraForm?.membership_card_discount.value},
            ]},
        ]}>
        <content-block centered>
            <membership-card design="${this.baseBusinessImageDir}${this.businessExtra.membership_card_design}" discount="${this.businessExtra.membership_card_discount}"></membership-card>
        </content-block>
    </editable-section>

    <editable-section
        multipart
        heading="Embed Google Tour"
        description="You can add your Google Street View 360 virtual tour right to your app."
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': '360', 'type': 'textarea', 'label': 'Google Tour', 'name': 'business_extra[google_tour]', 'value': this.escapedQuotes(this.businessExtraForm?.google_tour.value)},
        ]}>
        <body-1>If you do not have a tour click <a target="_blank" href="http://merchantview360.com/contact/">here</a> to request a call for a quote</body-1>
    </editable-section>

    <editable-section
        multipart
        heading="Reviews Embed Code - Add reviews to your website"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${[]}>
        
        <body-1><a href="https://${window.location.host}/customer-reviews/${this.business.slug}" target="_blank">Click here to preview Collective Reviews</a></body-1>
        <code-block>
            &lt;?php echo file_get_contents("https://${window.location.host}/customer-reviews/${this.business.slug}"); ?&gt;
        </code-block>
    </editable-section>

    <editable-section
        multipart
        heading="Review Grade Embed Code"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${[]}>
        
        <img src="${window.location.origin}/embed/${this.business.slug}/review-grade" />
        <code-block>
            &lt;img src="${window.location.origin}/embed/${this.business.slug}/review-grade" /&gt;
        </code-block>
    </editable-section>

    <editable-section
        multipart
        heading="Extra"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'link', 'type': 'text', 'label': 'Nearby Link', 'name': 'business_extra[local_link]', 'value': this.businessExtraForm?.local_link.value},
            {'leadingIcon': 'email', 'type': 'text', 'label': 'No Deals Email', 'name': 'business_extra[no_deals_email]', 'value': this.businessExtraForm?.no_deals_email.value},
            {'leadingIcon': 'announcement', 'type': 'text', 'label': 'Scrolling Announcements', 'name': 'business_extra[mobile_tagline]', 'value': this.businessExtraForm?.mobile_tagline.value},
            {'leadingIcon': 'visibility_off', 'type': 'switch', 'label': 'Show Business Name In Header', 'name': 'business_extra[show_name_in_header]', 'value': this.businessExtraForm?.show_name_in_header.value ? 'on' : 'off'},
        ]}>
    </editable-section>
</editable-sections-wrapper>

`;
  }
}
