import {css, html, LitElement, PropertyValues} from 'lit';
import {customElement, state, property} from 'lit/decorators.js';
import {SortableListItem} from './sortable-list-item';

interface SortChangeIndexes {
  from: number;
  to: number;
}

@customElement('sortable-list')
export class SortableList extends LitElement {
  @property({type: String}) public url: string = '';
  @property({type: String}) public fieldName: string = '';
  @state() private items: SortableListItem[] = [];

  private draggingId?: string;
  private originalDraggingIndex?: number;

  static styles = css`
:host {
    display: contents;
}
`;

  protected firstUpdated() {
    const slot = this.shadowRoot!.querySelector('slot') as HTMLSlotElement;

    this.items = slot.assignedElements() as SortableListItem[];

    this.setupListeners();
  }

  protected updated(_changedProperties: PropertyValues) {
    // super.updated(_changedProperties);
    if (!_changedProperties.has('items')) {
      return;
    }

    // this will replace the sortable-list-item elements in our light-dom
    // with the updated order
    this.innerHTML = '';
    this.items.forEach((item) => {
      // hide the sortable-list-item currently being dragged
      if (item.sortId === this.draggingId) {
        item.setAttribute('invisible', 'true');
      }
      this.appendChild(item);
    });
  }

  private setupListeners(): void {
    this.items.forEach((item) => {
      item.addEventListener('dragover', (ev: DragEvent) => {
        ev.preventDefault();
        ev.dataTransfer!.dropEffect = 'move';
        // don't do anything if it was dragged in the same spot as before
        if (this.draggingId !== item.sortId) {
          this.reorderItems(this.draggingId!, item.sortId!);
        }
      });
      item.addEventListener('drop', (ev: DragEvent) => {
        ev.preventDefault();
        const newIndex = this.items.findIndex((i) => i.sortId === item.sortId);
        // don't do anything if it was dropped in its original position
        if (newIndex !== this.originalDraggingIndex) {
          this.reorderItems(this.draggingId!, item.sortId!, true);
        }
      });
      item.addEventListener('dragstart', (ev: DragEvent) => {
        ev.dataTransfer!.dropEffect = 'move';
        this.draggingId = item.sortId;
        this.originalDraggingIndex = this.items.findIndex((i) => i.sortId === item.sortId);
      });
      item.addEventListener('dragend', () => {
        this.draggingId = undefined;
        this.originalDraggingIndex = undefined;
        this.items.forEach((i) => i.removeAttribute('invisible'));
      });
    });
  }

  private reorderItems(fromId: string, toId: string, persist: boolean = false): void {
    const indexes = this.getIndexes(fromId, toId);

    const newOrder = this.moveItem(indexes.from, indexes.to);

    this.items = newOrder;

    if (persist) {
      const data = this.items.map((item) => `${this.fieldName}[]=${item.sortId}`).join('&');

      fetch(this.url, {
        method: 'post',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: data,
      });
    }
  }

  private getIndexes(fromId: string, toId: string): SortChangeIndexes {
    const fromIndex = this.items.findIndex((item) => item.sortId === fromId);
    const toIndex = this.items.findIndex((item) => item.sortId === toId);

    return {
      from: fromIndex,
      to: toIndex,
    };
  }

  private moveItem(fromIndex: number, toIndex: number): SortableListItem[] {
    const items = Array.from(this.items);

    items.splice(toIndex, 0, ...items.splice(fromIndex, 1));

    return items;
  }

  protected render() {
    return html`
<slot></slot>
`;
  }
}
