import {FormSubmissionBase} from './form-submission-base';
import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import '@material/mwc-formfield';
import '@material/mwc-switch';
import {Switch} from '@material/mwc-switch';

@customElement('form-submission-switch')
export class FormSubmissionSwitch extends FormSubmissionBase {
  @property({type: String}) label = '';
  @query('mwc-switch') private mwcSwitch!: Switch;

  protected get shouldInclude(): boolean {
    return this.value === 'on';
  }

  protected render() {
    return html`<mwc-formfield label="${this.label}"><mwc-switch @change="${() => this.value = this.mwcSwitch.checked ? 'on' : 'off'}" ?checked="${this.value === 'on'}"></mwc-switch></mwc-formfield>`;
  }
}
