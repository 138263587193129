import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessReviewResponse} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/review/review-display';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';

@customElement('backend-business-review-page')
export class ReviewPage extends LitElement {
  @symfony({variable: '$review', converter: 'take:["feedback", "rating", "created_at", "Consumer.first_name", "Consumer.last_name", "Consumer.email", "Consumer.phone", "BusinessReviewResponseQuestion.id", "BusinessReviewResponseQuestion.rating", "BusinessReviewResponseQuestion.BusinessReviewQuestion.question"]|json', type: Object}) private review!: BusinessReviewResponse;

  protected render() {
    return html`
<narrow-content>
    <content-block>
        <flex-column-container>
            <flex-column><body-1>Email: ${this.review.Consumer.email}</body-1></flex-column>
            <flex-column flex="initial"><body-1>Phone: ${this.review.Consumer.phone}</body-1></flex-column>
        </flex-column-container>
    </content-block>

    <review-display
        rating="${this.review.rating}"
        message="${this.review.feedback}"
        reviewerName="${this.review.Consumer.first_name} ${this.review.Consumer.last_name}"
        reviewDate="${date(this.review.created_at)}">
    </review-display>
    
    <body-1>Question Responses:</body-1>
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Question</data-table-cell>
                <data-table-cell>Rating</data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.review.BusinessReviewResponseQuestion.map((question) => html`
            <data-table-row>
                <data-table-cell>${question.BusinessReviewQuestion.question}</data-table-cell>
                <data-table-cell>${question.rating}</data-table-cell>
            </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    
</narrow-content>
`;
  }
}
