import {css, html, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {FormSubmission} from '../form-submission/form-submission';
import {SelectOption} from '../form-submission/fields/form-submission-select';
import {CustomersFilterCountUpdated} from './events/customers-filter-count-updated';
import {BusinessCustomField} from '../../symfony/models';
import '../../components/typography/body-1';
import '../../components/typography/heading-5';
import '../../components/layout/content-block';
import '../../components/form-submission/fields/form-submission-textfield';
import '../../components/form-submission/fields/form-submission-checkbox';
import '../form-submission/fields/form-submission-select';

/* eslint-disable camelcase */
export interface Filters {
  gender?: string[];
  age_start?: string;
  age_end?: string;
  join_start?: string;
  join_end?: string;
  zipcode?: string[];
}
/* eslint-enable camelcase */

@customElement('customers-filter')
export class CustomersFilter extends LitElement {
  @property({type: Object}) public filters!: Filters;
  @property({type: Array}) public zipCodes: string[] = [];
  @property({type: Array}) public customFields: BusinessCustomField[] = [];
  @property({type: Number, reflect: true}) public count: number = 0;
  @property({type: Boolean}) public forSms: boolean = false;
  @query('.filters-form') private formSubmission?: FormSubmission;

  private pendingRequests: Request[] = [];

  static styles = css`
.filter {
    margin: 15px 0;
}
`;

  public getFormSubmission(): FormSubmission {
    return this.formSubmission as FormSubmission;
  }

  protected firstUpdated(): void {
    this.updateCount();
  }

  private async updateCount(): Promise<void> {
    this.count = await this.checkCount();
    this.dispatchEvent(new CustomersFilterCountUpdated(this.count));
  }

  private async checkCount(): Promise<number> {
    if (!this.formSubmission) {
      return Promise.resolve(0);
    }

    const url = this.forSms ? '/admin/business/filterconsumerscount' : '/admin/business/filterconsumerscount/data/true';

    // store the request so we can delay it and check for other pending requests and
    // only execute the last one
    this.pendingRequests.push(new Request(url, {
      method: 'post',
      body: await this.formSubmission.getFormData(),
    }));

    const numberOfRequests = this.pendingRequests.length;

    return new Promise((resolve) => {
      setTimeout(async () => {
        // if we are not the latest request, then just throw this one away
        if (this.pendingRequests.length !== numberOfRequests) {
          return resolve(0);
        }

        const response = await fetch(this.pendingRequests[this.pendingRequests.length - 1]);

        // clear the pending requests
        this.pendingRequests = [];

        resolve(parseInt(await response.text()));
      }, 100);
    });
  }

  private get zipOptions(): SelectOption[] {
    const options = this.zipCodes.map((zip) => {
      return {key: zip, label: zip};
    });

    // options.push({ key: "", label: "Unknown" });

    return options;
  }

  protected render() {
    return html`
<content-block centered>
    <heading-5 class="count">${this.count} Customers Selected</heading-5>
</content-block>

<form-submission class="filters-form" @form-submission-field-updated="${this.updateCount}">
    <div class="filter">
        <body-1>Filter by Gender</body-1>
        <form-submission-checkbox name="gender[]" label="Female" value="F" ?checked="${this.filters.gender?.includes('F')}"></form-submission-checkbox>
        <form-submission-checkbox name="gender[]" label="Male" value="M" ?checked="${this.filters.gender?.includes('M')}"></form-submission-checkbox>
        <form-submission-checkbox name="gender[]" label="Unknown" value="" ?checked="${this.filters.gender?.includes('')}"></form-submission-checkbox>
    </div>
    
    <div class="filter">
        <body-1>Filter by Birthday</body-1>
        <form-submission-textfield icon="event" type="date" name="age_start" label="Start" value="${this.filters.age_start || ''}"></form-submission-textfield>
        <form-submission-textfield icon="event" type="date" name="age_end" label="End" value="${this.filters.age_end || ''}"></form-submission-textfield>
    </div>

    <div class="filter">
        <body-1>Filter by Join Date</body-1>
        <form-submission-textfield icon="event" type="date" name="join_start" label="Start" value="${this.filters.join_start || ''}"></form-submission-textfield>
        <form-submission-textfield icon="event" type="date" name="join_end" label="End" value="${this.filters.join_end || ''}"></form-submission-textfield>
    </div>

    <div class="filter">
        <body-1>Filter by Zip/Postal Code</body-1>
        <form-submission-select fullwidth multi label="Zipcode" name="zipcode[]" value="" .options='${this.zipOptions}'></form-submission-select>
    </div>
    
    ${this.customFields.map((field) => html`
    <div class="filter">
        <body-1>${field.name}</body-1>
        
        ${field.type_id == '5' ? html`
        <form-submission-textfield type="date" name="field_start_${field.id}" label="Start" value=""></form-submission-textfield>
        <form-submission-textfield type="date" name="field_end_${field.id}" label="End" value=""></form-submission-textfield>
        ` : ''}
        
        ${field.type_id == '1' || field.type_id == '2' ? html`
        <form-submission-textfield fullwidth name="field_${field.id}" label="" value=""></form-submission-textfield>
        ` : ''}
        
        ${field.type_id == '3' ? html`
        <form-submission-checkbox name="field_${field.id}" label="${field.name}" value="1"></form-submission-checkbox>
        ` : ''}
        
        ${field.type_id == '4' ? html`
        ${field.BusinessCustomFieldValue?.map((value) => html`
        <form-submission-checkbox name="${field.id}" label="${value.value}" value="${value.id}"></form-submission-checkbox>
        `)}
        ` : ''}
    </div>
    `)}
    
</form-submission>
`;
  }
}
