import {html, LitElement} from 'lit';
import {customElement, state, property, query} from 'lit/decorators.js';
import {style} from './app-shell-css';
import '../drawer-content';
import '@material/mwc-top-app-bar';
import '@material/mwc-icon-button';
import '@material/mwc-icon';
import '@material/mwc-tab-bar';
import '@material/mwc-tab';
import '../../bottom-app-bar/bottom-app-bar';
import '../../footer-links/footer-links';
import '../../footer-links/footer-links-item';
import {ApplicationNavigation, backend, NavigationItem, Sublinks, mobile} from '../../../navigation';
import {TopAppBar} from '@material/mwc-top-app-bar';
import {RequestUpdate} from './events/request-update';
import {BottomAppBarItemClicked} from '../../bottom-app-bar/events/bottom-app-bar-item-clicked';
import '../../../components/alert-message/alert-message';

interface Alerts {
  success: string[];
  error: string[];
}

@customElement('app-shell')
export class AppShell extends LitElement {
  @property({type: String}) public application!: string;
  @property({type: Object}) public attrs: { [key: string]: any } = {};
  @property({type: String, reflect: true}) public theme: string = 'localtunity';
  @property({type: Object}) alerts!: Alerts;
  @property({type: Boolean, reflect: true}) public hideTopAppBar: boolean = false;
  @state() title: string = '';
  @state() businessName: string = '';
  @state() domain: string = '';
  @state() applicationNavigation: ApplicationNavigation = {actions: [], navigation: []};
  @state() private useDrawer: boolean = true;
  @state() private useBottomAppBar: boolean = false;
  @query('mwc-top-app-bar') private topAppBar!: TopAppBar;
  @query('#container') private container!: HTMLDivElement;

  static styles = style;

  constructor() {
    super();

    this.handleRequestUpdate = this.handleRequestUpdate.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.shadowRoot!.addEventListener(RequestUpdate.type, this.handleRequestUpdate);

    if (this.application === 'marketing') {
      this.useDrawer = false;
      this.hideTopAppBar = true;
      this.useBottomAppBar = false;
    }

    if (this.application === 'mobile') {
      this.title = this.attrs['licensee_name'];
      this.useDrawer = false;
      this.hideTopAppBar = true;
      this.useBottomAppBar = true;

      this.applicationNavigation = mobile();
    }

    if (this.application === 'backend') {
      this.theme = this.attrs['theme'];
      this.title = this.attrs['licensee_name'];
      this.businessName = this.attrs['be_logged_in_user_name'];
      this.domain = this.attrs['licensee_name'];
      this.useDrawer = true;
      this.useBottomAppBar = false;
      this.hideTopAppBar = false;

      this.applicationNavigation = backend(this.attrs);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.shadowRoot!.removeEventListener(RequestUpdate.type, this.handleRequestUpdate);
  }

  protected firstUpdated() {
    // if we don't set the scrollTarget to the container, we'll encounter issues such as the bar staying fixed to the
    // top and the bar not reappearing when scrolling down then back up
    this.topAppBar.scrollTarget = this.container;
  }

  private get currentPath(): string {
    return window.location.pathname + window.location.hash;
  }

  private get sublinks(): Sublinks {
    const matchedNavigationItem = this.matchNavigationItem();

    return matchedNavigationItem && matchedNavigationItem.sublinks ? matchedNavigationItem.sublinks : {};
  }

  private matchNavigationItem(): NavigationItem | undefined {
    return this.applicationNavigation.navigation.find((item) => {
      if (item.href === this.currentPath) {
        return true;
      }

      return Object.values(item.sublinks || {}).includes(this.currentPath);
    });
  }

  private handleSublinkActivated(e: CustomEvent): void {
    const href = Object.values(this.sublinks)[e.detail.index];

    // if a new tab was selected, then change the page
    if (href !== this.currentPath) {
      // setting a timeout to give the tab animation a chance to finish
      setTimeout(() => window.location.href = href, 300);
    }
  }

  private handleBottomAppBarItemClicked(event: BottomAppBarItemClicked): void {
    window.location.href = event.href;
  }

  /**
   * We allow any component to request an update to the very top level of the app by dispatching an event.
   */
  private handleRequestUpdate(): void {
    this.requestUpdate();
  }

  private activeSublinkIndex(): number {
    return Object.values(this.sublinks).indexOf(this.currentPath);
  }

  protected render() {
    return html`
      <drawer-content ?contentOnly="${!this.useDrawer}" .activeNavigationItem="${this.matchNavigationItem()}" drawerTitle="${this.businessName}" drawerSubtitle="${this.domain}" .navigation="${this.applicationNavigation.navigation}">
        <div id="container">
          <header id="header">
            <mwc-top-app-bar>
              ${this.useDrawer ? html`
                <mwc-icon-button slot="navigationIcon" icon="menu"></mwc-icon-button>
              ` : ''}
              <div slot="title">${this.title}</div>
              ${this.applicationNavigation.actions.map((item) => html`
                <mwc-icon-button title="${item.label}" icon="${item.graphic}" slot="actionItems" @click="${() => window.location.href = item.href}"></mwc-icon-button>`)}
            </mwc-top-app-bar>

            <mwc-tab-bar id="sublinks-bar" @MDCTabBar:activated="${this.handleSublinkActivated}" activeIndex="${this.activeSublinkIndex()}">
              ${Object.keys(this.sublinks).map((item) => html`
                <mwc-tab label="${item}"></mwc-tab>`)}
            </mwc-tab-bar>
          </header>
          <article id="body">
            <alert-message toast type="success" .messages="${this.alerts.success}"></alert-message>
            <alert-message type="error" .messages="${this.alerts.error}"></alert-message>
            <slot></slot>
          </article>
          <footer id="footer">
            <footer-links>
              <footer-links-item href="#">&copy; 2020 ${this.title}</footer-links-item>
              <footer-links-item href="#">Terms</footer-links-item>
              <footer-links-item href="#">Privacy Policy</footer-links-item>
            </footer-links>
            ${this.useBottomAppBar ? html`
              <bottom-app-bar @bottom-app-bar-item-clicked="${this.handleBottomAppBarItemClicked}" .activeNavigationItem="${this.matchNavigationItem()}" .navigation="${this.applicationNavigation.navigation}"></bottom-app-bar>
            ` : ''}
          </footer>
        </div>
      </drawer-content>
    `;
  }
}
