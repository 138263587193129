import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessCustomFieldForm} from '../../../symfony/forms';
import {BusinessCustomField, BusinessCustomFieldValue} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/typography/body-1';
import '../../../components/typography/heading-3';
import '../../../components/layout/narrow-content';
import '../../../components/editable-section/editable-section';
import '../../../components/layout/content-block';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/button-link/button-link';

@customElement('backend-business-customfield-page')
export class CustomfieldPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "type_id", "name", "required", "show_registration"]|json', type: Object}) private form!: BusinessCustomFieldForm;
  @symfony({variable: '$form', converter: '_call:"isNew":""', type: Boolean}) private isNew: boolean = false;
  @symfony({variable: '$field', converter: '_default/{"id":"","type_id":""}|take:["id", "type_id"]|json', type: Object}) private field!: BusinessCustomField;
  @symfony({variable: '$values', converter: '_default:[]|take:["id", "value"]|json', type: Array}) private values: BusinessCustomFieldValue[] = [];

  connectedCallback() {
    super.connectedCallback();

    // default the selected field type when adding a new field
    if (this.isNew && this.form.type_id.value === '') {
      this.form.type_id.options[0].selected = true;
    }
  }

  protected render() {
    let items: ItemDisplay[] = [
      {'leadingIcon': 'label', 'type': 'text', 'label': 'Field Name', 'name': 'business_custom_field[name]', 'value': this.form.name.value},
    ];

    if (this.isNew) {
      items.push({'leadingIcon': 'label', 'type': 'select', 'label': 'Field Type', 'name': 'business_custom_field[type_id]', 'value': this.form.type_id.value, 'options': this.form.type_id.options});
    }

    items = items.concat([
      {'leadingIcon': 'visibility', 'type': 'switch', 'label': 'Is Required', 'name': 'business_custom_field[required]', 'value': this.form.required.value ? 'on' : 'off'},
      {'leadingIcon': 'visibility', 'type': 'switch', 'label': 'Show On Registration Form', 'name': 'business_custom_field[show_registration]', 'value': this.form.show_registration.value ? 'on' : 'off'},
      {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'business_custom_field[id]', 'value': this.form.id.value},
    ]);

    return html`
<narrow-content centered>
    <editable-section
        heading="Custom Field"
        description=""
        submitName="submit_save"
        .items=${items}>
    </editable-section>
    
    ${this.field && this.field.type_id == '4' ? html`
    <content-block textCenter>
        <heading-3>List Values</heading-3>
        
        ${this.values.length > 0 ? html`
        <data-table fullWidth>
            <data-table-head>
                <data-table-row>
                    <data-table-cell>Value</data-table-cell>
                    <data-table-cell>Edit</data-table-cell>
                    <data-table-cell>Delete</data-table-cell>
                </data-table-row>
            </data-table-head>
            <data-table-body>
                ${this.values.map((value) => html`
                    <data-table-row>
                        <data-table-cell>${value.value}</data-table-cell>
                        <data-table-cell link="/admin/business/customfieldvalue/field/${this.field.id}/id/${value.id}">Edit</data-table-cell>
                        <data-table-cell link="/admin/business/customfieldvaluedelete/id/${value.id}" confirm="Are you sure?">Delete</data-table-cell>
                    </data-table-row>
                `)}
            </data-table-body>
        </data-table>
        ` : html`
        <body-1>You have not added any values to this list</body-1>
        `}
        
        <button-link href="/admin/business/customfieldvalue/field/${this.field.id}" raised>Add Value</button-link>
    </content-block>
    ` : ''}
    
</narrow-content>
`;
  }
}
