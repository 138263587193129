import {html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {FormSubmissionBase} from './form-submission-base';

@customElement('form-submission-hidden')
export class FormSubmissionHidden extends FormSubmissionBase {
  protected get shouldInclude(): boolean {
    return true;
  }

  protected render() {
    return html`<input type="hidden" value="${this.value}">`;
  }
}
