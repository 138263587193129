import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessExtra, LinkableBusinessGallery} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/image-grid/image-grid';
import '../../../components/image-grid/image-grid-item';

@customElement('backend-business-linkablegallery-page')
export class LinkablegalleryPage extends LitElement {
  @symfony({variable: '$business', converter: '_call:"getBusinessExtra"|take:["linkable_business_gallery_title"]|json', type: Object}) private businessExtra!: BusinessExtra;
  @symfony({variable: '$photos', converter: 'take:["id", "business_id", "filename", "link"]|json', type: Array}) private photos: LinkableBusinessGallery[] = [];

  protected render() {
    return html`
<narrow-content>
    <editable-section
        heading="Gallery Title"
        description="Give your gallery a descriptive name."
        submitName="submit_title"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'label', 'type': 'text', 'label': 'Your Gallery Title', 'name': 'gallery_title', 'value': this.businessExtra.linkable_business_gallery_title},
        ]}>
    </editable-section>
    <editable-section
        heading="Upload New Photo"
        description="Image should be at least 600x600 pixels"
        submitName="submit"
        multipart
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'cloud_upload', 'type': 'file', 'label': 'Choose a new photo for your gallery', 'name': 'linkable_business_gallery[filename]', 'value': ''},
            {'leadingIcon': 'link', 'type': 'text', 'label': 'Link', 'name': 'linkable_business_gallery[link]', 'value': ''},
        ]}>
    </editable-section>

</narrow-content>

<image-grid>
    ${this.photos.map((photo) => html`
        <image-grid-item deleteUrl="/admin/business/deletelinkablegalleryimage/image_id/${photo.id}" src="/mobile/images/business/${photo.business_id}/gallery/th${photo.filename}" alt="Gallery Image" footerText="${photo.link}"></image-grid-item>
    `)}
</image-grid>
`;
  }
}
