import {html, LitElement} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import '@material/mwc-button';
import '@material/mwc-list';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-icon';
import {
  Business,
  BusinessDeal,
  BusinessExtra,
  BusinessLocation,
  BusinessReward,
  Consumer,
  MobilePurchase,
} from '../../../symfony/models';
import {symfony} from '../../../symfony/decorators';
import {desktopStyle, style} from './index-page-css';
import {mobile, NavigationItem, Sublinks} from '../../../navigation';
import {AppShell} from '../../../components/layout/app-shell/app-shell';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/hash-content/hash-content';
import '../../../components/carousel/carousel-container';
import '../../../components/carousel/carousel-item';
import '../../../components/section-heading/section-heading';
import '../../../components/button-link/button-link';
import '../../../components/typography/body-1';
import '../../../components/typography/body-2';
import '../../../components/typography/heading-4';
import '../../../components/typography/heading-5';
import '../../../components/typography/heading-6';

enum TabParents {
  Home = 'home',
  Explore = 'explore',
  Rewards = 'rewards',
}

interface ListNavigation {
  tab: TabParents;
  label: string;
  href: string;
  include: boolean;
}

@customElement('mobile-home-index-page')
export class IndexPage extends LitElement {
  @symfony({variable: '$business', converter: 'take:["id", "name", "website", "BusinessLocation.id", "BusinessExtra.google_tour", "slug", "BusinessEvent.id", "BusinessGallery.id", "LinkableBusinessGallery.id", "Partner.id", "Partner.PartnerExtra.allow_rewards", "Partner.PartnerExtra.logo", "BusinessSocialMedia.ecommerce_url", "BusinessProfileMedia.twitter_url", "BusinessProfileMedia.linkedin_url", "BusinessProfileMedia.pinterest_url", "BusinessProfileMedia.amazon_url", "User.stripe_access_token"]|json', type: Object}) private business!: Business;
  @symfony({variable: '$business', converter: '_call:"getAvgRating":""', type: Number}) private businessAvgRating!: number;
  @symfony({variable: '$business', converter: '_call:"shouldShowNoDealsButton":""', type: Boolean}) private shouldShowNoDealsButton: boolean = false;
  @symfony({variable: '$business_extra', converter: 'take:["id", "show_reviews_tab", "show_testimony_tab", "show_membership_card", "business_id", "show_videomessaging_tab", "show_socialvideo_tab", "wifi_password", "web_cover", "web_cover_custom"]|json', type: Object}) private businessExtra!: BusinessExtra;
  @symfony({variable: '$location', converter: 'take:["id", "address_one", "city", "State.initials", "zip_code", "phone_one"]|json', type: Object}) private location!: BusinessLocation;
  @symfony({variable: '$consumer', converter: '_default/{"id":""}|take:["id"]|json', type: Object}) private consumer!: Consumer;
  @symfony({variable: '$mobilePurchases', converter: '_default:[]|take:["id"]|json', type: Array}) private mobilePurchases: MobilePurchase[] = [];
  @symfony({variable: '$sharing_rewards', converter: 'take:["id"]|json', type: Array}) private sharingRewards: BusinessReward[] = [];
  @symfony({variable: '$deals', converter: 'take:["id"]|json', type: Array}) private deals: BusinessDeal[] = [];
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"business_logo"', type: String}) private businessLogo!: string;
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"domain_partner"', type: String}) private domainPartner!: string;
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"domain_partner_logo"', type: String}) private domainPartnerLogo!: string;
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_facebook_app_id"', type: String}) private facebookAppId?: string;
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_mobile_url"', type: String}) private mobileUrl?: string;
  @symfony({variable: '$cashBackNowOffers', converter: 'take:["id"]|json', type: Array}) private cashBackNowOffers: BusinessReward[] = [];
  @symfony({variable: '$rewards', converter: 'take:["id"]|json', type: Array}) private rewards: BusinessReward[] = [];
  @symfony({variable: '$hasSpendtunity', converter: '', type: Boolean}) private hasSpendtunity: boolean = false;
  @property({type: String, reflect: true}) public hash: TabParents = TabParents.Home;
  private listNavigation: ListNavigation[] = [];

  static styles = [style, desktopStyle];

  constructor() {
    super();

    this.updateHash = this.updateHash.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    // we don't want the sublinks tab bar to show for any of the 3 index "pages"
    (document.querySelector('app-shell') as AppShell).style.setProperty('--sublinks-bar-height', '0px');

    window.addEventListener('hashchange', this.updateHash);
    this.updateHash();

    this.setupNavigation();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('hashchange', this.updateHash);
  }

  private setupNavigation(): void {
    const navigation = mobile();
    const exploreSublinks = (<NavigationItem>navigation.navigation.find((item) => item.label === 'Explore')).sublinks!;
    const rewardsSublinks = (<NavigationItem>navigation.navigation.find((item) => item.label === 'Rewards')).sublinks!;

    // TODO do this in a better way than matching on the (easily changeable) label
    const routesMap: { [key: string]: boolean } = {};
    routesMap['Rewards & Coupons'] = Boolean(this.business.Partner.PartnerExtra.allow_rewards && this.consumer.id);
    routesMap['Daily Deal'] = this.mobilePurchases.length > 0;
    routesMap['Social Rewards'] = this.sharingRewards.length > 0;
    routesMap['Happy Birthday! Claim your gift!'] = false;
    routesMap['Todays Deals'] = this.deals.length > 0;
    routesMap['Get Cash Back Now!'] = this.cashBackNowOffers.length > 0;
    routesMap['Get Punch Cards'] = this.rewards.length > 0;
    routesMap['Events'] = this.business.BusinessEvent.length > 0;
    routesMap['VIP Discount Card'] = this.businessExtra.show_membership_card;
    routesMap['My Cash Card'] = false;
    routesMap['Spendtunity'] = this.hasSpendtunity;
    routesMap['360 See Inside Tour'] = Boolean(this.business.BusinessExtra.google_tour);
    routesMap['Loyalty Game'] = this.businessExtra.business_id == 5569664;
    routesMap['Reviews & Feedback'] = this.businessExtra.show_reviews_tab;
    routesMap['Comment Camera'] = this.businessExtra.show_testimony_tab;
    routesMap['Video Messaging'] = this.businessExtra.show_videomessaging_tab;
    routesMap['10 Mins. of Fame'] = this.businessExtra.show_socialvideo_tab;
    routesMap['See Photos'] = this.business.BusinessGallery.length > 0;
    routesMap['Media'] = this.business.LinkableBusinessGallery.length > 0;
    routesMap['Contact & Directions'] = true;
    routesMap['Wifi Access'] = Boolean(this.businessExtra.wifi_password);
    routesMap['My Profile'] = true;
    routesMap['Make a Mobile Payment'] = Boolean(this.business.User.stripe_access_token);

    // map of route label to the boolean indicating if we want to include it
    const includeRoutes = new Map<string, boolean>(Object.entries(routesMap));

    const exploreItems = this.sublinksToListNavigations(TabParents.Explore, exploreSublinks, includeRoutes);
    const rewardsItems = this.sublinksToListNavigations(TabParents.Rewards, rewardsSublinks, includeRoutes);

    this.listNavigation = exploreItems.concat(rewardsItems).filter((nav) => nav.include);
  }

  private sublinksToListNavigations(tab: TabParents, sublinks: Sublinks, includeRoutes: Map<string, boolean>): ListNavigation[] {
    const items: ListNavigation[] = [];

    for (const [label, href] of Object.entries(sublinks)) {
      // don't include any sublinks that we have not told to be included
      if (!includeRoutes.has(label)) {
        continue;
      }

      items.push({tab, label, href, include: includeRoutes.get(label) as boolean});
    }

    return items;
  }

  private updateHash(): void {
    this.hash = window.location.hash.replace('#', '') as TabParents;
  }

  private handleListNavigation(tabParent: TabParents, index: number): void {
    const nav = this.listNavigation.filter((nav) => nav.tab === tabParent)[index];

    window.location.href = nav.href;
  }

  protected render() {
    return html`

      <div class="top-section-desktop">
        <div class="cover-section">
          <div class="cover-image-container">
            ${this.businessExtra.web_cover_custom ? html`
              <img class="cover-image" src="/mobile/images/business/${this.business.id}/${this.businessExtra.web_cover_custom}" alt="">
            ` : html`
              <img class="cover-image" src="/mobile/images/covers/${this.businessExtra.web_cover}.jpg" alt="">
            `}
          </div>
          <div class="review-grade">
            <img class="review-grade__partner-image" src="/images/licensee/${this.business.Partner.id}/${this.business.Partner.PartnerExtra.logo}" alt="">
            <button-link raised fullWidth href="#">See Reviews</button-link>
          </div>
        </div>
        <img class="app-logo" src="/mobile/images/business/${this.business.id}/${this.businessLogo}"/>
        <div class="titlebar-section">
          <heading-6 class="titlebar-section__title">${this.business.name}</heading-6>
          <div class="titlebar-section__share">
            <body-1>Share This Loyalty Program</body-1>
            <a href="https://www.facebook.com/dialog/feed?app_id=${this.facebookAppId}&link=${this.mobileUrl}/${this.business.slug}&redirect_uri=${this.mobileUrl}/${this.business.slug}"><img src="/mobile/images/icon-facebook.png" alt="Facebook"></a>
            <a href="https://twitter.com/intent/tweet?url=${this.mobileUrl}/${this.business.slug}"><img src="/mobile/images/icon-twitter.png" alt="Twitter"></a>
          </div>
        </div>
        <div class="navigation-section">
          <body-2 class="navigation-section__link">Home</body-2>
          <body-2 class="navigation-section__link">Merchant View 360</body-2>
          <body-2 class="navigation-section__link">Reviews</body-2>
          <body-2 class="navigation-section__link">Testimonials</body-2>
          <body-2 class="navigation-section__link">Join Us</body-2>
          <body-2 class="navigation-section__link">Preview Offers</body-2>
        </div>
      </div>

      <div class="top-section">
        <div class="top-section-artwork">
          <img src="/mobile/images/business/${this.business.id}/${this.businessLogo}"/>
        </div>
        <div class="top-section-information">
          <div class="top-section-information__extra-info">
            <heading-4>${this.business.name}</heading-4>
            <heading-5>${this.location.address_one}, ${this.location.city}, ${this.location.State.initials} ${this.location.zip_code}</heading-5>
            <p>Average Rating: ${this.businessAvgRating}</p>
            <ul>
              <li>Phone: ${this.location.phone_one}</li>
              <li>WiFi Access: No</li>
              <li>Categories: Auto Dealerships</li>
              <li>Website: ${this.business.website}</li>
            </ul>
          </div>
          <div class="top-section-information__buttons">
            <mwc-button raised label="Call"></mwc-button>
            <mwc-button raised label="Directions"></mwc-button>
          </div>
        </div>
        <div class="top-section-gradient"></div>
      </div>
      <div class="bottom-section">

        <hash-content hash="home" updateEmptyHash>
          <section-heading heading="Explore" linkText="See all" linkHref="${window.location.pathname}#explore"></section-heading>
          <div class="explore-section">
            <carousel-container>
              <carousel-item>
                <body-1>Reviews & Feedback</body-1>
              </carousel-item>
              <carousel-item>
                <body-1>Contact & Locations</body-1>
              </carousel-item>
              <carousel-item>
                <body-1>360 Inside Tour</body-1>
              </carousel-item>
            </carousel-container>
          </div>
          <section-heading heading="Rewards" linkText="See all" linkHref="${window.location.pathname}#rewards"></section-heading>
          <div class="rewards-section">
            <carousel-container>
              <carousel-item>
                <body-1>Get Cash Back Now!</body-1>
              </carousel-item>
              <carousel-item>
                <body-1>Punch Card</body-1>
              </carousel-item>
              <carousel-item>
                <body-1>Discounts</body-1>
              </carousel-item>
            </carousel-container>
          </div>
        </hash-content>

        <hash-content hash="explore">
          <prominent-notice
            image="/assets/images/undraw_Detailed_information_re_qmuc.svg"
            heading="Explore ${this.business.name}"
            subtitle="How about giving us a review while your here!">
          </prominent-notice>
          <div class="list-navigation-section">
            <mwc-list @selected="${(e: CustomEvent) => this.handleListNavigation(TabParents.Explore, e.detail.index)}">
              ${this.listNavigation.filter((nav) => nav.tab === TabParents.Explore).map((nav) => html`
                <mwc-list-item class="list-navigation-item" hasMeta>
                  <span>${nav.label}</span>
                  <mwc-icon slot="meta">navigate_next</mwc-icon>
                </mwc-list-item>
                <li divider role="separator"></li>
              `)}
            </mwc-list>
          </div>
        </hash-content>

        <hash-content hash="rewards">
          <prominent-notice
            image="/assets/images/undraw_business_shop_qw5t.svg"
            heading="${this.business.name} Rewards"
            subtitle="Shop. Save. Simple!">
          </prominent-notice>
          <div class="list-navigation-section">
            <mwc-list @selected="${(e: CustomEvent) => this.handleListNavigation(TabParents.Rewards, e.detail.index)}">
              ${this.listNavigation.filter((nav) => nav.tab === TabParents.Rewards).map((nav) => html`
                <mwc-list-item class="list-navigation-item" hasMeta>
                  <span>${nav.label}</span>
                  <mwc-icon slot="meta">navigate_next</mwc-icon>
                </mwc-list-item>
                <li divider role="separator"></li>
              `)}
            </mwc-list>
          </div>
        </hash-content>

      </div>


    `;
  }
}
