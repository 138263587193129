import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {SocialVideo} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/review/review-display';
import '../../../components/review/review-display-action';

@customElement('backend-business-managesocialvideos-page')
export class ManagesocialvideosPage extends LitElement {
  @symfony({variable: '$socialVideos', converter: 'take:["id", "media_url", "Consumer.consumer_feedback_header", "approved", "created_at"]|json', type: Array}) private socialVideos: SocialVideo[] = [];

  protected render() {
    return html`
${this.socialVideos.length == 0 ? html`
<prominent-notice
        large
        image="/assets/images/undraw_videographer_nnc7.svg"
        heading="Manage 10 Mins. of Fame"
        subtitle="No content at this time.">
</prominent-notice>
` : ''}

<flex-column-container gap="10" style="justify-content: flex-start;">
    ${this.socialVideos.map((video) => html`
        <flex-column flex="initial" style="max-width: 500px;">
            <review-display
                    media="${video.media_url}"
                    reviewerName="${video.Consumer.consumer_feedback_header}"
                    reviewDate="${date(video.created_at)}">
                <review-display-action slot="actions" href="/admin/business/postsocialvideotoyoutube/id/${video.id}" label="Post To YouTube" confirm="Are you sure you want to post this video to YouTube?"></review-display-action>
                <review-display-action slot="actions" href="/admin/business/deletesocialvideo/id/${video.id}" label="Delete" confirm="Are you sure you want to delete this video?"></review-display-action>
                
                ${!video.approved ? html`
                <review-display-action slot="actions" primary href="/admin/business/approvesocialvideo/id/${video.id}" label="Approve"></review-display-action>
                ` : ''}
            </review-display>
        </flex-column>
    `)}
</flex-column-container>
`;
  }
}
