import {css, html, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {Chart} from 'chart.js';
import {hex2Rgba} from '../../helpers/formatters/hex-to-rgba';

interface LineChartDataPoints {
  [key: string]: string;
}

@customElement('line-chart')
export class LineChart extends LitElement {
  @property({type: String}) public dataLabel: string = '';
  @property({type: Object}) public datapoints!: LineChartDataPoints;
  @query('canvas') private canvas!: HTMLCanvasElement;
  private chart?: Chart;

  static styles = css`
:host {
    display: block;
}
`;

  private get _labels(): string[] {
    return Object.keys(this.datapoints);
  }

  private get _data(): number[] {
    return Object.values(this.datapoints).map((v) => +v);
  }

  // private get _min(): number {
  //     return Math.min(...this._data) - 5;
  // }
  //
  // private get _max(): number {
  //     return Math.max(...this._data) + 5;
  // }

  protected firstUpdated() {
    const themePrimaryColor = getComputedStyle(document.querySelector('app-shell')!).getPropertyValue('--primary-color');

    const primaryRgba = hex2Rgba(themePrimaryColor);

    this.chart = new Chart(this.canvas, {
      type: 'line',
      data: {
        labels: this._labels,
        datasets: [{
          label: this.dataLabel,
          data: this._data,
          backgroundColor: primaryRgba,
          borderColor: primaryRgba,
          pointBackgroundColor: primaryRgba,
          fill: true,
          pointRadius: 2,
          pointHitRadius: 3,
          borderWidth: 1,
        }],
      },
      options: {
        elements: {
          // line: {
          //     tension: 0,
          // },
        },
        scales: {
          yAxes: [{
            ticks: {
              // min: this._min,
              // max: this._max,
              // stepSize: 1,
              beginAtZero: true,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
        },
      },
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.chart?.destroy();
  }

  protected render() {
    return html`
<canvas></canvas>
`;
  }
}
