import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '@material/mwc-button';

@customElement('thumbnail-image')
export class ThumbnailImage extends LitElement {
  @property({type: String}) public src: string = '';
  @property({type: Boolean}) public large: boolean = false;
  @property({type: String}) public deleteUrl: string = '';

  static styles = css`
:host {
    display: flex;
    width: 100px;
    height: 100px;
    position: relative;
    float: right;
}
:host([large]) {
    width: 200px;
    height: 200px;
}
img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: none;
}
:host(:hover) .overlay  {
    display: flex;
}
mwc-button {
  --mdc-theme-primary: #b00020;
  --mdc-theme-on-primary: white;
}
`;

  private handleDeleteClicked() {
    if (window.confirm('Are you sure you want to delete this image?')) {
      window.location.href = this.deleteUrl;
    }
  }

  protected render() {
    return html`
<img src="${this.src}" />

${this.deleteUrl !== '' ? html`
<div class="overlay"><mwc-button raised label="Delete" @click="${this.handleDeleteClicked}"></mwc-button></div>
` : ''}
`;
  }
}
