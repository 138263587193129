import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {UserForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';

@customElement('backend-business-usersadd-page')
export class UsersaddPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "first_name", "last_name", "email", "password"]|json', type: Object}) private form!: UserForm;

  protected render() {
    return html`
<narrow-content centered>
    <editable-section
        heading="Add User"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'account_circle', 'type': 'combination', 'label': 'Name', 'value': `${this.form.first_name.value} ${this.form.last_name.value}`, 'inputs': [
                {'type': 'text', 'label': 'First Name', 'name': 'user[first_name]', 'value': this.form.first_name.value},
                {'type': 'text', 'label': 'Last Name', 'name': 'user[last_name]', 'value': this.form.last_name.value},
            ]},
            {'leadingIcon': 'email', 'type': 'text', 'label': 'Email', 'name': 'user[email]', 'value': this.form.email.value},
            {'leadingIcon': 'lock', 'type': 'password', 'label': 'Password', 'name': 'user[password]', 'value': this.form.password.value},
            {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'user[id]', 'value': this.form.id.value},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
