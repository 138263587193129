import {customElement} from 'lit/decorators.js';
import {BaseTypography} from './base-typography';

@customElement('heading-4')
export class Heading4 extends BaseTypography {
  protected mdcTypography(): string {
    return 'headline4';
  }

  protected defaultTag(): string {
    return 'h4';
  }
}
