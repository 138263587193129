import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('qr-code')
export class QrCode extends LitElement {
  @property({type: Boolean}) public fullurl: boolean = false;
  @property({type: String}) public link: string = '';
  @property({type: String}) public track: string = '';
  @property({type: Number}) public size: number = 125;

  static styles = css`
:host {
    text-align: center;
}
`;

  private getUrl(): string {
    if (this.fullurl) {
      return this.link;
    }

    const size = `${this.size}x${this.size}`;

    const base = `https://api.qrserver.com/v1/create-qr-code/?size=${size}&data=${encodeURIComponent(this.link)}`;

    if (this.track !== '') {
      return base + `?track=${this.track}`;
    }

    return base;
  }

  protected render() {
    return html`
            <img src="${this.getUrl()}" alt="QR Code" />
`;
  }
}
