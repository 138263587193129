import {css} from 'lit';

// no idea why, but because the top-app-bar has position:fixed it causes the right icons to disappear off-screen
// setting this transform will cause it to ignore the viewport as the containing block https://developer.mozilla.org/en-US/docs/Web/CSS/position#fixed

export const style = css`
:host {
    --top-app-bar-height: 64px;
    --sublinks-bar-height: 48px;
    --default-primary-color: #296d6f;
    //--default-primary-color: #eb9540;
    --primary-color: var(--default-primary-color);
    --secondary-color: var(--primary-color);
    --text-secondary-on-light: rgba(0, 0, 0, 0.54);
    --mdc-theme-primary: var(--primary-color);
    --mdc-theme-secondary: var(--secondary-color);
    --mdc-theme-on-primary: #ffffff;
    --mdc-theme-text-secondary-on-light: var(--text-secondary-on-light);
}
:host([theme="theme-localtunity-green"]) {
    --primary-color: var(--default-primary-color);
    --secondary-color: var(--default-primary-color);
}
:host([theme="theme-cherry-hill-red"]) {
    --primary-color: #ec1b22;
    --secondary-color: var(--primary-color);
}
:host([hideTopAppBar]) mwc-top-app-bar {
    display: none;
}
:host([application="backend"]) #body {
    padding: 15px;
}
:host([application="mobile"]) footer-links {
    display: none;
}
#container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#sublinks-bar {
    --mdc-tab-height: var(--sublinks-bar-height);
}
#body {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-image: url("/assets/images/banner_background.svg");
    background-position: top;
    background-repeat: no-repeat;
}
`;
