import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '../../components/button-link/button-link';

@customElement('review-display-action')
export class ReviewDisplayAction extends LitElement {
  @property({type: String}) public href: string = '';
  @property({type: String}) public label: string = '';
  @property({type: String}) public confirm: string = '';
  @property({type: Boolean}) public primary: boolean = false;

  protected render() {
    return html`
<button-link href="${this.href}" confirm="${this.confirm}" ?raised="${this.primary}">${this.label}</button-link>
`;
  }
}
