import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessForm, UserForm} from '../../../symfony/forms';
import {Keyword, BusinessLocation, User} from '../../../symfony/models';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/typography/body-1';
import '../../../components/typography/body-2';
import '../../../components/typography/heading-6';
import '../../../components/layout/narrow-content';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/editable-section/editable-sections-wrapper';
import '../../../components/editable-section/editable-section';
import '../../../components/editable-section/editable-section-item-display';
import '../../../components/account/account-location';
import '../../../components/open-dialog-button/open-dialog-button';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-hidden';
import '../../../components/form-submission/fields/form-submission-submit';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/layout/content-block';
import '../../../components/button-link/button-link';

@customElement('backend-business-account-page')
export class AccountPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "first_name", "last_name", "email"]|json', type: Object}) private userForm?: UserForm;
  @symfony({variable: '$locations', converter: 'take:["id", "name", "phone_one", "address_one", "city", "State.initials", "zip_code"]|json', type: Array}) private locations: BusinessLocation[] = [];
  @symfony({variable: '$keywords', converter: 'take:["id", "name"]|json', type: Array}) private keywords: Keyword[] = [];
  @symfony({variable: '$primaryKeyword', converter: '_default/{"id":"","name":""}|take:["id", "name"]|json', type: Object}) private primaryKeyword?: Keyword;
  @symfony({variable: '$form_business_notifications', converter: 'form_fields:["category_one_id", "category_two_id", "category_three_id", "language_id", "timezone", "notification_weekly", "is_nearby", "is_multi_location", "status_id"]|json', type: Object}) private businessNotificationsForm?: BusinessForm;
  @symfony({variable: '$user', converter: 'take:["stripe_access_token"]|json', type: Object}) private user?: User;
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_stripe_client_id"', type: String}) private stripeClientid?: string;

  protected render() {
    return html`
      <narrow-content>
        <flex-column-container align="flex-start" columnForMobile>
          <flex-column>
            <editable-sections-wrapper>
              <editable-section
                heading="Account Information"
                headingImage="/assets/images/undraw_profile_6l1l.svg"
                headingImagePosition="center"
                description="Here you can update your email and/or password that you use to login."
                submitName="submit_save"
                .items=${<ItemDisplay[]>[
                    {'leadingIcon': 'account_circle', 'type': 'combination', 'label': 'Name', 'value': `${this.userForm?.first_name.value} ${this.userForm?.last_name.value}`, 'inputs': [
                        {'type': 'text', 'label': 'First Name', 'name': 'user[first_name]', 'value': this.userForm?.first_name.value},
                        {'type': 'text', 'label': 'Last Name', 'name': 'user[last_name]', 'value': this.userForm?.last_name.value},
                    ]},
                    {'leadingIcon': 'alternate_email', 'type': 'text', 'label': 'Email', 'name': 'user[email]', 'value': this.userForm?.email.value},
                    {'leadingIcon': 'lock', 'type': 'combination', 'label': 'Password', 'value': '********', 'inputs': [
                        {'type': 'password', 'label': 'Password', 'name': 'user[password]', 'value': ''},
                        {'type': 'password', 'label': 'Confirm Password', 'name': 'user[password_confirm]', 'value': ''},
                    ]},
                    {'leadingIcon': '', 'type': 'hidden', 'label': '', 'name': 'user[id]', 'value': this.userForm?.id.value},
                ]}>
              </editable-section>

              <editable-section
                heading="Locations"
                description="List all your locations so that local customers will be able to find you when browsing listings."
                submitName="locations"
                individualEdits>
                ${this.locations.length > 0 ? html`
                    ${this.locations.map((location) => html`
                      <account-location
                        name="${location.name}"
                        phone="${location.phone_one}"
                        address="${location.address_one}, ${location.city}, ${location.State.initials} ${location.zip_code}"
                        editHref="/admin/business/locationsedit/id/${location.id}"
                        deleteHref="/admin/business/locationsdelete/id/${location.id}"
                        ?showDelete="${this.locations.length > 1}"
                      >
                      </account-location>
                    `)}
                    <button-link href="/admin/business/locationsadd">Add More</button-link>
                  ` : html`
                    <body-1>There are no locations setup.</body-1>
                  `}
              </editable-section>

              <editable-section
                heading="Categories"
                description="Categories help us determine what type of business you have and where to show it."
                submitName="submit_save_notifications"
                .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'looks_one', 'type': 'select', 'label': 'Primary Category (required)', 'name': 'business[category_one_id]', 'value': this.businessNotificationsForm?.category_one_id.value, 'options': this.businessNotificationsForm?.category_one_id.options},
                        {'leadingIcon': 'looks_two', 'type': 'select', 'label': 'Category Two (optional)', 'name': 'business[category_two_id]', 'value': this.businessNotificationsForm?.category_two_id.value, 'options': this.businessNotificationsForm?.category_two_id.options},
                        {'leadingIcon': 'looks_3', 'type': 'select', 'label': 'Category Three (options)', 'name': 'business[category_three_id]', 'value': this.businessNotificationsForm?.category_three_id.value, 'options': this.businessNotificationsForm?.category_three_id.options},
                    ]}>
              </editable-section>

              <editable-section
                individualEdits
                heading="Keywords"
                description="We recommend you add at least 3 searchable keywords that are related to your listing."
                submitName="submit_add_keyword"
                action="/admin/business/addkeyword">

                ${this.keywords.map((keyword) => html`
                  <flex-column-container>
                    <flex-column>
                      <editable-section-item-display leadingIcon="label_important" type="text" label="" name="" value="${keyword.name}${this.primaryKeyword?.id === keyword.id ? ' (Primary)' : ''}"></editable-section-item-display>
                    </flex-column>
                    <flex-column flex="initial">
                      <open-dialog-button label="Edit">
                        <heading-6>${keyword.name}</heading-6>

                        ${this.primaryKeyword?.id !== keyword.id ? html`
                          <form-submission action="/admin/business/setMainKeyword">
                            <form-submission-hidden name="keyword_id" value="${keyword.id}"></form-submission-hidden>
                            <form-submission-submit name="submit_set_main_keyword" value="Set As Primary"></form-submission-submit>
                          </form-submission>
                        ` : ''}

                        <br>

                        <form-submission action="/admin/business/deleteKeyword">
                          <form-submission-hidden name="keyword_id" value="${keyword.id}"></form-submission-hidden>
                          <form-submission-submit name="submit_delete_keyword" value="Delete"></form-submission-submit>
                        </form-submission>
                      </open-dialog-button>
                    </flex-column>
                  </flex-column-container>
                `)}

                <open-dialog-button label="Add More">
                  <body-1>We recommend you add at least 3 searchable keywords that are related to your listing.</body-1>
                  <br>
                  <form-submission action="/admin/business/addkeyword">
                    <form-submission-textfield label="Keyword" name="name"></form-submission-textfield>
                    <content-block centered>
                      <form-submission-submit name="submit_add_keyword" value="Save"></form-submission-submit>
                    </content-block>
                  </form-submission>
                </open-dialog-button>
              </editable-section>

              <editable-section
                heading="Open for Business?"
                description="Indicates whether this business is temporarily or permanently closed, or remains open for business."
                submitName="submit_save_notifications"
                .items=${<ItemDisplay[]>[
                  {'leadingIcon': 'storefront', 'type': 'select', 'label': 'Status', 'name': 'business[status_id]', 'value': this.businessNotificationsForm?.status_id.value, 'options': this.businessNotificationsForm?.status_id.options},
                ]}>
              </editable-section>
            </editable-sections-wrapper>


          </flex-column>

          <flex-column>
            <editable-section
              noBorder
              heading="Language & Timezone"
              description="Here you can set the default language and timezone settings for your business."
              submitName="submit_save_notifications"
              .items=${<ItemDisplay[]>[
                    {'leadingIcon': 'language', 'type': 'select', 'label': 'Language', 'name': 'business[language_id]', 'value': this.businessNotificationsForm?.language_id.value, 'options': this.businessNotificationsForm?.language_id.options},
                    {'leadingIcon': 'access_time', 'type': 'select', 'label': 'Timezone', 'name': 'business[timezone]', 'value': this.businessNotificationsForm?.timezone.value, 'options': this.businessNotificationsForm?.timezone.options},
                ]}>
            </editable-section>

            <editable-section
              noBorder
              heading="Notifications"
              submitName="submit_save_notifications"
              .items=${<ItemDisplay[]>[
                    {'leadingIcon': 'email', 'type': 'switch', 'label': 'Receive a weekly email update of your app activity.', 'name': 'business[notification_weekly]', 'value': this.businessNotificationsForm?.notification_weekly.value ? 'on' : 'off'},
                ]}>
            </editable-section>

            <editable-section
              noBorder
              heading="Set as Neighboring Business"
              description="For home-based businesses, this gives the option to hide residential addresses."
              submitName="submit_save_notifications"
              .items=${<ItemDisplay[]>[
                    {'leadingIcon': 'domain', 'type': 'switch', 'label': 'Is Nearby', 'name': 'business[is_nearby]', 'value': this.businessNotificationsForm?.is_nearby.value ? 'on' : 'off'},
                ]}>
            </editable-section>

            <editable-section
              noBorder
              heading="Set as Multi-Location Business"
              description="For corporate businesses that have locations with the same phone number as the primary location. This also changes how the business is displayed on the marketing pages of all Localtunity Networks. When on, secondary locations closest to the user will be presented as the primary location for the app. Directions, Call, and Address information will all reflect whichever location is closest to the user."
              submitName="submit_save_notifications"
              .items=${<ItemDisplay[]>[
                    {'leadingIcon': 'domain', 'type': 'switch', 'label': 'Is Multi-Location', 'name': 'business[is_multi_location]', 'value': this.businessNotificationsForm?.is_multi_location.value ? 'on' : 'off'},
                ]}>
            </editable-section>

            <editable-section
              noBorder
              heading="Connect Payment Accounts"
              description="To receive payments online, connect your preferred payment method."
              submitName="connect_payment_accounts"
              individualEdits>

              <content-block>
                ${this.user?.stripe_access_token ? html`
                  <body-1>Stripe account is connected</body-1>
                ` : ''}
                <button-link href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${this.stripeClientid}&scope=read_write" outlined fullWidth>${this.user?.stripe_access_token ? 'Change Account' : 'Connect with Stripe'}</button-link>
              </content-block>

            </editable-section>

            <editable-section
              noBorder
              heading="Cancel Account"
              submitName="cancel_account"
              individualEdits>
              <body-2>
                If you cancel your account, your listing will no longer be available. Once you cancel your account you will immediately lose access to your listing panel.
              </body-2>
              <button-link confirm="Are you sure you want to cancel your account?" href="/admin/business/cancel" outlined>Cancel Account</button-link>
            </editable-section>

          </flex-column>
        </flex-column-container>
      </narrow-content>
    `;
  }
}
