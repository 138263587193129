import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Business, Category, Partner} from '../../../symfony/models';
import {Pager} from '../../../symfony/built-ins';
import {SelectOption} from '../../../components/form-submission/fields/form-submission-select';
import '@material/mwc-icon';
import '../../../components/layout/content-block';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/form-submission/fields/form-submission-submit';

@customElement('backend-partner-index-page')
export class IndexPage extends LitElement {
  @symfony({variable: '$businesses', converter: 'take:["id", "name", "Partner.id", "Partner.domain", "User.UserStatus.name"]|json', type: Array}) private businesses: Business[] = [];
  @symfony({variable: '$categories', converter: 'take:["id", "name"]|json', type: Array}) private categories: Category[] = [];
  @symfony({variable: '$categoryId', type: String}) private categoryId: string = '';
  @symfony({variable: '$partner', converter: 'take:["id"]|json', type: Object}) private partner!: Partner;
  @symfony({variable: '$pager', converter: 'pager|json', type: Object}) private pager!: Pager;

  static styles = css`
:host {
    display: block;
}
`;

  private categoryOptions(): SelectOption[] {
    return this.categories.map((category) => ({
      key: String(category.id),
      label: category.name,
      selected: String(category.id) === this.categoryId,
    }));
  }

  protected render() {
    return html`
<content-block>
    <form-submission>
        <flex-column-container gap="10">
            <flex-column flex="1">
                <form-submission-textfield fullWidth name="search" label="Search Businesses" value=""></form-submission-textfield>
            </flex-column>
            <flex-column flex="1">
                <form-submission-select fullWidth label="Category" name="category" value="" .options='${this.categoryOptions()}'></form-submission-select>
            </flex-column>
            <flex-column flex="initial">
                <form-submission-submit value="Search"></form-submission-submit>
            </flex-column>
        </flex-column-container>
    </form-submission>
</content-block>

<data-table fullWidth .pager="${this.pager}">
    <data-table-head>
        <data-table-row>
            <data-table-cell>Name</data-table-cell>
            <data-table-cell>Domain</data-table-cell>
            <data-table-cell>Status</data-table-cell>
            <data-table-cell>Settings</data-table-cell>
            <data-table-cell>Login</data-table-cell>
            <data-table-cell>Delete</data-table-cell>
        </data-table-row>
    </data-table-head>
    <data-table-body>
        ${this.businesses.map((business) => html`
        <data-table-row>
            <data-table-cell>${business.name}</data-table-cell>
            <data-table-cell>${business.Partner.domain}</data-table-cell>
            <data-table-cell>${business.User.UserStatus.name}</data-table-cell>
            ${business.Partner.id === this.partner.id ? html`
                <data-table-cell link="/admin/partner/businesssettings/id/${business.id}">Edit</data-table-cell>
            ` : html`
                <data-table-cell></data-table-cell>
            `}
            <data-table-cell link="/admin/user/businesslogin/id/${business.id}">Login</data-table-cell>
            <data-table-cell confirm="Are you sure you want to delete this business? This will delete all programs as well." link="/admin/partner/deletebusiness/id/${business.id}"><mwc-icon>delete</mwc-icon></data-table-cell>
        </data-table-row>
        `)}
    </data-table-body>
</data-table>
`;
  }
}
