import {css, html, LitElement, unsafeCSS} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import birthdayPattern from './birthday-club-pattern.png';
import '../virtual-card';

@customElement('spendtunity-card')
export class SpendtunityCard extends LitElement {
  @property({type: String}) public backgroundColor: string = '';
  @property({type: String}) public logoUrl: string = '';
  @property({type: String}) public reward: string = '';
  @property({type: String}) public condition: string = '';

  static styles = css`
:host {
    border-radius: 20px;
    font-weight: bold;
    color: #fff;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-family: sans-serif;
    text-align: center;
    font-size: 16px;
}
virtual-card {
    display: flex;
    flex-direction: column;
    background-image: url("${unsafeCSS(`/assets/js/${birthdayPattern}`)}");
    background-repeat: repeat;
    background-size: 370px;
}
.top-section {
    display: flex;
    align-items: center;
}
.bottom-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.logo {
    width: 80px;
    border-radius: 40px;
    margin: 12px 25px;
}
.reward {
    font-size: 25px;
    flex: 1;
}
.customer {
    font-size: 25px;
}
.end-date {
    font-size: 10px;
}
.condition {
    background-color: rgba(0, 0, 0, .3);
    line-height: 33px;
    border-top: 2px solid rgba(0, 0, 0, .2);
}
`;

  protected render() {
    const bgColor = this.backgroundColor || '#296d6f';
    const rewardText = this.reward || 'X';
    const conditionText = this.condition || 'Spend X Amount';

    return html`
<style>
:host {
    background-color: ${bgColor};
}
</style>

<virtual-card>
    <div class="top-section">
        <img class="logo" src="${this.logoUrl}" />
        <div class="reward">Get ${rewardText} OFF!</div>
    </div>
    <div class="bottom-section">
        <div class="customer">Your Customer</div>
        <div class="end-date">Valid Through: 20XX-XX-XX</div>
        <div class="condition">${conditionText}</div>
        <div class="member-id">Membership ID: #123456</div>
    </div>
</virtual-card>
`;
  }
}
