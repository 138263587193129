import {css, html, LitElement, unsafeCSS} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {MDCDataTable} from '@material/data-table';
import '@material/mwc-checkbox';
import tableStyles from '@material/data-table/dist/mdc.data-table.css';
import {Pager} from '../../symfony/built-ins';
import '../../components/button-link/button-link';


@customElement('data-table')
export class DataTable extends LitElement {
  @property({type: Object}) public pager?: Pager;
  @property({type: Boolean}) public textCentered: boolean = false;
  @property({type: Boolean}) public fullWidth: boolean = false;

  static styles = [unsafeCSS(tableStyles), css`
:host {
    display: block;
    max-width: 100%;
}
.data-table--centered, .data-table--centered .mdc-data-table__header-cell {
    text-align: center;
}
.data-table--full-width {
    width: 100%;
}
.data-table__cell--link {
    cursor: pointer;
    color: blue;
}
.data-table__pagination {
    align-items: center;
}
`];

  @query('.mdc-data-table') private mdcRoot!: HTMLElement;
  private mdcComponent!: MDCDataTable;

  protected firstUpdated(): void {
    this.mdcComponent = new MDCDataTable(this.mdcRoot);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.mdcComponent.destroy();
  }

  private handleLinkClick(link: string, confirm?: string): void {
    let confirmed = confirm === '';

    if (!confirmed && window.confirm(confirm)) {
      confirmed = true;
    }

    if (confirmed && link !== '') {
      window.location.href = link;
    }
  }

  private get perPage(): number {
    return this.pager?.maxPerPage || -1;
  }

  private get currentPage(): number {
    return this.pager?.page || 1;
  }

  private get totalResults(): number {
    return this.pager?.total || 0;
  }

  private get previousPageUrl(): string {
    return this.pager?.isFirstPage ? '' : `?page=${this.pager?.previousPage}`;
  }

  private get nextPageUrl(): string {
    return this.pager?.isLastPage ? '' : `?page=${this.pager?.nextPage}`;
  }

  private get firstRowIndex(): number {
    return (this.perPage * this.currentPage) - this.perPage + 1;
  }

  private get lastRowIndex(): number {
    const index = this.firstRowIndex + this.perPage - 1;

    return index >= this.totalResults ? this.totalResults : index;
  }

  protected render() {
    const headerRows = Array.from(this.querySelectorAll('data-table-head data-table-row'));
    const dataRows = Array.from(this.querySelectorAll('data-table-body data-table-row'));

    const totalColumns = Array.from(headerRows[0].querySelectorAll('data-table-cell')).length;

    const classes = classMap({
      'data-table': true,
      'data-table--centered': this.textCentered,
      'data-table--full-width': this.fullWidth,
    });

    return html`
      <div class="mdc-data-table ${classes}">
        <table class="mdc-data-table__table" aria-label="">
          <thead>
            ${headerRows.map((row) => html`
              <tr class="mdc-data-table__header-row">
                ${Array.from(row.querySelectorAll('data-table-cell')).map((cell) => {
                  const isCheckbox = cell.querySelector('mwc-checkbox') !== null;

                  const classes = classMap({
                    'mdc-data-table__header-cell--checkbox': isCheckbox,
                  });

                  return html`<th class="mdc-data-table__header-cell ${classes}">${unsafeHTML(cell.innerHTML)}</th>`;
                })}
              </tr>`)}
          </thead>
          <tbody class="mdc-data-table__content">
            ${dataRows.map((row) => html`
              <tr class="mdc-data-table__row">
                ${Array.from(row.querySelectorAll('data-table-cell')).map((cell) => {
                  const isCheckbox = cell.querySelector('mwc-checkbox') !== null;

                  const classes = classMap({
                    'data-table__cell--link': cell.hasAttribute('link'),
                    'mdc-data-table__cell--checkbox': isCheckbox,
                  });

                  return html`<td class="mdc-data-table__cell ${classes}">${html`<span @click="${() => this.handleLinkClick(cell.getAttribute('link') || '', cell.getAttribute('confirm') || '')}">${unsafeHTML(cell.innerHTML)}</span>`}</td>`;
                })}
              </tr>`)}
          </tbody>
          ${this.perPage !== -1 ? html`
            <tfoot>
              <tr>
                <td colspan="${totalColumns}">
                  <div class="data-table__pagination mdc-data-table__pagination">
                    <div class="mdc-data-table__pagination-total">${this.firstRowIndex}-${this.lastRowIndex} of ${this.totalResults}</div>
                    <button-link href="${this.previousPageUrl}" ?disabled="${this.previousPageUrl === ''}" icon="navigate_before"></button-link>
                    <button-link href="${this.nextPageUrl}" ?disabled="${this.nextPageUrl === ''}" icon="navigate_next"></button-link>
                  </div>
                </td>
              </tr>
            </tfoot>
          ` : ''}
        </table>
      </div>
    `;
  }
}
