import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {ifDefined} from 'lit/directives/if-defined';
import {symfony} from '../../../symfony/decorators';
import {Testimony} from '../../../symfony/models';
import {TestimonyForm} from '../../../symfony/forms';
import {date} from '../../../helpers/formatters/date-formatter';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/review/review-display';

@customElement('backend-business-replytestimony-page')
export class ReplytestimonyPage extends LitElement {
  @symfony({variable: '$testimony', converter: 'take:["id", "media_url", "Consumer.consumer_feedback_header", "comment", "created_at"]|json', type: Array}) private testimony!: Testimony;
  @symfony({variable: '$form', converter: 'form_fields:["reply"]|json', type: Object}) private form!: TestimonyForm;

  protected render() {
    return html`
<narrow-content centered>
    <review-display
            media="${this.testimony.media_url}"
            message="${ifDefined(this.testimony.comment)}"
            reviewerName="${this.testimony.Consumer.consumer_feedback_header}"
            reviewDate="${date(this.testimony.created_at)}">
    </review-display>
    
    <editable-section
        fullWidth
        heading="Your Reply"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'notes', 'type': 'textarea', 'label': 'Comment', 'name': 'testimony[reply]', 'value': this.form.reply.value},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
