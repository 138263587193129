import {html, LitElement, css} from 'lit';
import {customElement, state, property} from 'lit/decorators.js';
import {BusinessExtraForm, FormField} from '../../../../symfony/forms';
import {SelectOption} from '../../../../components/form-submission/fields/form-submission-select';
import {ItemDisplay} from '../../../../components/editable-section/editable-section';

type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
type BusinessHours = 'monday_opening_hours' | 'monday_closing_hours' | 'tuesday_opening_hours' | 'tuesday_closing_hours' |
  'wednesday_opening_hours' | 'wednesday_closing_hours' | 'thursday_opening_hours' | 'thursday_closing_hours' | 'friday_opening_hours' | 'friday_closing_hours' |
  'saturday_opening_hours' | 'saturday_closing_hours' | 'sunday_opening_hours' | 'sunday_closing_hours';
type BusinessHoursFields = Pick<BusinessExtraForm, BusinessHours>;

@customElement('hours-editable-section')
export class HoursEditableSection extends LitElement {
  @property({type: Object}) public businessExtraForm!: BusinessExtraForm;
  @state() private hoursFields!: BusinessHoursFields;

  static styles = css`
:host {
    display: block;
}
`;

  connectedCallback() {
    super.connectedCallback();

    this.hoursFields = this.setupHoursFields();
  }

  private setupHoursFields(): BusinessHoursFields {
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    // get an array of all the hours fields we need
    const fields: BusinessHours[] = daysOfWeek.reduce((acc: string[], dow: string) => acc.concat([`${dow}_opening_hours`, `${dow}_closing_hours`]), []) as BusinessHours[];

    // now copy only those fields from the original BusinessExtraForm into a new object
    return Object.assign({}, ...fields.map((prop: BusinessHours) => ({[prop]: this.businessExtraForm[prop]})));
  }

  private updateHoursFields(field: BusinessHours, newValue: string): BusinessHoursFields {
    // we need to updated the 'selected' field according to the newly selected value
    const newOptions: SelectOption[] = this.hoursFields[field].options.map((option: SelectOption) => ({...option, ...{selected: option.key === newValue}}));

    // now create the new form field with the updated values
    const newField: FormField = {...this.hoursFields[field], ...{value: newValue, options: newOptions}};

    // update the form field on the object
    return {...this.hoursFields, ...{[field]: newField}};
  }

  private handleFieldUpdated(event: CustomEvent): void {
    // When the underlying form field is updated, it will be out of sync with our hoursFields. So here we will
    // listen for changes to the underlying form field and update our internal hoursFields.
    if (event.detail.name.includes('opening_hours') || event.detail.name.includes('closing_hours')) {
      const field: BusinessHours = event.detail.name.match(/\[(.+)\]/)[1];

      // The 'form-submission-field-updated' event is fired multiple times when simply initiating it's values, but we only want to take
      // action after the field was actually changed so we'll check the previous/current values to see if there has been a change.
      const previousValue = this.hoursFields[field].value;
      const newValue = event.detail.value;

      if (previousValue === newValue) {
        return;
      }

      this.hoursFields = this.updateHoursFields(field, newValue);

      const otherField: BusinessHours = field.includes('opening') ?
        field.replace('opening', 'closing') as BusinessHours :
        field.replace('closing', 'opening') as BusinessHours;

      // when setting 1 value to CLOSED, also set the other
      if (newValue === 'CLOSED') {
        this.hoursFields = this.updateHoursFields(otherField, 'CLOSED');
      }

      // when 1 value is set but the other is blank, set the other to the same value
      if (newValue !== '' && this.hoursFields[otherField].value === '') {
        this.hoursFields = this.updateHoursFields(otherField, newValue);
      }
    }

    // Handle toggling the open/closed switch
    if (event.detail.name.endsWith('-toggle')) {
      const dow: DayOfWeek = event.detail.name.split('-')[0];

      // The 'form-submission-field-updated' event is fired multiple times when simply initiating it's values, but we only want to take
      // action after the field was actually changed so we'll check the previous/current values to see if there has been a change.
      const wasPreviouslyOn = this.shouldToggleBeOn(dow);
      const isCurrentlyOn = event.detail.value === 'on';

      if (wasPreviouslyOn === isCurrentlyOn) {
        return;
      }

      const openingField = `${dow}_opening_hours` as BusinessHours;
      const closingField = `${dow}_closing_hours` as BusinessHours;

      if (event.detail.value === 'off') {
        this.hoursFields = this.updateHoursFields(openingField, 'CLOSED');
        this.hoursFields = this.updateHoursFields(closingField, 'CLOSED');
      } else {
        // instead of a blank value, show the original times if possible
        this.hoursFields = this.updateHoursFields(openingField, this.businessExtraForm[openingField].value === 'CLOSED' ? '' : this.businessExtraForm[openingField].value);
        this.hoursFields = this.updateHoursFields(closingField, this.businessExtraForm[closingField].value === 'CLOSED' ? '' : this.businessExtraForm[closingField].value);
      }
    }
  }

  private hoursValue(dow: DayOfWeek, forClosing: boolean = false): string {
    const field = forClosing ? `${dow}_closing_hours` as BusinessHours : `${dow}_opening_hours` as BusinessHours;

    return this.hoursFields[field].value;
  }

  private shouldToggleBeOn(dow: DayOfWeek): boolean {
    return this.hoursValue(dow, false) !== 'CLOSED' && this.hoursValue(dow, true) !== 'CLOSED';
  }

  private hoursDisplayValue(dow: DayOfWeek): string {
    return this.hoursValue(dow, false) === 'CLOSED' && this.hoursValue(dow, true) === 'CLOSED' ? 'Closed' : `${this.hoursValue(dow, false)} - ${this.hoursValue(dow, true)}`;
  }

  protected render() {
    return html`
<editable-section
  @form-submission-field-updated="${this.handleFieldUpdated}"
  multipart
  heading="Hours"
  description=""
  alignContent="right"
  submitName="submit_save"
  .items=${<ItemDisplay[]>[
    {'leadingIcon': 'text_snippet', 'type': 'text', 'label': 'Notes', 'name': 'business_extra[note_about_hours]', 'value': this.businessExtraForm?.note_about_hours.value},
    {'leadingIcon': 'access_time', 'type': 'combination-horizontal', 'label': 'Monday', 'value': this.hoursDisplayValue('monday'), 'inputs': [
        {'type': 'switch', 'label': this.shouldToggleBeOn('monday') ? 'Open' : 'Closed', 'fieldLabel': 'Monday', 'name': 'monday-toggle', 'value': this.shouldToggleBeOn('monday') ? 'on': 'off'},
        {'type': 'select', 'label': 'Monday Opening', 'name': 'business_extra[monday_opening_hours]', 'value': this.hoursFields.monday_opening_hours.value, 'options': this.hoursFields.monday_opening_hours.options},
        {'type': 'select', 'label': 'Monday Closing', 'name': 'business_extra[monday_closing_hours]', 'value': this.hoursFields.monday_closing_hours.value, 'options': this.hoursFields.monday_closing_hours.options},
    ]},
    {'type': 'combination-horizontal', 'label': 'Tuesday', 'value': this.hoursDisplayValue('tuesday'), 'inputs': [
        {'type': 'switch', 'label': this.shouldToggleBeOn('tuesday') ? 'Open' : 'Closed', 'fieldLabel': 'Tuesday', 'name': 'tuesday-toggle', 'value': this.shouldToggleBeOn('tuesday') ? 'on': 'off'},
        {'type': 'select', 'label': 'Tuesday Opening', 'name': 'business_extra[tuesday_opening_hours]', 'value': this.hoursFields.tuesday_opening_hours.value, 'options': this.hoursFields.tuesday_opening_hours.options},
        {'type': 'select', 'label': 'Tuesday Closing', 'name': 'business_extra[tuesday_closing_hours]', 'value': this.hoursFields.tuesday_closing_hours.value, 'options': this.hoursFields.tuesday_closing_hours.options},
      ]},
    {'type': 'combination-horizontal', 'label': 'Wednesday', 'value': this.hoursDisplayValue('wednesday'), 'inputs': [
        {'type': 'switch', 'label': this.shouldToggleBeOn('wednesday') ? 'Open' : 'Closed', 'fieldLabel': 'Wednesday', 'name': 'wednesday-toggle', 'value': this.shouldToggleBeOn('wednesday') ? 'on': 'off'},
        {'type': 'select', 'label': 'Wednesday Opening', 'name': 'business_extra[wednesday_opening_hours]', 'value': this.hoursFields.wednesday_opening_hours.value, 'options': this.hoursFields.wednesday_opening_hours.options},
        {'type': 'select', 'label': 'Wednesday Closing', 'name': 'business_extra[wednesday_closing_hours]', 'value': this.hoursFields.wednesday_closing_hours.value, 'options': this.hoursFields.wednesday_closing_hours.options},
      ]},
    {'type': 'combination-horizontal', 'label': 'Thursday', 'value': this.hoursDisplayValue('thursday'), 'inputs': [
        {'type': 'switch', 'label': this.shouldToggleBeOn('thursday') ? 'Open' : 'Closed', 'fieldLabel': 'Thursday', 'name': 'thursday-toggle', 'value': this.shouldToggleBeOn('thursday') ? 'on': 'off'},
        {'type': 'select', 'label': 'Thursday Opening', 'name': 'business_extra[thursday_opening_hours]', 'value': this.hoursFields.thursday_opening_hours.value, 'options': this.hoursFields.thursday_opening_hours.options},
        {'type': 'select', 'label': 'Thursday Closing', 'name': 'business_extra[thursday_closing_hours]', 'value': this.hoursFields.thursday_closing_hours.value, 'options': this.hoursFields.thursday_closing_hours.options},
      ]},
    {'type': 'combination-horizontal', 'label': 'Friday', 'value': this.hoursDisplayValue('friday'), 'inputs': [
        {'type': 'switch', 'label': this.shouldToggleBeOn('friday') ? 'Open' : 'Closed', 'fieldLabel': 'Friday', 'name': 'friday-toggle', 'value': this.shouldToggleBeOn('friday') ? 'on': 'off'},
        {'type': 'select', 'label': 'Friday Opening', 'name': 'business_extra[friday_opening_hours]', 'value': this.hoursFields.friday_opening_hours.value, 'options': this.hoursFields.friday_opening_hours.options},
        {'type': 'select', 'label': 'Friday Closing', 'name': 'business_extra[friday_closing_hours]', 'value': this.hoursFields.friday_closing_hours.value, 'options': this.hoursFields.friday_closing_hours.options},
      ]},
    {'type': 'combination-horizontal', 'label': 'Saturday', 'value': this.hoursDisplayValue('saturday'), 'inputs': [
        {'type': 'switch', 'label': this.shouldToggleBeOn('saturday') ? 'Open' : 'Closed', 'fieldLabel': 'Saturday', 'name': 'saturday-toggle', 'value': this.shouldToggleBeOn('saturday') ? 'on': 'off'},
        {'type': 'select', 'label': 'Saturday Opening', 'name': 'business_extra[saturday_opening_hours]', 'value': this.hoursFields.saturday_opening_hours.value, 'options': this.hoursFields.saturday_opening_hours.options},
        {'type': 'select', 'label': 'Saturday Closing', 'name': 'business_extra[saturday_closing_hours]', 'value': this.hoursFields.saturday_closing_hours.value, 'options': this.hoursFields.saturday_closing_hours.options},
      ]},
    {'type': 'combination-horizontal', 'label': 'Sunday', 'value': this.hoursDisplayValue('sunday'), 'inputs': [
        {'type': 'switch', 'label': this.shouldToggleBeOn('sunday') ? 'Open' : 'Closed', 'fieldLabel': 'Sunday', 'name': 'sunday-toggle', 'value': this.shouldToggleBeOn('sunday') ? 'on': 'off'},
        {'type': 'select', 'label': 'Sunday Opening', 'name': 'business_extra[sunday_opening_hours]', 'value': this.hoursFields.sunday_opening_hours.value, 'options': this.hoursFields.sunday_opening_hours.options},
        {'type': 'select', 'label': 'Sunday Closing', 'name': 'business_extra[sunday_closing_hours]', 'value': this.hoursFields.sunday_closing_hours.value, 'options': this.hoursFields.sunday_closing_hours.options},
      ]},
  ]}>
</editable-section>
    `;
  }
}
