import {html, LitElement, TemplateResult, unsafeCSS} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {style} from './editable-section-item-display-css';
import {SelectOption} from '../form-submission/fields/form-submission-select';
import themeStyles from '@material/theme/dist/mdc.theme.css';
import typographyStyles from '@material/typography/dist/mdc.typography.css';
import {displayFileValue} from '../form-submission/fields/form-submission-file';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '../help-tooltip/help-tooltip';

@customElement('editable-section-item-display')
export class EditableSectionItemDisplay extends LitElement {
  public static EDIT_CLICKED_KEY = 'editable-section-display-clicked';

  @property({type: String}) leadingIcon = '';
  @property({type: String}) type = '';
  @property({type: String}) label = '';
  @property({type: String}) name = '';
  @property({type: String}) value = '';
  @property({type: Array}) options: SelectOption[] = [];
  @property({type: Boolean}) individualEdits = false;
  @property({type: String}) alignContent: 'left' | 'center' | 'right' = 'left';
  @property({type: Object}) public extra?: { [key: string]: string };
  @property({type: String}) public help: string = '';

  static styles = [unsafeCSS(themeStyles), unsafeCSS(typographyStyles), style];

  private handleIndividualClicked(e: any) {
    console.log(e);
  }

  private displayValue(): TemplateResult {
    if (this.type === 'file') {
      return displayFileValue(this.value, false, this.extra?.deleteUrl || '');
    }

    let displayValue = this.value;

    // for selects, we can show the current value by finding it in our options
    // if (this.type === 'select' && this.value === '') {
    //     displayValue = this.options.find(option => option.selected)?.label || '';
    // }

    if (this.type === 'switch') {
      displayValue = this.value.toUpperCase();
    }

    return html`${displayValue}`;
  }

  protected render() {
    return html`
<div class="editable-section__item editable-section__item--type-${this.type}">
    <div class="editable-section__item-leading-icon">
      ${this.leadingIcon !== '' ? html`<mwc-icon>${this.leadingIcon}</mwc-icon>` : ''}
    </div>
    <span class="editable-section__item-label mdc-typography--subtitle1 mdc-theme--text-secondary-on-light">${this.label}</span>
    ${this.help !== '' ? html`
    <help-tooltip tooltip="${this.help}"></help-tooltip>
    ` : ''}
    <div class="editable-section__item-content editable-section__item-content--align-${this.alignContent}">
        <span class="mdc-typography--body1 mdc-theme--text-primary-on-light">${this.displayValue()}</span>
    </div>
    ${this.individualEdits ? html`<mwc-icon-button icon="edit" class="editable-section__item-edit-button mdc-theme--text-secondary-on-light" @click="${this.handleIndividualClicked}"></mwc-icon-button>` : ''}
</div>
`;
  }
}
