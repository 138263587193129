import {css} from 'lit';

export const style = css`
:host {
    display: block;
    max-width: 600px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 15px;
    margin: 15px 0;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    --mdc-dialog-max-width: 800px;
}
:host([fullWidth]) {
    max-width: 100%;
}
:host([noBorder]) {
    border: none;
}
.editable-section {
    display: flex;
    flex-direction: column;
}
.editable-section__heading-container {
    display: flex;
    align-items: center;
    position: relative;
}
.editable-section__heading-text-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 0;
}
.editable-section--with-image .editable-section__heading-container {
    z-index: 0;
    height: 140px;
    align-items: flex-end;
    margin: -15px -15px 0 -15px;
    padding: 0 15px;
}
.editable-section--with-image .editable-section__heading-container:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 0;
}
.editable-section--with-image .editable-section__heading, .editable-section--with-image .editable-section__edit-button {
    color: #fff;
}
.editable-section__heading {
    flex: 1;
    font-size: 1.1rem;
    margin: 0;
}
.editable-section__heading-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.editable-section__dialog-form {
    display: flex;
    flex-direction: column;
}
.editable-section__dialog-form-field, .editable-section__dialog-form-field-label {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}
.editable-section__dialog-form-field--hidden {
    display: none;
}
.editable-section__dialog-form-field-label {
   color: rgba(0, 0, 0, 0.8);
}
.editable-section__dialog-form-field-container {
  display: block;
}
.editable-section__dialog-form-field-container--display-type-combination-horizontal {
  display: flex;
  align-items: center;
}
.editable-section__dialog-form-field-container--display-type-combination-horizontal > * {
  flex: 1;
}
.editable-section__dialog-form-field-container--display-type-combination-horizontal > .editable-section__dialog-form-field:not(:first-child) {
  margin-left: 15px;
}
.unsaved-changes-button {
    width: 100%;
    margin-top: 25px;
}
`;
