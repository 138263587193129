import {html, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Business, BusinessCustomField, PartnerPaymentOptions} from '../../../symfony/models';
import {CustomersFilterCountUpdated} from '../../../components/customers-filter/events/customers-filter-count-updated';
import {Filters} from '../../../components/customers-filter/customers-filter';
import '../../../components/typography/body-1';
import '../../../components/typography/body-2';
import '../../../components/layout/content-block';
import '../../../components/layout/narrow-content';
import '../../../components/button-link/button-link';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/customers-filter/customers-filter';
import '../../../components/customers-filter/customers-filter-submission';
import '../../../components/form-submission/fields/form-submission-checkbox';
import '../../../components/form-submission/fields/form-submission-textarea';

@customElement('backend-business-messaging-page')
export class MessagingPage extends LitElement {
  static MAX_MESSAGE_LENGTH: number = 115;

  @symfony({variable: '$business', converter: 'take:["sms_credits", "name", "slug"]|json', type: Object}) private business!: Business;
  @symfony({variable: '$sf_request', converter: '_call:"extractParameters":["gender", "age_start", "age_end", "join_start", "join_end", "zipcode"]|json', type: Object}) private filters!: Filters;
  @symfony({variable: '$sf_request', converter: '_call:"getParameter":"message"', type: String}) private message: string = '';
  @symfony({variable: '$sf_request', converter: '_call:"getParameter":"include_url"', type: String}) private includeUrl: string = '';
  @symfony({variable: '$sf_request', converter: '_call:"isMethod":"post"', type: Boolean}) private isPost: Boolean = false;
  @symfony({variable: '$zip_data', converter: 'json', type: Array}) private zipCodes: string[] = [];
  @symfony({variable: '$custom_fields', converter: 'take:["id", "name", "type_id", "BusinessCustomFieldValue.id", "BusinessCustomFieldValue.value"]|json', type: Array}) private customFields: BusinessCustomField[] = [];
  @symfony({variable: '$partner_name', type: String}) private partnerName: string = '';
  @symfony({variable: '$partner_payment_options', converter: 'take:["code"]|json', type: Array}) private partnerPaymentOptions: PartnerPaymentOptions[] = [];
  @symfony({variable: '$deal', converter: '_default:""', type: String}) private deal: string = '';
  @state() private count: number = 0;
  @state() private maxMessageLength: number = MessagingPage.MAX_MESSAGE_LENGTH;

  private handleIncludeUrlToggled(event: CustomEvent): void {
    const includingUrl = event.detail.shouldInclude;
    const urlLength = `${window.location.host}/${this.business.slug}`.length + 1;

    this.maxMessageLength = includingUrl ? MessagingPage.MAX_MESSAGE_LENGTH - urlLength : MessagingPage.MAX_MESSAGE_LENGTH;
  }

  protected render() {
    return html`
<narrow-content>
    <prominent-notice
        image="/assets/images/undraw_typing_jie3.svg"
        heading="Customer SMS"
        subtitle="Send SMS messages directly to your customers.">
        <body-1>${this.count} Customers Selected | ${this.business.sms_credits} Credits Available</body-1>
    </prominent-notice>

    <content-block centered>
        ${this.partnerPaymentOptions.length > 0 ? this.partnerPaymentOptions.map((payment) => html`
        <body-2>${payment.code}</body-2>
        `) : html`
        <button-link href="/admin/business/help">Contact Us To Buy Messages</button-link>
        `}
    </content-block>
    
    <content-block centered>
        <body-2>*It is required that the sender identify themselves. You should not delete your business name.</body-2>
    </content-block>

    <content-block centered>
        <customers-filter-submission actionLabel="Send Messages">
            <form-submission-checkbox slot="extra-form-fields" @form-submission-field-updated="${this.handleIncludeUrlToggled}" label="Include URL in message (${window.location.host}/${this.business.slug})" name="include_url" value="1" ?checked="${this.includeUrl === '1' || !this.isPost}"></form-submission-checkbox>
            <form-submission-textarea slot="extra-form-fields" maxLength="${this.maxMessageLength}" label="Message" name="message" value='${this.message ? this.message : `${this.business.name}: ${this.deal}`}'></form-submission-textarea>
            <form-submission-checkbox slot="extra-form-fields" label='By using ${this.partnerName} or any other ${this.partnerName} property ("Our Sites") to send text messages, the client agrees to be bound by our terms of service. The client agrees to comply with all applicable laws, rules and regulations. The client undertakes that it will not knowingly use ${this.partnerName} services for any illegal, immoral or improper purpose or in any manner which contravenes the law or regulatory requirements of the appropriate jurisdiction or knowingly in any way which is indecent or offensive or in any way that could be considered as Spamming, and undertakes not to allow any third party to do so. The client explicitly confirms that for each SMS/text it sends to Mobile Subscribers via ${this.partnerName} services/platforms, the client has the prior consent of the recipient of each message before the message is sent. ${this.partnerName} disclaims any liability to the client and any users of this Service. ${this.partnerName} will not guarantee delivery of any messages.' name="disclaimer" value="1"></form-submission-checkbox>

            <customers-filter forSms slot="customers-filter" @customers-filter-count-updated="${(event: CustomersFilterCountUpdated) => this.count = event.count}"
                .filters="${this.filters}"
                .zipCodes="${this.zipCodes}"
                .customFields="${this.customFields}">
            </customers-filter>
        </customers-filter-submission>

    </content-block>
</narrow-content>
`;
  }
}
