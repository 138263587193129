/* global google */
import {css, html, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';

@customElement('pindrop-map')
export class PindropMap extends LitElement {
  @property({type: String}) public key: string = '';
  @property({type: Number}) public lat: number = 0;
  @property({type: Number}) public lng: number = 0;
  @query('#map') private map!: HTMLDivElement;

  static styles = css`
    :host {
      display: block;
      width: 100%;
      min-height: 200px;
    }

    #map {
      width: 100%;
      height: 100%;
    }
  `;

  connectedCallback() {
    super.connectedCallback();

    this.initializeMap();
  }

  private async initializeMap(): Promise<void> {
    await this.loadGoogleMaps();

    const center: google.maps.LatLngLiteral = {lat: this.lat, lng: this.lng};

    const map: google.maps.Map = new google.maps.Map(this.map, {
      center,
      zoom: 18,
    });

    const marker: google.maps.Marker = new google.maps.Marker({position: center, map: map, draggable: true});

    google.maps.event.addListener(marker, 'dragend', () => {
      const coords = marker.getPosition() as google.maps.LatLng;

      const lat = coords.lat();
      const lng = coords.lng();

      this.dispatchEvent(new PinDropped(lat, lng));
    });
  }

  private loadGoogleMaps(): Promise<void> {
    return new Promise<void>((resolve) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.key}&callback=initMap`;
      script.defer = true;

      (<any>window).initMap = () => resolve();

      this.appendChild(script);
    });
  }

  protected render() {
    return html`
      <div id="map"></div>
    `;
  }
}

export class PinDropped extends Event {
  static type = 'pin-dropped';

  constructor(public lat: number, public lng: number) {
    super(PinDropped.type, {bubbles: true, composed: true});
  }
}
