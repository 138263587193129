import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '@material/mwc-button';

@customElement('image-grid-item')
export class ImageGridItem extends LitElement {
  @property({type: String}) src = '';
  @property({type: String}) alt = '';
  @property({type: String}) deleteUrl = '';
  @property({type: String}) footerText = '';

  static styles = css`
:host {
    max-width: 300px;
    display: block;
    overflow: hidden;
    margin-left: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.image-grid__image-body {
    position: relative;
}
.image-grid__image-footer {
    text-align: center;
    line-height: 2.5rem;
    border: 1px solid #dadce0;
}
.image-grid__image {
    display: block;
    width: 100%;
}
.image-grid__image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: none;
}
:host(:hover) .image-grid__image-overlay  {
    display: flex;
}
mwc-button {
  --mdc-theme-primary: #b00020;
  --mdc-theme-on-primary: white;
}
`;

  private handleDeleteClicked() {
    if (window.confirm('Are you sure you want to delete this image?')) {
      window.location.href = this.deleteUrl;
    }
  }

  protected render() {
    return html`
<div class="image-grid__image-container">
    <div class="image-grid__image-body">
        <img class="image-grid__image" src="${this.src}" alt="${this.alt}">
        <div class="image-grid__image-overlay"><mwc-button raised label="Delete" @click="${this.handleDeleteClicked}"></mwc-button></div>
    </div>
    <div class="image-grid__image-footer">
        ${this.footerText}
    </div>
</div>
`;
  }
}
