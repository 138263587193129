import {customElement} from 'lit/decorators.js';
import {BaseTypography} from './base-typography';

@customElement('heading-3')
export class Heading3 extends BaseTypography {
  protected mdcTypography(): string {
    return 'headline3';
  }

  protected defaultTag(): string {
    return 'h3';
  }
}
