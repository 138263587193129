import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/button-link/button-link';

@customElement('backend-business-index-page')
export class IndexPage extends LitElement {
  protected render() {
    return html`
<prominent-notice
        image="/assets/images/undraw_mobile_prototyping_grmd.svg"
        heading="Create Your App!"
        subtitle="Promote Your Business or Service to Local Shoppers On The Go. Turn mobile searches into loyal VIP customers">
    <button-link href="/admin/business/app#listing-details" raised>Create App</button-link>
</prominent-notice>
`;
  }
}
