import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessRewardForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/editable-section/editable-section';

@customElement('backend-business-cashbacknow-page')
export class CashbacknowPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["name_buy", "name_get", "expires_after", "security_code", "security_reset", "security_reset_time", "security_notify"]|json', type: Object}) private form!: BusinessRewardForm;

  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
<narrow-content centered>
    <editable-section
        heading="Cash Back Now"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'attach_money', 'type': 'text', 'label': 'Spend/Buy Amount $', 'name': 'business_reward[name_buy]', 'value': this.form.name_buy.value},
            {'leadingIcon': 'redeem', 'type': 'text', 'label': 'Instant Cash Back Amount $', 'name': 'business_reward[name_get]', 'value': this.form.name_get.value},
            {'leadingIcon': 'hourglass_disabled', 'type': 'select', 'label': 'How many days after reward is earned should it expire?', 'name': 'business_reward[expires_after]', 'value': this.form.expires_after.value, 'options': this.form.expires_after.options},
        ]}>
    </editable-section>

    <editable-section
        heading="Security"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'security', 'type': 'text', 'label': 'Security Code', 'name': 'business_reward[security_code]', 'value': this.form.security_code.value},
            {'leadingIcon': 'loyalty', 'type': 'combination', 'label': 'Reset Time', 'value': `Every ${this.form.security_reset.options.find((o) => o.selected)?.label || 'N/A'} at ${this.form.security_reset_time.value}`, 'inputs': [
                {'leadingIcon': '', 'type': 'select', 'label': 'Reset security code every', 'name': 'business_reward[security_reset]', 'value': this.form.security_reset.value, 'options': this.form.security_reset.options},
                {'leadingIcon': '', 'type': 'select', 'label': 'Reset Time (UTC−06:00)', 'name': 'business_reward[security_reset_time]', 'value': this.form.security_reset_time.value, 'options': this.form.security_reset_time.options},
            ]},
            {'leadingIcon': 'notes', 'type': 'textarea', 'label': 'Notify the following email addresses when security code is reset (separate each email address with a comma)', 'name': 'business_reward[security_notify]', 'value': this.form.security_notify.value},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
