import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {FormSubmissionBase} from './form-submission-base';
import {TextArea} from '@material/mwc-textarea';

@customElement('form-submission-textarea')
export class FormSubmissionTextarea extends FormSubmissionBase {
  @property({type: String}) label = '';
  @property({type: Number}) maxLength: number = -1;
  @property({type: Number}) rows: number = 2;
  @property({type: Number}) cols: number = 20;
  @query('mwc-textarea') private mwcTextarea!: TextArea;

  protected get shouldInclude(): boolean {
    return true;
  }

  protected render() {
    return html`<mwc-textarea @input="${() => this.value = this.mwcTextarea.value}" label="${this.label}" value="${this.value}" maxLength="${this.maxLength}" ?charCounter="${this.maxLength !== -1}" rows="${this.rows}" cols="${this.cols}"></mwc-textarea>`;
  }
}
