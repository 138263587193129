import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessEvent} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/floating-action-button/floating-action-button';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';

@customElement('backend-business-events-page')
export class EventsPage extends LitElement {
  @symfony({variable: '$events', converter: 'take:["id", "title", "description", "start_date", "end_date"]|json', type: Array}) private events: BusinessEvent[] = [];

  protected render() {
    return html`
        
<narrow-content>
    <content-block textRight>
        <floating-action-button href="/admin/business/addevent" icon="add" actionLabel="Create Event"></floating-action-button>
    </content-block>
    
    ${this.events.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Title</data-table-cell>
                <data-table-cell>Description</data-table-cell>
                <data-table-cell>Start</data-table-cell>
                <data-table-cell>End</data-table-cell>
                <data-table-cell></data-table-cell>
                <data-table-cell></data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.events.map((event) => html`
                <data-table-row>
                    <data-table-cell>${event.title}</data-table-cell>
                    <data-table-cell>${event.description}</data-table-cell>
                    <data-table-cell>${date(event.start_date)}</data-table-cell>
                    <data-table-cell>${event.end_date ? date(event.end_date) : ''}</data-table-cell>
                    <data-table-cell link="/admin/business/addevent/id/${event.id}">Edit</data-table-cell>
                    <data-table-cell link="/admin/business/eventdelete/id/${event.id}" confirm="Are you sure you want to delete this event?">Delete</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
<content-block textCenter>
    <body-1>You have not setup any events.</body-1>
</content-block>
    `}
    
</narrow-content>
        
`;
  }
}
