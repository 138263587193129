import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('code-block')
export class CodeBlock extends LitElement {
  static styles = css`
pre {
    text-align: left;
    background-attachment: scroll;
    background-color: #FAF0E6;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat repeat;
    border: 1px solid #489CD4;
    clear: both;
    overflow: auto;
    padding: 0.5em;
    white-space: pre-wrap;
    word-wrap: break-word;
}
`;

  protected render() {
    return html`
<pre><slot></slot></pre>
`;
  }
}
