import {css, html, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessLocationExtraForm, BusinessLocationForm} from '../../../symfony/forms';
import {BusinessLocation, State} from '../../../symfony/models';
import {PinDropped} from '../../../components/pindrop-map/pindrop-map';
import {locationEditTemplate} from './locationsadd-page';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/pindrop-map/pindrop-map';

@customElement('backend-business-locationsedit-page')
export class LocationseditPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "public_address", "name", "website", "phone_one", "address_one", "city", "country_id", "state_id", "zip_code", "lat", "lng"]|json', type: Object}) private form!: BusinessLocationForm;
  @symfony({variable: '$form_extra', converter: 'form_fields:["id", "has_atm", "has_wifi", "has_restroom", "is_byob", "has_refreshments", "has_santas_mailbox", "is_sponsor", "offers_aaa_rewards", "is_hiring", "accepts_cash", "accepts_visa", "accepts_mastercard", "accepts_discover", "accepts_american_express"]|json', type: Object}) private formExtra!: BusinessLocationExtraForm;
  @symfony({variable: '$location', converter: 'take:["lat", "lng"]|json', type: Object}) private location!: BusinessLocation;
  @symfony({variable: '$states', converter: 'take:["id", "country_id", "name"]|json', type: Array}) private states: State[] = [];
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_google_api_key"', type: String}) private googleApiKey!: string;

  @state() private lat: number = 0;
  @state() private lng: number = 0;

  static styles = css`
pindrop-map {
    height: 350px;
}
`;

  connectedCallback() {
    super.connectedCallback();

    // initialize the hidden lat/lng form values, but allow them to be updated
    // when the map pin is moved
    this.lat = +this.form.lat.value;
    this.lng = +this.form.lng.value;
  }

  private handlePinDropped(event: PinDropped): void {
    this.lat = event.lat;
    this.lng = event.lng;
  }

  protected render() {
    return html`
<narrow-content centered>
    <content-block centered>
        <pindrop-map @pin-dropped="${this.handlePinDropped}" key="${this.googleApiKey}" lat="${this.location.lat}" lng="${this.location.lng}"></pindrop-map>
    </content-block>
    
    ${locationEditTemplate(this.form, this.formExtra, this.states, this.lat, this.lng)}
</narrow-content>
`;
  }
}
