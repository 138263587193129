import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '../../components/typography/body-1';
import '../../components/typography/heading-6';

@customElement('section-heading')
export class SectionHeading extends LitElement {
  @property({type: String}) public heading: string = '';
  @property({type: String}) public linkText: string = '';
  @property({type: String}) public linkHref: string = '';

  static styles = css`
:host {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
}
.link {
    text-decoration: none;
    color: inherit;
}
`;

  protected render() {
    return html`
<heading-6>${this.heading}</heading-6>
<body-1>
    <a class="link" href="${this.linkHref}">${this.linkText}</a>
</body-1>
`;
  }
}
