import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessReward} from '../../../symfony/models';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/floating-action-button/floating-action-button';

@customElement('backend-business-programsgames-page')
export class ProgramsgamesPage extends LitElement {
  @symfony({variable: '$games', converter: 'take:["id", "name", "expires_after", "checkin_times", "checkin_per"]|json', type: Array}) private games: BusinessReward[] = [];

  private checkinTimesString(game: BusinessReward): string {
    if (game.checkin_times == -1) {
      return 'Unlimited';
    }

    const dictionary = new Map<number, string>([
      [1, 'day'],
      [2, 'week'],
      [3, 'month'],
    ]);

    const period = dictionary.get(+game.checkin_per) as string;

    return `${game.checkin_times} times per ${period}`;
  }

  protected render() {
    return html`
        
<narrow-content>
    <content-block textRight>
        <floating-action-button href="/admin/business/programsgame" icon="add" actionLabel="Create Loyalty Game"></floating-action-button>
    </content-block>
    
    ${this.games.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Name</data-table-cell>
                <data-table-cell>Expires</data-table-cell>
                <data-table-cell>Scans</data-table-cell>
                <data-table-cell></data-table-cell>
                <data-table-cell></data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.games.map((game) => html`
                <data-table-row>
                    <data-table-cell>${game.name}</data-table-cell>
                    <data-table-cell>${game.expires_after == 0 ? `No Expiration` : `${game.expires_after} months after earned`}</data-table-cell>
                    <data-table-cell>${this.checkinTimesString(game)}</data-table-cell>
                    <data-table-cell link="/admin/business/programsgame/id/${game.id}">Edit</data-table-cell>
                    <data-table-cell link="/admin/business/programsgamedelete/id/${game.id}" confirm="Are you sure you want to delete this loyalty game?">Delete</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
<content-block textCenter>
    <body-1>You have not setup any loyalty games.</body-1>
</content-block>
    `}
    
</narrow-content>
        
`;
  }
}
