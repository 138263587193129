import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('data-table-head')
export class DataTableHead extends LitElement {
  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
    `;
  }
}
