import {css} from 'lit';

export const style = css`
:host {
  --mdc-icon-size: 24px;
}
.editable-section__item {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.editable-section__item--type-hidden {
    display: none;
}
.editable-section__item--uppercase-value .editable-section__item-content {
    text-transform: uppercase;
}
.editable-section__item-leading-icon {
    color: var(--mdc-theme-primary);
    display: inline-block;
    /* need to explicitly set the width for when there is no leadingIcon */
    width: var(--mdc-icon-size);
}
.editable-section__item-content {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 70px;
}
.editable-section__item-content--align-left {
    text-align: left;
}
.editable-section__item-content--align-center {
    text-align: center;
}
.editable-section__item-content--align-right {
    text-align: right;
}
.editable-section__item-leading-icon:not(:first-child), .editable-section__item-label:not(:first-child), .editable-section__item-content:not(:first-child) {
    margin-left: 20px;
}
.help-icon {
    color: var(--primary-color);
    margin-left: 5px;
    --mdc-icon-size: 18px;
    cursor: pointer;
}
`;
