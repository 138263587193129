import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Business, BusinessDeal, BusinessReward} from '../../../symfony/models';
import '../../../components/typography/body-1';
import '../../../components/typography/heading-4';
import '../../../components/typography/heading-5';
import '../../../components/layout/content-block';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';
import '../../../components/line-chart/line-chart';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/card-display/card-display';

@customElement('backend-business-programsdashboard-page')
export class ProgramsdashboardPage extends LitElement {
  @symfony({variable: '$business', converter: 'take:["BusinessExtra.phone_call_count", "BusinessExtra.website_clicks", "BusinessExtra.directions_clicks", "BusinessExtra.last_month_phone_call_count", "BusinessExtra.last_month_website_clicks", "BusinessExtra.last_month_directions_clicks", "Consumer.id", "BusinessPermission.mobile_nav_bar"]|json', type: Object}) private business!: Business;
  @symfony({variable: '$programs', converter: 'take:["id", "name", "type_id", "getRewardEarnedCount", "getRewardRedeemedCount"]|json', type: Array}) private programs: BusinessReward[] = [];
  @symfony({variable: '$deals', converter: 'take:["id", "deal", "getDealRedeemedCount"]|json', type: Array}) private deals: BusinessDeal[] = [];
  @symfony({variable: '$consumers_month_to_month', converter: 'json', type: Object}) private consumersMonthToMonth!: { [key: string]: string };
  @symfony({variable: '$consumers_new', converter: 'json', type: Object}) private consumersNew!: { [key: string]: string };

  static styles = css`
.stat-column {
    min-width: 200px;
}
card-display {
    text-align: center;
}
`;

  private programType(program: BusinessReward): string {
    const dictionary = new Map<number, string>([
      [1, 'Mobile Punch Card'],
      [2, 'Social Reward'],
      [3, 'Loyalty Game'],
      [4, 'Tablet Loyalty'],
    ]);

    return dictionary.get(+program.type_id) as string;
  }

  protected render() {
    return html`
<flex-column-container>
    <flex-column class="stat-column">
        <card-display>
            <div slot="content">
                <heading-5>Phone Calls</heading-5>
                <body-1>${this.business.BusinessExtra.phone_call_count}</body-1>
            </div>
        </card-display>
    </flex-column>
    <flex-column class="stat-column">
        <card-display>
            <div slot="content">
                <heading-5>Website Clicks</heading-5>
                <body-1>${this.business.BusinessExtra.website_clicks}</body-1>
            </div>
        </card-display>
    </flex-column>
    <flex-column class="stat-column">
        <card-display>
            <div slot="content">
                <heading-5>Directions Clicks</heading-5>
                <body-1>${this.business.BusinessExtra.directions_clicks}</body-1>
            </div>
        </card-display>
    </flex-column>
    <flex-column class="stat-column">
        <card-display>
            <div slot="content">
                <heading-5>Subscribers</heading-5>
                <body-1>${this.business.Consumer.length}</body-1>
            </div>
        </card-display>
    </flex-column>
</flex-column-container>

<flex-column-container columnForMobile>
    <flex-column>
        <line-chart dataLabel="Members Month to Month" .datapoints="${this.consumersMonthToMonth}"></line-chart>
    </flex-column>
    <flex-column>
        <line-chart dataLabel="New Members By Month" .datapoints="${this.consumersNew}"></line-chart>
    </flex-column>
</flex-column-container>

<content-block>
    <heading-4>Statistics</heading-4>
</content-block>
<content-block>
    ${this.programs.length || this.deals.length ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Description</data-table-cell>
                <data-table-cell>Type</data-table-cell>
                <data-table-cell>Earned</data-table-cell>
                <data-table-cell>Redeemed</data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.programs.map((program) => html`
            <data-table-row>
                <data-table-cell>${program.name}</data-table-cell>
                <data-table-cell>${this.programType(program)}</data-table-cell>
                <data-table-cell>${program.getRewardEarnedCount}</data-table-cell>
                <data-table-cell>${program.getRewardRedeemedCount}</data-table-cell>
            </data-table-row>
            `)}
            ${this.deals.map((deal) => html`
            <data-table-row>
                <data-table-cell>${deal.deal}</data-table-cell>
                <data-table-cell>Mobile Deal</data-table-cell>
                <data-table-cell>${deal.getDealRedeemedCount}</data-table-cell>
                <data-table-cell>${deal.getDealRedeemedCount}</data-table-cell>
            </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
    <content-block textCenter>
        <body-1>You have no program setup</body-1>
    </content-block>
    `}
</content-block>


${this.business.BusinessPermission.mobile_nav_bar ? html`
<content-block>
    <heading-4>Analytics</heading-4>
</content-block>
<content-block>
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell></data-table-cell>
                <data-table-cell>Phone Calls</data-table-cell>
                <data-table-cell>Website Clicks</data-table-cell>
                <data-table-cell>Directions Clicks</data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            <data-table-row>
                <data-table-cell>Current Month's Analytics</data-table-cell>
                <data-table-cell>${this.business.BusinessExtra.phone_call_count - this.business.BusinessExtra.last_month_phone_call_count}</data-table-cell>
                <data-table-cell>${this.business.BusinessExtra.website_clicks - this.business.BusinessExtra.last_month_website_clicks}</data-table-cell>
                <data-table-cell>${this.business.BusinessExtra.directions_clicks - this.business.BusinessExtra.last_month_directions_clicks}</data-table-cell>
            </data-table-row>
            <data-table-row>
                <data-table-cell>Last Month's Analytics</data-table-cell>
                <data-table-cell>${this.business.BusinessExtra.last_month_phone_call_count}</data-table-cell>
                <data-table-cell>${this.business.BusinessExtra.last_month_website_clicks}</data-table-cell>
                <data-table-cell>${this.business.BusinessExtra.last_month_directions_clicks}</data-table-cell>
            </data-table-row>
        </data-table-body>
    </data-table>
</content-block>
` : ''}


`;
  }
}
