import {html, LitElement} from 'lit';
import {customElement, queryAll} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Business, Partner} from '../../../symfony/models';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/embed/embed-display';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-submit';
import '../../../components/form-submission/fields/form-submission-switch';
import '@material/mwc-button';
import {SelectOption} from '../../../components/form-submission/fields/form-submission-select';

interface EmbedParameter {
  label: string,
  value: string|boolean
}

@customElement('backend-business-manageembeds-page')
export class ManageembedsPage extends LitElement {
  @symfony({variable: '$business', converter: 'take:["allAvailableReviewThemes", "slug", "Partner", "Partner.name", "Partner.domain", "Partner.domain_app"]|json', type: Array}) private business!: Business;
  @symfony({variable: '$partnerOptions', converter: 'take:["name", "domain_app"]|json', type: Array}) private partnerOptions: Partner[] = [];
  @queryAll('form-submission-select') selects!: HTMLFormElement[];
  @queryAll('form-submission-switch') switches!: HTMLFormElement[];

  protected get AvailablePartners(): SelectOption[] {
    // filter out partnerOptions with unset domain_app
    // map everything else as a SelectOption
    return this.partnerOptions.map((partner: Partner) => {
      return {
        key: partner.domain_app,
        label: partner.name,
        selected: this.getFieldValue(this.selects, 'Partner') === partner.domain_app,
      };
    });
  }

  protected get AvailableThemes(): SelectOption[] {
    const options: SelectOption[] = [];

    for (const [key, value] of Object.entries(this.business.allAvailableReviewThemes)) {
      options.push({
        key: String(key),
        label: String(value),
        selected: this.getFieldValue(this.selects, 'Theme') === key,
      });
    }

    return options;
  }

  protected get UrlPrefix() {
    return this.getFieldValue(this.selects, 'Partner') == 'm.localhost' ? 'http://' : 'https://';
  }

  protected get HostName() {
    return (this.getFieldValue(this.selects, 'Partner') ?? this.business.Partner.domain_app).replace('localhost', 'localhost:8080');
  }

  protected get EmbedParams(): string {
    const params: EmbedParameter[] = [];

    if (this.getFieldValue(this.switches, 'Show Partner') === 'off') {
      params.push({
        label: 'hide-partner',
        value: true,
      });
    }
    if (this.getFieldValue(this.switches, 'Show Actions') === 'off') {
      params.push({
        label: 'hide-action',
        value: true,
      });
    }
    params.push({
      label: 'theme',
      value: this.getFieldValue(this.selects, 'Theme') ?? this.AvailableThemes[0]['key'],
    });

    let queryParams = '?';
    for (let i = 0; i < params.length; i++) {
      queryParams += i > 0 ? '&' : '';
      queryParams += params[i]['label'] + '=' + params[i]['value'];
    }

    return queryParams;
  }

  protected getFieldValue(sourceQueryElements: HTMLFormElement[], label: string) {
    for (const [, value] of Object.entries(sourceQueryElements)) {
      if (label == value.label) {
        return value.value;
      }
    }

    return null;
  }

  protected render() {
    return html`
<narrow-content>
    <content-block centered>
        <form-submission>
            <flex-column-container gap="10" columnForMobile>
                <flex-column flex="initial">
                    <form-submission-select name="embed_partner" @form-submission-field-updated="${() => this.requestUpdate()}" label="Partner" value="${this.getFieldValue(this.selects, 'Partner') ?? this.business.Partner.domain_app}" .options="${this.AvailablePartners}"></form-submission-select>
                    <form-submission-select name="embed_theme" @form-submission-field-updated="${() => this.requestUpdate()}" label="Theme" value="${this.getFieldValue(this.selects, 'Theme') ?? this.AvailableThemes[0]['key']}" .options="${this.AvailableThemes}"></form-submission-select>
                </flex-column>
                <flex-column flex="initial">
                    <form-submission-switch name="embed_show_partner" @form-submission-field-updated="${() => this.requestUpdate()}" label="Show Partner" value="${this.getFieldValue(this.switches, 'Show Partner') ?? 'on'}"></form-submission-switch>
                </flex-column>
                <flex-column flex="initial">
                    <form-submission-switch name="embed_show_actions" @form-submission-field-updated="${() => this.requestUpdate()}" label="Show Actions" value="${this.getFieldValue(this.switches, 'Show Actions') ?? 'on'}"></form-submission-switch>
                </flex-column>
            </flex-column-container>
        </form-submission>
  </content-block>
  <flex-column-container>
      <flex-column flex="initial" style="flex-grow: 1;">
        <embed-display
          redirectTo="${this.UrlPrefix + this.HostName + '/' + this.business.slug + '/reviews/index'}"
          embedCodeSrc="${this.UrlPrefix + this.HostName + '/embed/' + this.business.slug + '/reviews?v2'}"
          type="horizontal-reviews-feed"
        ></embed-display>
      </flex-column>
  </flex-column-container>
  <flex-column-container>
      <flex-column flex="initial" style="height: 100%; max-width: 50%;">
        <embed-display
          redirectTo="${this.UrlPrefix + this.HostName + '/' + this.business.slug + '/reviews/index'}"
          embedCodeSrc="${this.UrlPrefix + this.HostName + '/embed/' + this.business.slug + '/review-grade' + this.EmbedParams}"
          type="review-grade"
        ></embed-display>
      </flex-column>
      <flex-column flex="initial" style="height: 100%; max-width: 50%;">
          <embed-display
              redirectTo="${this.UrlPrefix + this.HostName + '/' + this.business.slug + '/reviews/index'}"
              embedCodeSrc="${this.UrlPrefix + this.HostName + '/embed/' + this.business.slug + '/reviews'}"
              type="vertical-reviews-feed"
          ></embed-display>
      </flex-column>
  </flex-column-container>
</narrow-content>
`;
  }
}
