import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import phonePreview from './phone-preview.png';

@customElement('mobile-app-preview')
export class MobileAppPreview extends LitElement {
  @property({type: String}) public url: string = '';

  static styles = css`
:host {
    display: block;
    height: 701px;
    width: 363px;
    max-width: 100%;
    position: relative;
}
img {
    position: absolute;
    height: 100%;
}
iframe {
    background-color: #fff;
    border: none;
    position: relative;
    top: 68px;
    left: 16px;
}
`;

  protected render() {
    return html`
<img src="/assets/js/${phonePreview}" alt="phone preview">
<iframe width="325" height="545" name="preview" src="${this.url}"></iframe>
`;
  }
}
