import {css, html, TemplateResult} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {FormSubmissionBase} from './form-submission-base';
import '@material/mwc-button';
import '../../../components/typography/body-2';
import '../../../components/thumbnail-image/thumbnail-image';

@customElement('form-submission-file')
export class FormSubmissionFile extends FormSubmissionBase {
  @property({type: String}) label = '';
  @query('input') private input!: HTMLInputElement;
  private file?: File;

  static styles = css`
:host {
    display: contents;
}
.button-container {
    display: flex;
    align-items: center;
}
mwc-button {
    flex: 1;
    margin-left: 15px;
}
.value-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
`;

  public fileChanged(value: string, file?: File): void {
    this.value = value;
    this.file = file;
  }

  private displayValue(): TemplateResult {
    return displayFileValue(this.value, true, '', this.file);
  }

  protected get shouldInclude(): boolean {
    return true;
  }

  protected get isFile(): boolean {
    return true;
  }

  private handleClick(): void {
    this.dispatchEvent(new CustomEvent('form-submission-select-file-clicked', {bubbles: true, cancelable: true, composed: true, detail: {name: this.name}}));
  }

  protected render() {
    return html`
<div class="button-container">
    <body-2>${this.label}</body-2>
    <mwc-button @click="${this.handleClick}" raised label="Select A File"></mwc-button>
</div>
${this.value ? html`
<div class="value-container">
    ${this.displayValue()}
</div>
` : ''}

`;
  }
}

export function displayFileValue(value: string, useLargeImage: boolean = false, deleteUrl: string = '', file?: File): TemplateResult {
  const extension = value.split('.').pop();

  const isImage = ['jpg', 'jpeg', 'png'].includes(extension || '');

  if (isImage) {
    if (value.startsWith('C:\\fakepath\\')) {
      value = URL.createObjectURL(file);
    }

    return html`<thumbnail-image deleteUrl="${deleteUrl}" ?large="${useLargeImage}" src="${value}"></thumbnail-image>`;
  }

  const displayValue = value.replace(/^(C:\\fakepath\\)/, '');

  return html`<p>${displayValue}</p>`;
}
