import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {Customer} from '../../../symfony/models';
import {symfony} from '../../../symfony/decorators';
import {Pager} from '../../../symfony/built-ins';
import '../../../components/typography/body-1';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/form-submission/fields/form-submission-checkbox';
import '../../../components/form-submission/fields/form-submission-submit';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/layout/content-block';
import '../../../components/floating-action-button/floating-action-button';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';

@customElement('backend-business-customers-page')
export class CustomersPage extends LitElement {
  @symfony({variable: '$customers', converter: 'take:["id", "first_name", "last_name", "email", "phone", "zip_code"]|json', type: Array}) private customers: Customer[] = [];
  @symfony({variable: '$sf_user', converter: '_call:"getAttribute":"customer_search"', type: String}) private customerSearch?: string;
  @symfony({variable: '$sf_user', converter: '_call:"hasCredential":"business"', type: Boolean}) private isBusiness: boolean = false;
  @symfony({variable: '$sf_user', converter: '_call:"hasCredential":"partner"', type: Boolean}) private isPartner: boolean = false;
  @symfony({variable: '$pager', converter: 'pager|json', type: Object}) private pager?: Pager;

  protected render() {
    return html`

<form-submission action="/admin/business/customers">
    <flex-column-container gap="10" columnForMobile>
        <flex-column flex="initial">
            <form-submission-textfield name="search" label="Search Customers" value="${this.customerSearch || ''}"></form-submission-textfield>
        </flex-column>
        <flex-column>
            <form-submission-submit name="submit_search" value="Search"></form-submission-submit>
        </flex-column>
        ${this.isBusiness ? html`
        <flex-column>
            <content-block textRight>
                <floating-action-button href="/admin/business/customerform" icon="add" actionLabel="Search"></floating-action-button>
            </content-block>
        </flex-column>
        ` : ''}
    </flex-column-container>
</form-submission>


${this.customers.length > 0 ? html`
<form-submission action="/admin/business/customerdelete">
    <data-table fullWidth .pager="${this.pager}">
        <data-table-head>
            <data-table-row>
                <data-table-cell></data-table-cell>
                <data-table-cell>First Name</data-table-cell>
                <data-table-cell>Last Name</data-table-cell>
                <data-table-cell>Email</data-table-cell>
                <data-table-cell>Mobile</data-table-cell>
                <data-table-cell>Postal Code</data-table-cell>
                <data-table-cell></data-table-cell>
                ${this.isBusiness ? html`
                    <data-table-cell></data-table-cell>
                    <data-table-cell></data-table-cell>
                ` : ''}
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.customers.map((customer) => html`
                <data-table-row>
                    <data-table-cell><form-submission-checkbox name="customer_deletes[]" value="customer_deletes_${customer.id}"></form-submission-checkbox></data-table-cell>
                    <data-table-cell>${customer.first_name}</data-table-cell>
                    <data-table-cell>${customer.last_name}</data-table-cell>
                    <data-table-cell>${customer.email}</data-table-cell>
                    <data-table-cell>${customer.phone}</data-table-cell>
                    <data-table-cell>${customer.zip_code}</data-table-cell>
                    <data-table-cell link="/admin/${this.isPartner ? 'partner' : 'business'}/customer/id/${customer.id}">View</data-table-cell>
                    ${this.isBusiness ? html`
                        <data-table-cell link="/admin/business/customerform/id/${customer.id}">Edit</data-table-cell>
                        <data-table-cell confirm="This will delete all data for this customer. Are you sure?" link="/admin/business/customerdelete/id/${customer.id}">Delete</data-table-cell>
                    ` : ''}
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>

    ${this.isBusiness ? html`
        <br>
        <br>
        <form-submission-submit confirm="This will delete all data for these customers. Are you sure?" name="submit" value="Delete Checked Customers"></form-submission-submit>
    ` : ''}
</form-submission>
` : html`
<content-block textCenter>
    <body-1>You do not have any customers signed up yet</body-1>
</content-block>
`}

`;
  }
}
