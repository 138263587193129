import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Review} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import {ReviewForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';
import '../../../components/review/review-display';

@customElement('backend-business-commentreview-page')
export class CommentreviewPage extends LitElement {
  @symfony({variable: '$review', converter: 'take:["id", "rating", "Consumer.consumer_feedback_header", "Consumer.verified", "message", "created_at"]|json', type: Object}) private review!: Review;
  @symfony({variable: '$form', converter: 'form_fields:["comment"]|json', type: Object}) private form!: ReviewForm;

  protected render() {
    return html`
<narrow-content centered>
    <review-display
        rating="${this.review.rating}"
        message="${this.review.message}"
        reviewerName="${this.review.Consumer.consumer_feedback_header}"
        ?reviewerVerified="${this.review.Consumer.verified}"
        reviewDate="${date(this.review.created_at)}">
    </review-display>
    
    <editable-section
        fullWidth
        heading="Your Reply"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'notes', 'type': 'textarea', 'label': 'Comment', 'name': 'review[comment]', 'value': this.form.comment.value},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
