import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Review} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import '../../../components/layout/narrow-content';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/review/review-display';
import '../../../components/review/review-reply';
import '../../../components/review/review-display-action';

@customElement('backend-business-managereviews-page')
export class ManagereviewsPage extends LitElement {
  @symfony({variable: '$reviews', converter: 'take:["id", "rating", "Consumer.consumer_feedback_header", "Consumer.verified", "message", "comment", "created_at"]|json', type: Array}) private reviews: Review[] = [];

  protected render() {
    return html`
<narrow-content>
    <prominent-notice
            large
            image="/assets/images/undraw_feedback_h2ft.svg"
            subtitle="${this.reviews.length > 0 ? 'Below are your customer reviews.' : 'There are no reviews at this time.'}"
            heading="Manage Reviews">
    </prominent-notice>

    ${this.reviews.map((review) => html`
        <review-display
            rating="${review.rating}"
            message="${review.message}"
            reviewerName="${review.Consumer.consumer_feedback_header}"
            ?reviewerVerified="${review.Consumer.verified}"
            reviewDate="${date(review.created_at)}">
            <review-display-action slot="actions" href="/admin/business/commentreview/id/${review.id}" label="Reply"></review-display-action>
        </review-display>
        
        ${review.comment ? html`
        <review-reply reply="${review.comment}"></review-reply>
        ` : ''}
    `)}
</narrow-content>
`;
  }
}
