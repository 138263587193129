import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessReward} from '../../../symfony/models';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/floating-action-button/floating-action-button';
import '../../../components/data-table/data-table';
import '../../../components/data-table/data-table-head';
import '../../../components/data-table/data-table-body';
import '../../../components/data-table/data-table-row';
import '../../../components/data-table/data-table-cell';

@customElement('backend-business-cashbacknowindex-page')
export class CashbacknowindexPage extends LitElement {
  @symfony({variable: '$cashBackOffers', converter: 'take:["id", "security_code", "name_buy", "name_get"]|json', type: Array}) private cashBackOffers: BusinessReward[] = [];

  protected render() {
    return html`
        
<narrow-content>
    <content-block textRight>
        <floating-action-button href="/admin/business/cashbacknow" icon="add" actionLabel="Create Cash Back Now Offer"></floating-action-button>
    </content-block>
    
    ${this.cashBackOffers.length > 0 ? html`
    <data-table fullWidth>
        <data-table-head>
            <data-table-row>
                <data-table-cell>Security Code</data-table-cell>
                <data-table-cell>Spend Amount</data-table-cell>
                <data-table-cell>Get Amount</data-table-cell>
                <data-table-cell></data-table-cell>
                <data-table-cell></data-table-cell>
            </data-table-row>
        </data-table-head>
        <data-table-body>
            ${this.cashBackOffers.map((cashBack) => html`
                <data-table-row>
                    <data-table-cell>${cashBack.security_code}</data-table-cell>
                    <data-table-cell>$${cashBack.name_buy}</data-table-cell>
                    <data-table-cell>$${cashBack.name_get}</data-table-cell>
                    <data-table-cell link="/admin/business/cashbacknow/id/${cashBack.id}">Edit</data-table-cell>
                    <data-table-cell link="/admin/business/cashbacknowdelete/id/${cashBack.id}" confirm="Are you sure you want to delete this Cash Back Now offer?">Delete</data-table-cell>
                </data-table-row>
            `)}
        </data-table-body>
    </data-table>
    ` : html`
<content-block textCenter>
    <body-1>You have not setup any Cash Back Now offers.</body-1>
</content-block>
    `}
    
</narrow-content>
        
`;
  }
}
