import {css, html, LitElement} from 'lit';
import {customElement, state, property} from 'lit/decorators.js';
import '@material/mwc-drawer';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-list/mwc-list-item';
import {NavigationItem} from '../../navigation';

enum DRAWER_NAVIGATION {
  Toggle = 'MDCTopAppBar:nav',
  Open = 'MDCDrawer:opened',
  Close = 'MDCDrawer:closed',
}

@customElement('drawer-content')
export class DrawerContent extends LitElement {
  @property({type: Boolean}) public open: boolean = true;
  @property({type: String}) public drawerTitle: string = '';
  @property({type: String}) public drawerSubtitle: string = '';
  @property({type: Array}) public navigation: NavigationItem[] = [];
  @property({type: Object}) public activeNavigationItem?: NavigationItem;
  @property({type: Boolean}) public contentOnly: boolean = false;
  @state() private drawerType: string = 'dismissible';

  static styles = css`
:host {
    display: contents;
}
`;

  constructor() {
    super();

    this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    // when first connected ensure we have the right type of drawer for the screen width
    if (!this.contentOnly) {
      this.open = window.innerWidth >= 1000 ? true : false;
      this.drawerType = window.innerWidth >= 1000 ? 'dismissible' : 'modal';
    }

    this.shadowRoot?.addEventListener(DRAWER_NAVIGATION.Toggle, this.handleToggleDrawer);
    this.shadowRoot?.addEventListener(DRAWER_NAVIGATION.Open, this.handleToggleDrawer);
    this.shadowRoot?.addEventListener(DRAWER_NAVIGATION.Close, this.handleToggleDrawer);
    window.addEventListener('resize', this.handleWindowResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.shadowRoot?.removeEventListener(DRAWER_NAVIGATION.Toggle, this.handleToggleDrawer);
    this.shadowRoot?.removeEventListener(DRAWER_NAVIGATION.Open, this.handleToggleDrawer);
    this.shadowRoot?.removeEventListener(DRAWER_NAVIGATION.Close, this.handleToggleDrawer);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  attributeChangedCallback(name: string, old: string | null, value: string | null) {
    super.attributeChangedCallback(name, old, value);

    if (name.toLowerCase() === 'contentonly') {
      this.toggleDrawer(false);
    }
  }

  private handleToggleDrawer(event: Event): void {
    const states = new Map<string, boolean | undefined>();
    states.set(DRAWER_NAVIGATION.Toggle, undefined);
    states.set(DRAWER_NAVIGATION.Open, true);
    states.set(DRAWER_NAVIGATION.Close, false);

    this.toggleDrawer(states.get(event.type));
  }

  private handleWindowResize(): void {
    this.updateDrawerType();
  }

  private toggleDrawer(forceState?: boolean) {
    this.open = forceState !== undefined ? forceState : !this.open;
  }

  private updateDrawerType(): void {
    this.drawerType = window.innerWidth >= 1000 ? 'dismissible' : 'modal';
  }

  private handleNavigation(e: CustomEvent) {
    const item = this.navigation[e.detail.index];

    if (item.href !== this.activeNavigationItem?.href) {
      // window.location.href = item.href;
      setTimeout(() => window.location.href = item.href, 300);
    }
  }

  protected render() {
    return html`
<mwc-drawer hasHeader type="${this.drawerType}" ?open="${this.open}">
    <span slot="title">${this.drawerTitle}</span>
    <span slot="subtitle">${this.drawerSubtitle}</span>
    <div class="drawer-content">
        <mwc-list activatable @selected="${this.handleNavigation}">
            <li divider role="separator"></li>
            ${this.navigation.map((item) => html`
            ${item.dividerBefore ? html`<li divider role="separator"></li>` : ''}
            <mwc-list-item graphic="avatar" ?activated="${this.activeNavigationItem?.href === item.href}">
                ${item.label}
                <mwc-icon slot="graphic">${item.graphic}</mwc-icon>
            </mwc-list-item>`)}
        </mwc-list>
    </div>
    <div slot="appContent">
        <slot></slot>
    </div>
</mwc-drawer>
`;
  }
}
