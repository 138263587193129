import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import '../../../components/typography/heading-6';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/form-submission/fields/form-submission-submit';

@customElement('marketing-signin-index-page')
export class IndexPage extends LitElement {
  static styles = css`
:host {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-box {
    border: 1px solid #e0e0e0;
    max-width: 600px;
    padding: 30px;
}
form-submission-textfield, .heading {
    display: block;
    margin-bottom: 15px;
}
`;

  protected render() {
    return html`
<div class="login-box">
    <heading-6 class="heading">Sign In</heading-6>
    <form-submission>
        <form-submission-textfield fullwidth type="text" label="Email" name="user[email]" value=""></form-submission-textfield>
        <form-submission-textfield fullwidth type="password" label="Password" name="user[password]" value=""></form-submission-textfield>
        <form-submission-submit name="submit_login" value="Login"></form-submission-submit>
    </form-submission>
</div>

`;
  }
}
