import {LitElement, html, PropertyValues} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {style} from './form-submission-css';
import {FormSubmissionFile} from './fields/form-submission-file';
import {FormSubmissionBase} from './fields/form-submission-base';

@customElement('form-submission')
export class FormSubmission extends LitElement {
  public static FIELD_UPDATED_EVENT = 'form-submission-field-updated';
  public static SUBMIT_FORM_EVENT = 'form-submission-submit-form';

  static styles = style;

  @property({type: String}) action = '';
  @property({type: String}) method: 'GET'|'POST' = 'POST';
  @property({type: Boolean}) multipart = false;

  @query('form') private form!: HTMLFormElement;
  @query('slot') private defaultSlot!: HTMLSlotElement;

  private fields: Map<string, string> = new Map<string, string>();
  private fileFields: Set<string> = new Set<string>();
  private numericArrayFields: Set<string> = new Set<string>();
  private hasFirstUpdated: boolean = false;

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    this.hasFirstUpdated = true;

    // when items in the light dom have changed, we might have a reference in this.fields to an input
    // that no longer exists, so check for that and if so delete it
    this.defaultSlot.addEventListener('slotchange', () => {
      const newNames = this.getFormItemsFromSlot().map((formItem) => this.getBaseName(formItem.name, formItem.value));

      const oldNames = Array.from(this.fields.keys());

      oldNames.forEach((name) => {
        if (newNames.indexOf(name) === -1) {
          this.fields.delete(name);
        }
      });

      this.requestUpdate();
    });
  }

  private getFormItemsFromSlot(): FormSubmissionBase[] {
    const nodes = this.defaultSlot.assignedNodes();

    const formItems: FormSubmissionBase[] = [];

    // we are currently allowing the form submission element to either be a direct child, or
    // the direct child of 1 container element
    nodes.forEach((node) => {
      const candidates = node instanceof FormSubmissionBase ? [node] : Array.from(node.childNodes);

      candidates.forEach((n) => {
        if (n instanceof FormSubmissionBase) {
          formItems.push(n);
        }
      });
    });

    return formItems;
  }

  public submit(): any {
    // When the form-submission-submit has a name equal to "submit", it effectively hijacks the submit() method
    // of our form element. We could simply call form.requestSubmit() instead of form.submit(), but browser support
    // for requestSubmit is poor at this time. For now we are using a hack that will steal another form's submit() method
    // https://stackoverflow.com/questions/833032/submit-is-not-a-function-error-in-javascript
    document.createElement('form').submit.call(this.form);
    // this.form.requestSubmit();
  }

  public async getFormData(): Promise<FormData> {
    // ensure that we have been rendered at least once so that the calling code doesn't call this method
    // before our internal form has been initialized
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (!this.hasFirstUpdated) {
          return;
        }

        clearInterval(intervalId);

        resolve(new FormData(this.form));
      }, 100);
    });
    // return new FormData(this.form);
  }

  private async handleFieldUpdated(event: CustomEvent): Promise<void> {
    // if any other element wants to react based on 1 of our internal fields being updated,
    // we will re-dispatch the event after we have handled the event here and updated this class
    event.stopPropagation();

    const {name, value, shouldInclude, isFile} = event.detail;

    const normalizedName = this.getBaseName(name, value);

    // some fields should not be posted to the server when they are "off"
    if (!shouldInclude) {
      this.fields.delete(normalizedName);
    } else {
      this.fields.set(normalizedName, value);
    }

    if (isFile) {
      this.fileFields.add(normalizedName);
    }

    // keep track of the numeric arrays (checkboxes) so that we can take special action when
    // converting them back to hidden fields
    if (name.includes('[]')) {
      this.numericArrayFields.add(normalizedName);
    }

    // since the hidden fields are not watched properties, we need to manually request an update and then
    // we will re-dispatch the field updated event so any outside listeners can react
    this.requestUpdate();

    await this.updateComplete;

    this.dispatchEvent(new CustomEvent(FormSubmission.FIELD_UPDATED_EVENT, event));
  }

  /**
   * For security reasons, browser's will not allow a file inputs value to be copied into another input. So instead of
   * using a hidden input that mirrors the value of the underlying form-submission-* component, we will use a true file
   * input (hidden with css) that will be clicked when the underlying form-submission-file component is clicked.
   *
   * @param {CustomEvent} event
   */
  private handleSelectFileClicked(event: CustomEvent): void {
    const name = event.detail.name;

    const fileComponent = this.querySelector(`form-submission-file[name="${name}"]`) as FormSubmissionFile | null;
    const fileElement = this.shadowRoot?.querySelector(`input[type="file"][name="${name}"]`) as HTMLInputElement | null;

    if (!fileComponent) {
      throw new Error(`There is no file component with the name '${name}'`);
    }

    if (!fileElement) {
      throw new Error(`There is no file element with the name '${name}'`);
    }

    // we need to update our component when the value changes
    fileElement.onchange = () => fileComponent.fileChanged(fileElement.value, fileElement?.files?.[0]);

    fileElement.click();
  }

  private handleSubmitForm(): void {
    this.submit();
  }

  /**
   * We need our internal 'fields' property to maintain a list of name to value pairs. The problem is that when we encounter
   * an array field (as checkbox's frequently do) it will overwrite any previous field with the same 'name[]'. What we do is
   * internally maintain that list as 'name[value]', then when we go to render the hidden input we put it back to 'name[]'.
   * @param {string} name
   * @param {string?} value
   * @return {string}
   */
  private getBaseName(name: string, value?: string): string {
    if (!name.includes('[]') && !this.numericArrayFields.has(name)) {
      return name;
    }

    const baseName = name.split('[')[0];

    return `${baseName}[${value || ''}]`;
  }

  protected render() {
    const enctype = this.multipart ? 'multipart/form-data' : 'application/x-www-form-urlencoded';

    const names = Array.from(this.fields.keys());

    return html`
<form action="${this.action}" method="${this.method}" enctype="${enctype}">
    <slot
    @form-submission-field-updated="${this.handleFieldUpdated}"
    @form-submission-submit-form="${this.handleSubmitForm}"
    @form-submission-select-file-clicked="${this.handleSelectFileClicked}"
    ></slot>
    
    <div class="form-submission__inputs-container">
        ${names.map((name) => html`<input type="${this.fileFields.has(name) ? 'file' : 'hidden'}" name="${this.getBaseName(name)}" value="${this.fields.get(name)!}">`)}
    </div>
</form>
`;
  }
}
