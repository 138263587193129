import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import '../../../components/typography/heading-4';
import '../../../components/typography/body-1';
import '../../../components/typography/body-2';
import '../../../components/typography/subtitle-1';
import '../../../components/button-link/button-link';
import '../../../components/form-submission/form-submission';
import '../../../components/form-submission/fields/form-submission-textfield';
import '../../../components/form-submission/fields/form-submission-submit';
import {businessUrl} from '../../../helpers/formatters/business-url';

@customElement('mobile-account-login-page')
export class LoginPage extends LitElement {
  static styles = css`
:host {
    display: block;
    padding: 15px;
    text-align: center;
}
form-submission {
    display: block;
    margin: 15px 0;
}
form-submission-textfield {
    display: block;
    margin-bottom: 15px;
}
.tagline, .description-text {
    color: var(--text-secondary-on-light);
}
.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}
`;

  protected render() {
    return html`
<heading-4>Your Loyalty Pays</heading-4>
<subtitle-1 class="tagline">You can earn loyalty rewards!</subtitle-1>
<form-submission>
    <form-submission-textfield fullwidth type="text" label="Email" name="email" value=""></form-submission-textfield>
    <form-submission-textfield fullwidth type="password" label="Password" name="password" value=""></form-submission-textfield>
    <form-submission-submit name="submit_login" value="Login"></form-submission-submit>
</form-submission>
<body-2 class="description-text">Use your existing login and password if you are already a rewards member</body-2>
<div class="register-container">
    <body-1 class="register-container__label">New Customer?</body-1>
    <button-link class="register-container__button" href="${businessUrl('/account/register')}">Register Now</button-link>
</div>
<button-link id="forgot-password-button" href="${businessUrl('/account/forgot')}">Forgot Password</button-link>

        `;
  }
}
