import {css, html, LitElement, TemplateResult} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('flex-column')
export class FlexColumn extends LitElement {
  @property({type: String}) flex: string = '1';

  private instanceStyles!: TemplateResult;

  connectedCallback(): void {
    super.connectedCallback();

    this.instanceStyles = html`
<style>
:host {
    flex: ${this.flex};
}
</style>
`;
  }

  static styles = css`
:host {
    max-width: 100%;
    overflow: hidden;
}
`;

  protected render() {
    return html`
${this.instanceStyles}
<slot></slot>`;
  }
}
