import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Testimony} from '../../../symfony/models';
import {date} from '../../../helpers/formatters/date-formatter';
import {ifDefined} from 'lit/directives/if-defined';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/review/review-display';
import '../../../components/review/review-reply';
import '../../../components/review/review-display-action';

@customElement('backend-business-managetestimonies-page')
export class ManagetestimoniesPage extends LitElement {
  @symfony({variable: '$testimonies', converter: 'take:["id", "media_url", "Consumer.consumer_feedback_header", "approved", "comment", "reply", "created_at"]|json', type: Array}) private testimonies: Testimony[] = [];

  protected render() {
    return html`
${this.testimonies.length == 0 ? html`
<prominent-notice
        large
        image="/assets/images/undraw_reviewed_docs_neeb.svg"
        heading="Manage Moments - Photos/Videos"
        subtitle="There are no moments at this time.">
</prominent-notice>
` : ''}

<flex-column-container gap="10" style="justify-content: flex-start;">
    ${this.testimonies.map((testimony) => html`
    <flex-column flex="initial" style="max-width: 500px;">
        <review-display
                media="${testimony.media_url}"
                message="${ifDefined(testimony.comment)}"
                reviewerName="${testimony.Consumer.consumer_feedback_header}"
                reviewDate="${date(testimony.created_at)}">
            <review-display-action slot="actions" href="/admin/business/replytestimony/id/${testimony.id}" label="Reply"></review-display-action>
            <review-display-action slot="actions" href="/admin/tools/deletetestimonial/id/${testimony.id}" label="Delete" confirm="Are you sure you want to delete this moment?"></review-display-action>
            
            ${!testimony.approved ? html`
            <review-display-action slot="actions" primary href="/admin/business/approvetestimony/id/${testimony.id}" label="Approve"></review-display-action>
            ` : ''}
        </review-display>
        
        ${testimony.reply ? html`
        <review-reply reply="${testimony.reply}"></review-reply>
        ` : ''}
    </flex-column>
    `)}
</flex-column-container>
`;
  }
}
