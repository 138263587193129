import {html, LitElement, PropertyValues, TemplateResult, unsafeCSS} from 'lit';
import {customElement, state, property, query} from 'lit/decorators.js';
import {slugify} from '../../symfony/decorators';
import {classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {style} from './editable-section-css';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-textarea';
import '@material/mwc-select';
import '@material/mwc-formfield';
import '@material/mwc-switch';
import '@material/mwc-icon-button';
import {FormSubmission} from '../form-submission/form-submission';
import {SelectOption} from '../form-submission/fields/form-submission-select';
import {EditableSectionItemDisplay} from './editable-section-item-display';
import themeStyles from '@material/theme/dist/mdc.theme.css';
import typographyStyles from '@material/typography/dist/mdc.typography.css';
import {ItemDisplayStore, StoredItemDisplay, Subscription} from './store/item-display-store';
import '../../components/typography/subtitle-2';
import '../../components/form-submission/fields/form-submission-textfield';
import '../../components/form-submission/fields/form-submission-select';
import '../../components/form-submission/fields/form-submission-switch';
import '../../components/form-submission/fields/form-submission-checkbox';
import '../../components/form-submission/fields/form-submission-textarea';
import '../../components/form-submission/fields/form-submission-file';
import '../../components/form-submission/fields/form-submission-hidden';

declare global {
  interface Window {
    ItemDisplayStore: ItemDisplayStore
  }
}

window.ItemDisplayStore = window.ItemDisplayStore || new ItemDisplayStore();

export enum ItemType {
  Combination = 'combination',
  CombinationHorizontal = 'combination-horizontal',
  Text = 'text',
  Textarea = 'textarea',
  Password = 'password',
  Date = 'date',
  Select = 'select',
  Switch = 'switch',
  Checkbox = 'checkbox',
  File = 'file',
  Hidden = 'hidden',
}

export interface ItemDisplay {
  leadingIcon?: string;
  type: `${ItemType}`; // allow string literal ItemType
  label: string;
  value: string;
  name?: string;
  options?: SelectOption[];
  inputs?: ItemInput[];
  extra?: { [key: string]: string };
  help?: string;
  fieldLabel?: string;
}

interface ItemInput {
  type: `${ItemType}`; // allow string literal ItemType
  label: string;
  name: string;
  value: string;
  options?: SelectOption[];
  fieldLabel?: string;
}

@customElement('editable-section')
export class EditableSection extends LitElement {
  @property({type: String}) heading = '';
  @property({type: String}) description = '';
  @property({type: String}) headingImage = '';
  @property({type: String}) headingImagePosition = 'top';
  @property({type: Boolean}) showDialog = false;
  @property({type: Boolean}) individualEdits = false;
  @property({type: Boolean}) noBorder = false;
  @property({type: String}) submitName = '';
  @property({type: String}) action = '';
  @property({type: Array, reflect: true}) items: ItemDisplay[] = [];
  @property({type: Boolean}) multipart = false;
  @property({type: String}) public primaryButtonLabel = 'Save';
  @property({type: String}) alignContent: 'left' | 'center' | 'right' = 'left';
  @property({type: Boolean}) public fullWidth: boolean = false;
  @property({type: Boolean, reflect: true}) public hasUnsavedChanges: boolean = false;
  @state() private itemsForOurSubmitName: ItemDisplay[] = [];
  @state() private inputs: ItemInput[] = [];

  @query('.editable-section__dialog-form') private form!: FormSubmission;

  private internalId: string;
  private subscription: Subscription;

  static styles = [unsafeCSS(themeStyles), unsafeCSS(typographyStyles), style];

  constructor() {
    super();

    this.internalId = Math.random().toString(36);
    this.subscription = window.ItemDisplayStore.subscribe(this.updateInputs.bind(this));

    this.handleItemEditClicked = this.handleItemEditClicked.bind(this);
  }

  connectedCallback(): void {
    super.connectedCallback();

    if (this.submitName === '') {
      throw new Error('Each editable-section element MUST have a submitName attribute.');
    }

    this.items = this.transformNullString(this.items);

    window.ItemDisplayStore.addItems(this.submitName, this.internalId, this.items);

    this.shadowRoot?.addEventListener(EditableSectionItemDisplay.EDIT_CLICKED_KEY, this.handleItemEditClicked);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.subscription.dispose();

    window.ItemDisplayStore.removeItems(this.internalId);

    this.shadowRoot?.removeEventListener(EditableSectionItemDisplay.EDIT_CLICKED_KEY, this.handleItemEditClicked);
  }

  protected updated(_changedProperties: PropertyValues): void {
    if (!_changedProperties.has('items')) {
      return;
    }

    // if this is not the initial load of the items, then consider this section to
    // have unsaved changes
    if (_changedProperties.get('items') !== undefined) {
      this.hasUnsavedChanges = true;
    }

    window.ItemDisplayStore.addItems(this.submitName, this.internalId, this.items);
  }

  private updateInputs(items: StoredItemDisplay[]): void {
    // each editable-section will contain all fields for the same submitName (even if they are from a different editable-section), but the
    // fields that are not our own will be hidden fields
    this.itemsForOurSubmitName = items.filter((item) => item.submitName === this.submitName).map((item) => item.item);
  }

  private transformNullString(items: ItemDisplay[]): ItemDisplay[] {
    // transform the literal string "null" into an empty string
    return items.map((item) => {
      if (item.value === 'null') {
        item.value = '';
      }

      return item;
    });
  }

  private convertDisplayToInputs(item: ItemDisplay): ItemInput[] {
    const inputs: ItemInput[] = [];

    const itemInputs = item.inputs || [{type: item.type, label: item.label, name: item.name || '', value: item.value, options: item.options || [], fieldLabel: item.fieldLabel}];

    itemInputs.forEach((input: ItemInput) => {
      let {type, label, name, value, options, fieldLabel} = input;

      if (!input.options) {
        options = [];
      }

      inputs.push({type, label, name, value, options, fieldLabel});
    });

    return inputs;
  }

  protected handleEditClicked() {
    this.showDialog = true;
  }

  protected handleItemEditClicked(event: Event): void {
    // Add the 'noEdit' attribute to every item except the one that was clicked. This will ensure
    // that only the item we clicked is shown in the dialog.
    this.querySelectorAll('editable-section-item').forEach((i) => {
      if (i !== event.target) {
        i.setAttribute('noEdit', 'true');
      }
    });

    // if we have selected a 'combination' item, then remove the 'noEdit' attribute that we just set on its children only
    if (event.target instanceof HTMLElement && (event.target.hasAttribute('combination') || event.target.hasAttribute('combination-horizontal'))) {
      event.target.querySelectorAll('editable-section-item').forEach((i) => i.removeAttribute('noEdit'));
    }

    this.showDialog = true;
  }

  private handleDialogClosed(event: CustomEvent): void {
    // when the dialog is closed, ensure we remove 'noEdit' attributes from all items
    this.querySelectorAll('editable-section-item').forEach((i) => i.removeAttribute('noEdit'));
    this.showDialog = false;

    if (event.detail?.action === 'save') {
      this.form.submit();
    }
  }

  private handleSaveChangesClicked(): void {
    this.form.submit();
  }

  private itemDisplayToFormSubmissionFields(item: ItemDisplay): TemplateResult {
    // most ItemDisplay's are essentially also the ItemInput's, but the 'combination' ItemDisplay's
    // differ and instead will *contain* the ItemInput's
    const inputs = this.convertDisplayToInputs(item);

    const formFields: TemplateResult[] = [];

    inputs.forEach((input: ItemInput) => {
      // if the current item is our own (directly inside this editable-section)
      // then we need to render a non-hidden input field
      // if the current item is not our own (part of the same submitName for another editable-section, but not directly inside *this* editable section)
      // then we need to render a hidden field
      if (!this.isOurOwnItem(input)) {
        // don't include "off" switches at all
        if (item.type === ItemType.Switch && item.value === 'off') {
          return;
        }

        input = {...input, ...{type: ItemType.Hidden}};
      }

      // the underlying field-submission field has it's own 'label', but if we can optionally also show an additional element via 'fieldLabel'
      if (input.fieldLabel && input.type !== ItemType.Hidden) {
        formFields.push(html`<subtitle-2 class="editable-section__dialog-form-field-label">${input.fieldLabel}</subtitle-2>`);
      }

      formFields.push(this.itemInputToFormSubmissionField(input));
    });

    // use a container element for each form field so we can optionally style things according to its original display type
    return html`<div class="editable-section__dialog-form-field-container editable-section__dialog-form-field-container--display-type-${item.type}">${formFields}</div>`;
  }

  /**
   * Here we take the displayed versions of the editable-section-item's and transform them into
   * their input equivalent.
   * @param {ItemInput} input
   * @return {TemplateResult}
   */
  private itemInputToFormSubmissionField(input: ItemInput): TemplateResult {
    if (input.type === ItemType.Text || input.type === ItemType.Password || input.type === ItemType.Date) {
      return html`<form-submission-textfield class="editable-section__dialog-form-field" type="${input.type}" name="${input.name}" label="${input.label}" value="${input.value}"></form-submission-textfield>`;
    }

    if (input.type === ItemType.Select) {
      return html`<form-submission-select class="editable-section__dialog-form-field" name="${input.name}" label="${input.label}" value="${input.value}" .options="${input.options!}"></form-submission-select>`;
    }

    if (input.type === ItemType.Switch) {
      return html`<form-submission-switch class="editable-section__dialog-form-field" name="${input.name}" label="${input.label}" value="${input.value}"></form-submission-switch>`;
    }

    if (input.type === ItemType.Checkbox) {
      return html`<form-submission-checkbox class="editable-section__dialog-form-field" name="${input.name}" label="${input.label}" value="${input.value}"></form-submission-checkbox>`;
    }

    if (input.type === ItemType.Textarea) {
      return html`<form-submission-textarea class="editable-section__dialog-form-field" name="${input.name}" label="${input.label}" value="${input.value}"></form-submission-textarea>`;
    }

    if (input.type === ItemType.File) {
      return html`<form-submission-file class="editable-section__dialog-form-field" name="${input.name}" label="${input.label}" value="${input.value}"></form-submission-file>`;
    }

    if (input.type === ItemType.Hidden) {
      return html`<form-submission-hidden class="editable-section__dialog-form-field editable-section__dialog-form-field--hidden" name="${input.name}" value="${input.value}"></form-submission-hidden>`;
    }

    throw new Error(`Unknown input type: '${input.type}'`);
  }

  private isOurOwnItem(candidate: ItemInput): boolean {
    let inputs: ItemInput[] = [];

    this.items.forEach((item) => {
      inputs = inputs.concat(this.convertDisplayToInputs(item));
    });

    return inputs.map((input) => input.name).includes(candidate.name);
  }

  protected render() {
    return html`
<div class="editable-section ${classMap({'editable-section--with-image': this.headingImage !== ''})}">
    <div class="editable-section__heading-container">
        ${this.headingImage ? html`<img style="${styleMap({objectPosition: this.headingImagePosition})}" class="editable-section__heading-image" src="${this.headingImage}" alt="">` : ''}
        <div class="editable-section__heading-text-wrapper mdc-theme--text-secondary-on-light">
            <h3 class="editable-section__heading mdc-typography--headline5">${this.heading}</h3>
            ${this.individualEdits ? '' : html`<mwc-icon-button icon="edit" class="editable-section__edit-button" @click="${this.handleEditClicked}"></mwc-icon-button>`}
        </div>
    </div>
    
    ${this.items.map((item: ItemDisplay) => html`
    <editable-section-item-display type="${item.type}" leadingIcon="${ifDefined(item.leadingIcon)}" label="${item.label}" value="${item.type === ItemType.Select ? item.options!.find((o) => o.selected)!.label : item.value}" ?individualEdits="${this.individualEdits}" .options="${item.options || []}" alignContent="${this.alignContent}" .extra="${item.extra}" help="${item.help || ''}"></editable-section-item-display>
    <slot name="${slugify(item.label)}"></slot>
    `)}
    
    <slot></slot>
    
    <mwc-dialog class="editable-section__dialog" heading="${this.heading}" ?open="${this.showDialog}" @closed="${this.handleDialogClosed}">
        <p>${this.description}</p>
        <form-submission class="editable-section__dialog-form" action="${this.action}" ?multipart="${this.multipart}">
            ${this.itemsForOurSubmitName.map((item) => this.itemDisplayToFormSubmissionFields(item))}
          <form-submission-hidden name="${this.submitName}" value="Save"></form-submission-hidden>
        </form-submission>
        <mwc-button slot="secondaryAction" dialogAction="cancel">Cancel</mwc-button>
        <mwc-button slot="primaryAction" dialogAction="save" raised>${this.primaryButtonLabel}</mwc-button>
    </mwc-dialog>
</div>
    
${this.hasUnsavedChanges ? html`
<mwc-button @click="${this.handleSaveChangesClicked}" class="unsaved-changes-button" raised label="Save Changes"></mwc-button>
` : ''}

`;
  }
}
