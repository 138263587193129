import {customElement} from 'lit/decorators.js';
import {css, html, LitElement} from 'lit';
import {symfony} from '../../../symfony/decorators';
import {PartnerForm, UserForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '@material/mwc-icon';
import '../../../components/layout/narrow-content';
import '../../../components/editable-section/editable-sections-wrapper';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';

@customElement('backend-partner-account-page')
export class AccountPage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["id", "email", "password", "password_confirm"]|json', type: Object}) private userForm!: UserForm;
  @symfony({variable: '$form_partner', converter: 'form_fields:["id", "phone_one", "address_one", "state_id", "country_id", "city", "province", "zip_code"]|json', type: Object}) private partnerForm!: PartnerForm;

  static styles = css`
:host {
    display: block;
}
`;

  protected render() {
    return html`
<narrow-content centered>
  <editable-section
      alignContent="right"
      heading="Edit User"
      description=""
      submitName="submit_save"
      .items=${<ItemDisplay[]>[
          {'leadingIcon': 'email', 'type': 'text', 'label': 'Email', 'name': 'user[email]', 'value': this.userForm?.email.value},
          {'leadingIcon': 'lock', 'type': 'combination', 'label': 'Password', 'value': '********', 'inputs': [
              {'type': 'password', 'label': 'New Password', 'name': 'user[password]', 'value': ''},
              {'type': 'password', 'label': 'Confirm New Password', 'name': 'user[password_confirm]', 'value': ''},
          ]},
          {'leadingIcon': 'phone', 'type': 'text', 'label': 'Phone', 'name': 'partner[phone_one]', 'value': this.partnerForm?.phone_one.value},
          {'leadingIcon': 'location_on', 'type': 'text', 'label': 'Address', 'name': 'partner[address_one]', 'value': this.partnerForm?.address_one.value},
          {'leadingIcon': 'location_on', 'type': 'text', 'label': 'City', 'name': 'partner[city]', 'value': this.partnerForm?.city.value},
          {'leadingIcon': 'location_on', 'type': 'select', 'label': 'Country', 'name': 'partner[country_id]', 'value': this.partnerForm?.country_id.value, 'options': this.partnerForm?.country_id.options},
          {'leadingIcon': 'location_on', 'type': 'select', 'label': 'State', 'name': 'partner[state_id]', 'value': this.partnerForm?.state_id.value, 'options': this.partnerForm?.state_id.options},
          {'leadingIcon': 'location_on', 'type': 'text', 'label': 'Postal Code', 'name': 'partner[zip_code]', 'value': this.partnerForm?.zip_code.value},
          {'leadingIcon': 'location_on', 'type': 'hidden', 'label': 'Province', 'name': 'partner[province]', 'value': ''},
      ]
  }>
  </editable-section>
</narrow-content>
    `;
  }
}
