import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {BusinessExtraForm} from '../../../../symfony/forms';
import {BusinessProfileMedia, BusinessSocialMedia} from '../../../../symfony/models';
import {ItemDisplay} from '../../../../components/editable-section/editable-section';
import '../../../../components/editable-section/editable-sections-wrapper';

@customElement('app-page-tab-integrations')
export class AppPageTabIntegrations extends LitElement {
  @property({type: Object}) public businessExtraForm!: BusinessExtraForm;
  @property({type: Object}) public businessProfileMedia!: BusinessProfileMedia;
  @property({type: Object}) public businessSocialMedia!: BusinessSocialMedia;

  protected render() {
    return html`
<editable-sections-wrapper>
    <editable-section
        multipart
        heading="Manage Media"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Facebook Page', 'name': 'facebook_url', 'value': this.businessProfileMedia.facebook_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Twitter Page', 'name': 'twitter_url', 'value': this.businessProfileMedia.twitter_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Linkedin Page', 'name': 'linkedin_url', 'value': this.businessProfileMedia.linkedin_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Pinterest Page', 'name': 'pinterest_url', 'value': this.businessProfileMedia.pinterest_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Instagram Page', 'name': 'instagram_url', 'value': this.businessProfileMedia.instagram_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Amazon Affiliate Page', 'name': 'amazon_url', 'value': this.businessProfileMedia.amazon_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Ecommerce Page', 'name': 'ecommerce_url', 'value': this.businessProfileMedia.ecommerce_url},
        ]}>
    </editable-section>

    <editable-section
        multipart
        heading="Connect Sites, Social Media & Reviews"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Pinterest Domain', 'name': 'pinterest_domain', 'value': this.businessSocialMedia.pinterest_domain},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link To Open Table', 'name': 'reservations_url', 'value': this.businessSocialMedia.reservations_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Reservations', 'name': 'generic_reservations_url', 'value': this.businessSocialMedia.generic_reservations_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Your Menu', 'name': 'menu_url', 'value': this.businessSocialMedia.menu_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Online Ordering', 'name': 'online_ordering_url', 'value': this.businessSocialMedia.online_ordering_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Your Coupons', 'name': 'groupon_id', 'value': this.businessSocialMedia.groupon_id},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Angies List', 'name': 'angies_list_url', 'value': this.businessSocialMedia.angies_list_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link E-Commerce', 'name': 'ecommerce_url', 'value': this.businessSocialMedia.ecommerce_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Avvo', 'name': 'avvo_url', 'value': this.businessSocialMedia.avvo_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Cars.com', 'name': 'cars_url', 'value': this.businessSocialMedia.cars_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link DealerRater', 'name': 'dealer_rater_url', 'value': this.businessSocialMedia.dealer_rater_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Rate MDs', 'name': 'ratemds_url', 'value': this.businessSocialMedia.ratemds_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Trip Advisor', 'name': 'trip_advisor_url', 'value': this.businessSocialMedia.trip_advisor_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Yahoo! Local', 'name': 'yahoo_local_url', 'value': this.businessSocialMedia.yahoo_local_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Zillow', 'name': 'zillow_url', 'value': this.businessSocialMedia.zillow_url},
            {'leadingIcon': 'poll', 'type': 'text', 'label': 'Link Zomato', 'name': 'zomato_url', 'value': this.businessSocialMedia.zomato_url},
        ]}>
    </editable-section>

    <editable-section
        multipart
        heading="Apple & Android Download Link"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'get_app', 'type': 'text', 'label': 'Iphone App Link', 'name': 'business_extra[iphone_app_link]', 'value': this.businessExtraForm?.iphone_app_link.value, 'help': 'Insert your app link to redirect customers to Apple itunes store to download your app.'},
            {'leadingIcon': 'android', 'type': 'text', 'label': 'Android App Link', 'name': 'business_extra[android_app_link]', 'value': this.businessExtraForm?.android_app_link.value, 'help': 'Insert your app link to redirect customers to Google Play store to download your app.'},
        ]}>
    </editable-section>
</editable-sections-wrapper>

`;
  }
}
