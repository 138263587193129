import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {BusinessExtraForm} from '../../../../symfony/forms';
import {ItemDisplay} from '../../../../components/editable-section/editable-section';
import '../../../../components/editable-section/editable-sections-wrapper';

@customElement('app-page-tab-reviews')
export class AppPageTabReviews extends LitElement {
  @property({type: Object}) public businessExtraForm!: BusinessExtraForm;
  @property({type: String}) public baseBusinessImageDir!: string;

  private imageValue(value?: string): string {
    if (!value) {
      return '';
    }

    return this.baseBusinessImageDir + value;
  }

  protected render() {
    return html`
<editable-sections-wrapper>
    <editable-section
        multipart
        heading="Manage & Promote Customer Reviews"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'rate_review', 'type': 'select', 'label': 'Review Block Theming', 'name': 'business_extra[review_block_theme_id]', 'value': this.businessExtraForm?.review_block_theme_id.value, 'options': this.businessExtraForm?.review_block_theme_id.options},
            {'leadingIcon': 'rate_review', 'type': 'file', 'label': 'Review Image', 'name': 'business_extra[review_image]', 'value': this.imageValue(this.businessExtraForm?.review_image.value)},
            {'leadingIcon': 'filter_1', 'type': 'text', 'label': 'Review Question One', 'name': 'business_extra[review_question_one]', 'value': this.businessExtraForm?.review_question_one.value},
            {'leadingIcon': 'filter_2', 'type': 'text', 'label': 'Review Question Two', 'name': 'business_extra[review_question_two]', 'value': this.businessExtraForm?.review_question_two.value},
            {'leadingIcon': 'filter_3', 'type': 'text', 'label': 'Review Question Three', 'name': 'business_extra[review_question_three]', 'value': this.businessExtraForm?.review_question_three.value},
            {'leadingIcon': 'filter_4', 'type': 'text', 'label': 'Review Question Four', 'name': 'business_extra[review_question_four]', 'value': this.businessExtraForm?.review_question_four.value},
            {'leadingIcon': 'filter_5', 'type': 'text', 'label': 'Review Question Five', 'name': 'business_extra[review_question_five]', 'value': this.businessExtraForm?.review_question_five.value},
        ]}>
    </editable-section>

    <editable-section
        multipart
        heading="New Customer Thank You Message"
        description=""
        alignContent="right"
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'visibility_off', 'type': 'switch', 'label': 'Enable Thank You Messages', 'name': 'business_extra[enable_thank_message]', 'value': this.businessExtraForm?.enable_thank_message.value ? 'on' : 'off'},
            {'leadingIcon': 'visibility_off', 'type': 'switch', 'label': 'Send Welcome Text To Customer Request A Review', 'name': 'business_extra[request_review_in_thank_message]', 'value': this.businessExtraForm?.request_review_in_thank_message.value ? 'on' : 'off'},
            {'leadingIcon': 'visibility_off', 'type': 'switch', 'label': 'Auto Text Customer To Leave A Photo Or Video On Comment Camera', 'name': 'business_extra[request_comment_camera_in_thank_message]', 'value': this.businessExtraForm?.request_comment_camera_in_thank_message.value ? 'on' : 'off'},
            {'leadingIcon': 'message', 'type': 'textarea', 'label': 'Type out a custom thank you message to text to new customers that join your program', 'name': 'business_extra[thank_message]', 'value': this.businessExtraForm?.thank_message.value},
        ]}>
    </editable-section>
</editable-sections-wrapper>
`;
  }
}
