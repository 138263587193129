import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {BusinessRewardForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '../../../components/layout/narrow-content';

@customElement('backend-business-programsshare-page')
export class ProgramssharePage extends LitElement {
  @symfony({variable: '$form', converter: 'form_fields:["name", "sharing_link", "name_get", "expires_after"]|json', type: Object}) private form!: BusinessRewardForm;

  protected render() {
    return html`
<narrow-content centered>
    <editable-section
        heading="Social Rewards"
        description=""
        submitName="submit_save"
        .items=${<ItemDisplay[]>[
            {'leadingIcon': 'label', 'type': 'textarea', 'label': 'Share This', 'name': 'business_reward[name]', 'value': this.form.name.value},
            {'leadingIcon': 'link', 'type': 'text', 'label': 'Share Link', 'name': 'business_reward[sharing_link]', 'value': this.form.sharing_link.value},
            {'leadingIcon': 'redeem', 'type': 'text', 'label': 'Customers Reward', 'name': 'business_reward[name_get]', 'value': this.form.name_get.value},
            {'leadingIcon': 'hourglass_disabled', 'type': 'select', 'label': 'How many days after reward is earned should it expire?', 'name': 'business_reward[expires_after]', 'value': this.form.expires_after.value, 'options': this.form.expires_after.options},
        ]}>
    </editable-section>
</narrow-content>
`;
  }
}
