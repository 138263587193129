import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {TabbableContentPane} from './tabbable-content-pane';
import '@material/mwc-tab';
import '@material/mwc-tab-bar';

@customElement('tabbable-content')
export class TabbableContent extends LitElement {
  @property({type: String}) public activeHeading: string = '';
  @property({type: Boolean}) public rounded: boolean = false;

  private headings: Set<string> = new Set<string>();

  static styles = css`
::slotted(tabbable-content-pane) {
    display: none;
}
:host([rounded]) mwc-tab-bar {
    --mdc-tab-border-radius: 20px 20px 0px 0px;
}
`;

  firstUpdated() {
    const panes: TabbableContentPane[] = Array.from(this.querySelectorAll('tabbable-content-pane'));

    panes.forEach((pane) => {
      this.headings.add(pane.heading);
    });

    this.requestUpdate();
  }

  private handleTabClick(heading: string) {
    this.activeHeading = heading;
  }

  protected render() {
    return html`
<style>
::slotted(tabbable-content-pane[heading="${this.activeHeading}"]) {
    display: block;
}
</style>

<mwc-tab-bar>
    ${Array.from(this.headings).map((heading) => html`
    <mwc-tab label="${heading}" @click="${() => this.handleTabClick(heading)}"></mwc-tab>
    `)}
</mwc-tab-bar>
<div class="panes">
    <slot></slot>
</div>


`;
  }
}
