import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {PricingTablePlan} from './pricing-table-plan';
import {PricingTablePlanFeature} from './pricing-table-plan-feature';
import '../../components/data-table/data-table';
import '../../components/data-table/data-table-head';
import '../../components/data-table/data-table-body';
import '../../components/data-table/data-table-row';
import '../../components/data-table/data-table-cell';

@customElement('pricing-table')
export class PricingTable extends LitElement {
  private plans: PricingTablePlan[] = [];

  static styles = css`
data-table-head data-table-cell {
    text-align: center;
}
`;

  connectedCallback() {
    super.connectedCallback();

    this.plans = Array.from(this.querySelectorAll('pricing-table-plan'));
  }

  private transformPlansToFeatures(): Feature[] {
    const mapped: Map<string, PricingTablePlanFeature[]> = new Map<string, PricingTablePlanFeature[]>();

    this.plans.forEach((plan: PricingTablePlan, index: number) => {
      plan.features.forEach((feature) => {
        // initialize this feature if we haven't encountered it yet
        if (!mapped.has(feature.label)) {
          mapped.set(feature.label, Array(this.plans.length).fill(new PricingTablePlanFeature()));
        }

        const cols = mapped.get(feature.label) as PricingTablePlanFeature[];

        cols[index] = feature;
      });
    });

    const features: Feature[] = [];

    mapped.forEach((cols: PricingTablePlanFeature[], feature: string) => {
      features.push({label: feature, columns: cols});
    });

    return features;
  }

  protected render() {
    const features = this.transformPlansToFeatures();

    return html`
<data-table textCentered>
    <data-table-head>
        <data-table-row>
            <data-table-cell></data-table-cell>
            ${this.plans.map((plan) => html`<data-table-cell>${plan}</data-table-cell>`)}
        </data-table-row>
    </data-table-head>
    <data-table-body>
        ${features.map((feature) => html`
        <data-table-row>
            <data-table-cell>${feature.label}</data-table-cell>
            ${feature.columns.map((col) => html`<data-table-cell>${col}</data-table-cell>`)}
        </data-table-row>
        `)}
    </data-table-body>
</data-table>
`;
  }
}

interface Feature {
  label: string;
  columns: PricingTablePlanFeature[];
}
