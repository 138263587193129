import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {RequestUpdate} from '../layout/app-shell/events/request-update';

@customElement('hash-content')
export class HashContent extends LitElement {
  @property({type: String}) public hash: string = '';
  @property({type: Boolean}) public updateEmptyHash: boolean = false;
  @property({type: Boolean, reflect: true}) public active: boolean = false;

  static styles = css`
:host {
    display: none;
}
:host([active]) {
    display: contents;
}
`;

  constructor() {
    super();

    this.checkActive = this.checkActive.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('hashchange', this.checkActive);

    if (window.location.hash === '' && this.updateEmptyHash) {
      window.location.hash = `#${this.hash}`;
    }

    this.checkActive();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('hashchange', this.checkActive);
  }

  attributeChangedCallback(name: string, old: string | null, value: string | null) {
    super.attributeChangedCallback(name, old, value);

    if (name === 'active') {
      // there are navigation sublinks that depend on matching against the current hash, so fire a RequestUpdate so the app-shell
      // can re-render if it needs to.
      this.dispatchEvent(new RequestUpdate());
    }
  }

  private checkActive(): void {
    const activeHash: string = window.location.hash.replace('#', '');

    this.active = (this.hash === activeHash);
  }

  protected render() {
    return html`
<slot></slot>
`;
  }
}
