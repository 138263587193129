import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {slugify, symfony} from '../../../symfony/decorators';
import {Partner, PartnerSliders} from '../../../symfony/models';
import {PartnerExtraForm, PartnerForm} from '../../../symfony/forms';
import {ItemDisplay} from '../../../components/editable-section/editable-section';
import '@material/mwc-icon';
import '../../../components/layout/narrow-content';
import '../../../components/layout/content-block';
import '../../../components/typography/heading-5';
import '../../../components/button-link/button-link';
import '../../../components/editable-section/editable-sections-wrapper';
import '../../../components/layout/flex-columns/flex-column-container';
import '../../../components/layout/flex-columns/flex-column';
import '../../../components/image-grid/image-grid';
import '../../../components/image-grid/image-grid-item';

@customElement('backend-partner-website-page')
export class WebsitePage extends LitElement {
  @symfony({variable: '$form_partner', converter: 'form_fields:["id", "name", "contact_phone_one", "contact_email", "hashtag_feed", "language_id", "language_id_business_default", "currency_id"]|json', type: Object}) private partnerForm!: PartnerForm;
  @symfony({variable: '$form_extra', converter: 'form_fields:["id", "logo", "home_message", "home_tagline", "home_form_message", "home_info_message", "home_info_action_message", "home_banner", "home_banner_link", "home_show_partners", "consumer_main_message", "consumer_subtitle", "consumer_button_text", "consumer_button_link", "consumer_tagline", "signup_banner_main_heading", "signup_banner_secondary_heading", "default_membership_card_design", "facebook_url", "twitter_url", "instagram_url", "seo_title", "seo_description", "seo_keywords", "show_how_it_works", "show_how_it_works", "show_consumer_site", "use_consumer_tagline_background", "allow_signup", "approval_needed", "show_pricing", "show_reseller", "show_phone", "show_address", "force_location", "allow_rewards", "google_analytics", "mobile_redirect"]|json', type: Object}) private partnerExtraForm!: PartnerExtraForm;
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_mobile_url"', type: String}) private appMobileUrl: string = '';
  @symfony({variable: 'sfConfig', converter: '_call:"get":"app_images_dir"', type: String}) private baseImageDir: string = '';
  @symfony({variable: '$partner', converter: 'take:["id", "PartnerSliders", "PartnerSliders.id", "PartnerSliders.filename", "PartnerMobileSliders", "PartnerMobileSliders.id", "PartnerMobileSliders.filename"]|json', type: Object}) private partner!: Partner;

  static styles = css`
:host {
    display: block;
}
`;

  private partnerImageValue(value?: string): string {
    if (!value) {
      return '';
    }

    return '/images/licensee/' + this.partner.id + '/' + value;
  }

  protected render() {
    return html`
<content-block textCenter>
  <heading-5>Marketing Website URL: </heading-5>
  <button-link href="${this.appMobileUrl}">${this.appMobileUrl}</button-link>
</content-block>
<narrow-content>
    <flex-column-container align="flex-start" columnForMobile>
        <flex-column>
            <editable-sections-wrapper>
                <editable-section
                    multipart
                    heading="Header"
                    description="Change Partner Logo"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'local_fire_department', 'type': 'file', 'extra': {deleteUrl: '/admin/partner/deletelogo'}, 'label': 'Logo', 'name': 'partner_extra[logo]', 'value': this.partnerImageValue(this.partnerExtraForm?.logo.value)},
                    ]
                }>
                </editable-section>
                <editable-section
                    multipart
                    heading="Homepage"
                    description="Change Homepage Settings"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Message', 'name': 'partner_extra[home_message]', 'value': this.partnerExtraForm?.home_message.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Tagline', 'name': 'partner_extra[home_tagline]', 'value': this.partnerExtraForm?.home_tagline.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Form Message', 'name': 'partner_extra[home_form_message]', 'value': this.partnerExtraForm?.home_form_message.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Info Message', 'name': 'partner_extra[home_info_message]', 'value': this.partnerExtraForm?.home_info_message.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Info Action Message', 'name': 'partner_extra[home_info_action_message]', 'value': this.partnerExtraForm?.home_info_action_message.value},
                        {'leadingIcon': 'label', 'type': 'file', 'label': 'Banner', 'name': 'partner_extra[home_banner]', 'value': this.partnerImageValue(this.partnerExtraForm?.home_banner.value)},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Banner Link', 'name': 'partner_extra[home_banner_link]', 'value': this.partnerExtraForm?.home_banner_link.value},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Show Partners', 'name': 'partner_extra[home_show_partners]', 'value': this.partnerExtraForm?.home_show_partners.value ? 'on' : 'off'},
                    ]
                }>
                </editable-section>
                <editable-section
                    multipart
                    heading="Consumer Page"
                    description="Change Consumer Page Settings"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Main Message', 'name': 'partner_extra[consumer_main_message]', 'value': this.partnerExtraForm?.consumer_main_message.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Subtitle', 'name': 'partner_extra[consumer_subtitle]', 'value': this.partnerExtraForm?.consumer_subtitle.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Button Text', 'name': 'partner_extra[consumer_button_text]', 'value': this.partnerExtraForm?.consumer_button_text.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Button Link', 'name': 'partner_extra[consumer_button_link]', 'value': this.partnerExtraForm?.consumer_button_link.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Tagline', 'name': 'partner_extra[consumer_tagline]', 'value': this.partnerExtraForm?.consumer_tagline.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Hashtag Feed ID', 'name': 'partner[hashtag_feed]', 'value': this.partnerForm?.hashtag_feed.value},
                        {'leadingIcon': 'cloud_upload', 'type': 'file', 'label': 'Desktop Sliders', 'name': 'partner[newSliders][filename]', 'value': ''},
                        {'leadingIcon': 'cloud_upload', 'type': 'file', 'label': 'Mobile Sliders', 'name': 'partner[newMobileSliders][filename]', 'value': ''},
                    ]
                }>
                    <image-grid slot="${slugify('Desktop Sliders')}">
                        ${this.partner.PartnerSliders.map((photo: PartnerSliders) => html`
                            <image-grid-item deleteUrl="/admin/partner/deleteslider/id/${photo.id}" src="/images/licensee/${this.partner.id}/sliders/${photo.filename}" alt="Desktop Slider"></image-grid-item>
                        `)}
                    </image-grid>
                    <image-grid slot="${slugify('Mobile Sliders')}">
                        ${this.partner.PartnerMobileSliders.map((photo: PartnerSliders) => html`
                            <image-grid-item deleteUrl="/admin/partner/deleteslider/id/${photo.id}" src="/images/licensee/${this.partner.id}/sliders/${photo.filename}" alt="Desktop Slider"></image-grid-item>
                        `)}
                    </image-grid>
                </editable-section>
                <editable-section
                    multipart
                    heading="Sign Up Page"
                    description="Change Sign Up Page Settings"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Signup Banner Main Heading', 'name': 'partner_extra[signup_banner_main_heading]', 'value': this.partnerExtraForm?.signup_banner_main_heading.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'Signup Banner Secondary Heading', 'name': 'partner_extra[signup_banner_secondary_heading]', 'value': this.partnerExtraForm?.signup_banner_secondary_heading.value},
                    ]
                }>
                </editable-section>
            </editable-sections-wrapper>
        </flex-column>
        <flex-column>
            <editable-sections-wrapper>
                <editable-section
                    multipart
                    heading="Default Membership Card Design"
                    description="Change Default Membership Card Design Settings"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                      {'leadingIcon': 'label', 'type': 'file', 'label': 'Default membership card design (600x400)', 'name': 'partner_extra[default_membership_card_design]', 'value': this.partnerImageValue(this.partnerExtraForm?.default_membership_card_design.value)},
                    ]
                }>
                </editable-section>
                <editable-section
                    multipart
                    heading="Language & Currency"
                    description="Change Language & Currency Settings"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                      {'leadingIcon': 'label', 'type': 'select', 'label': 'Default Language (Marketing Site)', 'name': 'partner[language_id]', 'value': this.partnerForm?.language_id.value, 'options': this.partnerForm?.language_id.options},
                      {'leadingIcon': 'label', 'type': 'select', 'label': 'Default Language (Businesses)', 'name': 'partner[language_id_business_default]', 'value': this.partnerForm?.language_id_business_default.value, 'options': this.partnerForm?.language_id_business_default.options},
                      {'leadingIcon': 'label', 'type': 'select', 'label': 'Default Currency', 'name': 'partner[currency_id]', 'value': this.partnerForm?.currency_id.value, 'options': this.partnerForm?.currency_id.options},
                    ]
                }>
                </editable-section>
                <editable-section
                    multipart
                    heading="Social Media"
                    description="Change Social Media Links"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                      {'leadingIcon': 'label', 'type': 'text', 'label': 'Facebook Url', 'name': 'partner_extra[facebook_url]', 'value': this.partnerExtraForm?.facebook_url.value},
                      {'leadingIcon': 'label', 'type': 'text', 'label': 'Twitter Url', 'name': 'partner_extra[twitter_url]', 'value': this.partnerExtraForm?.twitter_url.value},
                      {'leadingIcon': 'label', 'type': 'text', 'label': 'Instagram Url', 'name': 'partner_extra[instagram_url]', 'value': this.partnerExtraForm?.instagram_url.value},
                    ]
                }>
                </editable-section>
                <editable-section
                    multipart
                    heading="SEO"
                    description="Change SEO Settings"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'SEO Title', 'name': 'partner_extra[seo_title]', 'value': this.partnerExtraForm?.seo_title.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'SEO description', 'name': 'partner_extra[seo_description]', 'value': this.partnerExtraForm?.seo_description.value},
                        {'leadingIcon': 'label', 'type': 'text', 'label': 'SEO keywords', 'name': 'partner_extra[seo_keywords]', 'value': this.partnerExtraForm?.seo_keywords.value},
                    ]
                }>
                </editable-section>
                <editable-section
                    multipart
                    heading="Miscellaneous"
                    description="Change Miscellaneous Partner Settings"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Show the "How It Works" link in the navigation bar', 'name': 'partner_extra[show_how_it_works]', 'value': this.partnerExtraForm?.show_how_it_works.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Enable consumer website', 'name': 'partner_extra[show_consumer_site]', 'value': this.partnerExtraForm?.show_consumer_site.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Use consumer tagline background', 'name': 'partner_extra[use_consumer_tagline_background]', 'value': this.partnerExtraForm?.use_consumer_tagline_background.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Allow businesses to signup through your marketing website', 'name': 'partner_extra[allow_signup]', 'value': this.partnerExtraForm?.allow_signup.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Approve businesses before they have access?', 'name': 'partner_extra[approval_needed]', 'value': this.partnerExtraForm?.approval_needed.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Show pricing on marketing website', 'name': 'partner_extra[show_pricing]', 'value': this.partnerExtraForm?.show_pricing.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Show reseller page on marketing website', 'name': 'partner_extra[show_reseller]', 'value': this.partnerExtraForm?.show_reseller.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Show phone number on marketing website', 'name': 'partner_extra[show_phone]', 'value': this.partnerExtraForm?.show_phone.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Show address on marketing website', 'name': 'partner_extra[show_address]', 'value': this.partnerExtraForm?.show_address.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Force location', 'name': 'partner_extra[force_location]', 'value': this.partnerExtraForm?.force_location.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'switch', 'label': 'Allow rewards', 'name': 'partner_extra[allow_rewards]', 'value': this.partnerExtraForm?.allow_rewards.value ? 'on' : 'off'},
                        {'leadingIcon': 'label', 'type': 'textarea', 'label': 'Google analytics', 'name': 'partner_extra[google_analytics]', 'value': this.partnerExtraForm?.google_analytics.value},
                        {'leadingIcon': 'label', 'type': 'textarea', 'label': 'Mobile redirect code', 'name': 'partner_extra[mobile_redirect]', 'value': this.partnerExtraForm?.mobile_redirect.value},
                    ]
                }>
                </editable-section>
                <editable-section
                    multipart
                    heading="Contact Information"
                    description="Change Contact Information"
                    alignContent="right"
                    submitName="submit_save"
                    .items=${<ItemDisplay[]>[
                      {'leadingIcon': 'label', 'type': 'text', 'label': 'Company', 'name': 'partner[name]', 'value': this.partnerForm?.name.value},
                      {'leadingIcon': 'label', 'type': 'text', 'label': 'Contact Phone', 'name': 'partner[contact_phone_one]', 'value': this.partnerForm?.contact_phone_one.value},
                      {'leadingIcon': 'label', 'type': 'text', 'label': 'Contact Email', 'name': 'partner[contact_email]', 'value': this.partnerForm?.contact_email.value},
                    ]
                }>
                </editable-section>
            </editable-sections-wrapper>
        </flex-column>
    </flex-column-container>
</narrow-content>
    `;
  }
}
