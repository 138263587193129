import {html, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import {symfony} from '../../../symfony/decorators';
import {Filters} from '../../../components/customers-filter/customers-filter';
import {CustomersFilterCountUpdated} from '../../../components/customers-filter/events/customers-filter-count-updated';
import {BusinessCustomField} from '../../../symfony/models';
import '../../../components/typography/body-1';
import '../../../components/layout/narrow-content';
import '../../../components/prominent-notice/prominent-notice';
import '../../../components/layout/content-block';
import '../../../components/customers-filter/customers-filter';
import '../../../components/customers-filter/customers-filter-submission';


@customElement('backend-business-data-page')
export class DataPage extends LitElement {
  @symfony({variable: '$sf_request', converter: '_call:"extractParameters":["gender", "age_start", "age_end", "join_start", "join_end", "zipcode"]|json', type: Object}) private filters!: Filters;
  @symfony({variable: '$zip_data', converter: 'json', type: Array}) private zipCodes: string[] = [];
  @symfony({variable: '$custom_fields', converter: 'take:["id", "name", "type_id", "BusinessCustomFieldValue.id", "BusinessCustomFieldValue.value"]|json', type: Array}) private customFields: BusinessCustomField[] = [];
  @state() private count: number = 0;

  protected render() {
    return html`
<narrow-content>
    <prominent-notice
        image="/assets/images/undraw_spreadsheets_xdjy.svg"
        heading="Export Customers"
        subtitle="Export your customer information to csv">
        <body-1>${this.count} Customers Selected</body-1>
    </prominent-notice>
    
    <content-block centered>
        <customers-filter-submission actionLabel="Export Customers">
            <customers-filter slot="customers-filter" @customers-filter-count-updated="${(event: CustomersFilterCountUpdated) => this.count = event.count}"
                .filters="${this.filters}"
                .zipCodes="${this.zipCodes}"
                .customFields="${this.customFields}">
            </customers-filter>
        </customers-filter-submission>
    </content-block>
</narrow-content>
`;
  }
}
