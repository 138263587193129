import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import '../virtual-card';

@customElement('membership-card')
export class MembershipCard extends LitElement {
  @property({type: String}) public design: string = '';
  @property({type: Number}) public discount: number = 0;

  static styles = css`
:host {
    border-radius: 20px;
    background-color: #296d6f;
    font-weight: bold;
    color: #fff;
    font-family: sans-serif;
    text-align: center;
    font-size: 16px;
    position: relative;
}
.card-design {
    max-width: 100%;
    position: absolute;
    left: 0;
}
.card-details {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.top-section, .bottom-section {
    line-height: 40px;
    background-color: rgba(0, 0, 0, .3);
}
.top-section {
    display: flex;
    justify-content: space-around;
}
`;

  protected render() {
    const discountPercentage = this.discount === 0 ? 5 : this.discount;

    return html`
<virtual-card>
    <img class="card-design" src="${this.design}" alt="">

    <div class="card-details">
        <div class="top-section">
            <span class="customer-name">Your Customer</span>
            <div class="discount">Get ${discountPercentage}% Off</div>
        </div>
        <div class="bottom-section">
            <span class="member-id">Membership ID: #123456</span>
        </div>
    </div>
</virtual-card>
`;
  }
}
